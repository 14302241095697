import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import { Backend, Form, Softexpert } from "../../data/index";
import { toast } from "react-toastify";
import Select from "react-select";
import config from "../../config";
import { saveRol } from "../../data/collections/roles";

const {
  GetFormularyByServices,
  GetServicesByInstituion,
  GetLastAllInstitutions,
  GetLastVersionFormByService,
} = Backend;
const { GetForms } = Softexpert;
const { saveForm } = Form;

class NewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      shadow: "",
      idForm: "",
      idService: "0",
      idInsti: "0",
      error: "",
      imgUrl: "",
      loading: false,
      institutions: [],
      services: [],
      selectedOptionInstitution: null,
      selectedOptionService: null,
      modalError: false,
      errorSE: {},
    };
    this.idForm = this.idForm.bind(this);
    this.idInsti = this.idInsti.bind(this);
    this.idService = this.idService.bind(this);
  }

  async componentDidMount() {
    const institutions = await GetLastAllInstitutions();
    const options = [];
    try {
      for (const i in institutions.data.data) {
        options.push({
          value: institutions.data.data[i].id,
          label: institutions.data.data[i].id + "|" + institutions.data.data[i].name,
        });
      }
    } catch (e) {}
    this.setState({
      institutions: options,
    });
  }

  toggleError = () => {
    this.setState({
      modalError: !this.state.modalError,
    });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  idForm(e) {
    this.setState({
      idForm: e.target.value,
    });
  }

  idService(e) {
    this.setState({
      idService: e.target.value,
    });
  }

  async idInsti(e) {
    let id = (await e.target.value) || "0";
    const services = await GetServicesByInstituion(id);
    this.setState({
      idInsti: id,
      services: services.data.data,
    });
  }

  handleChange = async (selectedOptionInstitution) => {
    this.setState({ selectedOptionInstitution });
    let services = await GetServicesByInstituion(selectedOptionInstitution.value);
    console.log("BEATO ServicesInst", services);
    const options = [];
    services = services.data.data.filter(function (el) {
      return el.provider === "SoftExpert";
    });
    for (const i in services) {
      options.push({
        value: services[i].id,
        label: services[i].name + "(" + services[i].process + ")",
      });
    }
    this.setState({
      idInsti: selectedOptionInstitution.value,
      services: options,
    });
  };
  handleChangeService = async (selectedOptionService) => {
    this.setState({
      idService: selectedOptionService.value,
      selectedOptionService,
    });
  };

  async saveForm() {
    this.setState({
      loading: true,
    });
    let servicesNull = this.state.selectedOptionService.label;
    if (servicesNull.includes("null") || servicesNull.includes("()")) {
      this.setState({
        loading: false,
        error: "Este servicio no tiene id del proceso asignado en el administrador del portal",
      });
      return;
    }

    const form = await GetForms(this.state.idForm);
    if (form.ok) {
      if (this.state.idInsti === "" || this.state.idService === "") {
        this.setState({ error: "todos los campos tienen que estar llenos" });
      } else {
        const version = await GetLastVersionFormByService(this.state.idService);
        const servicesExist = await GetFormularyByServices(this.state.idService);
        if (servicesExist.data.servicesExist) {
          this.setState({
            error: "Este servicio ya esta registrado",
            loading: false,
          });
          return;
        }

        this.setState({ error: "" });

        let body = {
          jsonForm: form.data,
          idForm: this.state.idForm,
          idservice: parseInt(this.state.idService),
          idinstitution: parseInt(this.state.idInsti),
          url: document.getElementById("imgUrl").value,
          entity: form.entity,
          version: version.data.version,
          provider: "se",
        };

        const result = await saveForm(body);
        if (result.data.form) {
          this.setState({ modal: false });

          console.log(result.data);

          let body = {
            idUser: localStorage.getItem("idUser"),
            idForm: result.data.form.id,
          };

          let UserFull = {
            idUser: "3",
            idForm: result.data.form.id,
          };

          await saveRol(body);
          await saveRol(UserFull);

          this.props.handlerData();
          window.location.href = "/Formulary";
        } else {
          this.setState({
            error: "Hubo un error al guardar el formulario por favor revisar el log",
          });
        }
      }
    } else {
      let result = {
        detail: form.data,
        error: form.error,
        field: form.field,
      };
      this.setState({
        error: form.data,
        modalError: true,
        errorSE: result,
      });
      toast.error("El formulario tiene errores!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    this.setState({
      loading: false,
    });
  }

  render() {
    return (
      <div className="App padding">
        <MDBCol
          onClick={this.toggle}
          onMouseOut={() => this.setState({ shadow: "" })}
          onMouseEnter={() => this.setState({ shadow: "z-depth-1" })}
          className={"border radius padding " + this.state.shadow}
        >
          <MDBIcon
            onMouseEnter={() => this.setState({ shadow: "z-depth-1" })}
            icon="plus-circle"
            style={{
              color:
                config.env === "production"
                  ? "#00695c"
                  : config.env === "development"
                  ? "#0d47a1"
                  : "#424242",
            }}
            size="2x"
            className=" cursor"
          ></MDBIcon>
          <h6 onMouseEnter={() => this.setState({ shadow: "z-depth-1" })}>
            Nuevo Formulario de SE
          </h6>
        </MDBCol>

        {/** ===========================MODAL===================================*/}
        <MDBContainer>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
            <MDBModalHeader toggle={this.toggle}>Nuevo Formulario</MDBModalHeader>
            <MDBModalBody>
              <p className="h4 text-center mb-4">Busca un formulario en SoftExpert</p>
              <label htmlFor="defaultFormLoginEmailEx">ID formulario</label>
              <input
                type="text"
                id="id_formulary"
                name="id_formulary"
                className="form-control"
                onChange={this.idForm}
                value={this.state.idForm}
                autoComplete="on"
              />
              {this.state.loading && (
                <MDBIcon
                  icon="cog"
                  spin
                  size="3x"
                  style={{
                    color:
                      config.env === "production"
                        ? "#00695c"
                        : config.env === "development"
                        ? "#0d47a1"
                        : "#424242",
                  }}
                  fixed
                />
              )}
              <br />
              <MDBRow>
                <MDBCol>
                  <label htmlFor="defaultFormLoginEmailEx">Institución</label>
                  <Select
                    value={this.state.selectedOptionInstitution}
                    onChange={this.handleChange}
                    options={this.state.institutions}
                    placeholder="seleccione una institucion"
                  />
                  {this.state.loadingForRule && (
                    <div>
                      <MDBIcon
                        icon="cog"
                        spin
                        size="2x"
                        style={{
                          color:
                            config.env === "production"
                              ? "#00695c"
                              : config.env === "development"
                              ? "#0d47a1"
                              : "#424242",
                        }}
                        fixed
                      />
                      <label>Asignando regla....</label>
                    </div>
                  )}
                </MDBCol>
                <MDBCol>
                  <label htmlFor="defaultFormLoginEmailEx"> Servicio </label>
                  <Select
                    value={this.state.selectedOptionService}
                    onChange={this.handleChangeService}
                    options={this.state.services}
                    placeholder="seleccione un servicio"
                  />
                  {this.state.loadingForRule && (
                    <div>
                      <MDBIcon
                        icon="cog"
                        spin
                        size="2x"
                        style={{
                          color:
                            config.env === "production"
                              ? "#00695c"
                              : config.env === "development"
                              ? "#0d47a1"
                              : "#424242",
                        }}
                        fixed
                      />
                      <label>Asignando regla....</label>
                    </div>
                  )}
                </MDBCol>
                <MDBCol>
                  <label htmlFor="defaultFormLoginEmailEx"> Imagen de la Institución (URL) </label>
                  <input type="text" className="form-control" id="imgUrl" />
                </MDBCol>
              </MDBRow>
              <br />
            </MDBModalBody>
            <MDBModalFooter>
              <center>
                <label className="red-text">{this.state.error}</label>
              </center>
              <MDBBtn
                color={config.color}
                disabled={this.state.idInsti === "0" || this.state.idService === "0"}
                onClick={() => this.saveForm()}
              >
                Guardar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>

        {/**==================================MODAL ERROR=============== */}
        <MDBModal
          isOpen={this.state.modalError}
          toggle={this.toggleError}
          side
          position="bottom-right"
        >
          <MDBModalHeader toggle={this.toggleError}>{this.state.errorSE.error}</MDBModalHeader>
          <MDBModalBody>
            <label>
              <b>Razon:</b> {this.state.errorSE.detail}
            </label>
            <br />
            <label>
              <b>Campo:</b> {this.state.errorSE.field && this.state.errorSE.field.label}
            </label>
            <br />
            <label>
              <b>Id:</b> {this.state.errorSE.field && this.state.errorSE.field.name}
            </label>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}

export default NewForm;
