import React from "react";
import { Redirect } from "react-router-dom";
import { Form, Router } from "../../data/index";
import { MDBIcon } from "mdbreact";
import config from "../../config";
import { getRouterPermission } from "../../Helpers/helpers";

const { getRouterByUser } = Router;
const { GetAllFormularyDataTable } = Form;

export default class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: 0, children: "" };
  }

  async componentWillReceiveProps(props) {
    if (props !== this.props) {
      this.setState({
        hasError: await this.validations(),
        children: this.props.children,
      });
    }
  }

  shouldComponentUpdate(props, state) {
    return this.state !== state;
  }

  async componentDidMount() {
    this.setState({
      hasError: await this.validations(),
      children: this.props.children,
    });
  }

  async validations() {
    if (localStorage.getItem("idUser") && localStorage.getItem("token-rules")) {
      const form = await GetAllFormularyDataTable();
      if (form.ok) {
        const result = await getRouterByUser(localStorage.getItem("idUser"));
        const permission = getRouterPermission(
          result.data.routers,
          window.location.pathname.toLowerCase()
        );
        if (permission) {
          return 1;
        }
      }
      return 2;
    }
    return 2;
  }

  render() {
    if (window.location.pathname === "/") {
      window.location.href = "/login";
    }
    //============================= ESTA CARGANDO LA INTERFAZ =================================
    if (this.state.hasError === 0) {
      return (
        <MDBIcon
          icon="cog"
          spin
          size="4x"
          style={{
            color:
              config.env === "production"
                ? "#00695c"
                : config.env === "development"
                ? "#0d47a1"
                : "#424242",
            position: "absolute",
            left: "46%",
            top: "45%",
          }}
          fixed
        />
      );
    }

    //============================ SI EL TOKEN ESTA VENCIDO ===================================
    if (this.state.hasError === 2 && window.location.pathname !== "/login") {
      return <Redirect to="/login" />;
    }

    //============================ SI NO HAY CONEXION A INTERNET ==============================
    if (this.state.hasError === 3) {
      return (
        <div style={{ position: "absolute", left: "35%", top: "25%" }}>
          <center>
            <img alt="" src={require("../../img/rule.png")} />
          </center>
          <br />
          <h3>Revise su conexion a internet por favor</h3>
        </div>
      );
    }

    //============================= SINO TIENE PERMISOS A UNA RUTA=============================
    if (this.state.hasError === 4 && window.location.pathname !== "/home") {
      return <Redirect to="/Home" />;
    }

    return this.props.children;
  }
}
