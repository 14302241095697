import React, { Component } from "react";

import "./Utilities.css";

class CardText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFS: this.props.fs,
    };
  }

  render() {
    const { text } = this.props;
    return (
      <div>
        {this.state.isFS ? (
          <div className="legend-styled">
            <h6 id={this.props.id} className="text-left" style={{ fontWeight: "bold" }}>
              {text}
            </h6>
          </div>
        ) : (
          <div className="legend-styled-fs">
            <h3 id={this.props.id} className="text-left" style={{ fontWeight: "bold" }}>
              {text}
            </h3>
          </div>
        )}
      </div>
    );
  }
}

export default CardText;
