import React, { Component } from "react";
import { MDBCol, MDBRow } from "mdbreact";

export default class FRadio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is: false,
    };
  }

  render() {
    const field = this.props.field;
    return (
      <div className="form-group">
        <label htmlFor={field.label}>
          <b>{field.label}</b>
        </label>
        <MDBRow>
          {field.values.map((radio, i) => [
            <MDBCol
              key={i + radio.label}
              onClick={(e) =>
                this.props.handlerFromInput(field.name, field.type, radio, field.label)
              }
            >
              <input
                type="radio"
                name={field.name}
                value={radio.value}
                key={i}
                id={"F" + i + field.name}
              />
              <label>{radio.label + " "}</label>
            </MDBCol>,
          ])}
        </MDBRow>
      </div>
    );
  }
}
