import React, { Component } from "react";
import {
  MDBBadge,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
} from "mdbreact";
import "./style.css";
import { firebase } from "../../firebase";
import { ErrorByFirebase } from "../../Helpers/helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import { Router, User } from "../../data/index";

const { loginForToken } = User;
const { getRouterByUser } = Router;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      user: "",
      pass: "",
      loading: false,
      modal: false,
      modalRegister: false,
      isLogin: false,
      nameUser: "",
      activeItem: "1",
      modalForget: false,
      userLoggedIn: "",
      router: [],
    };
    this.nameUser = this.nameUser.bind(this);
    this.UserInput = this.UserInput.bind(this);
    this.PassInput = this.PassInput.bind(this);
    this.modalForget = this.modalForget.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(e, action) {
    this.setState({ currentKey: e.keyCode });
    if (e.key === "Enter") {
      switch (action) {
        case "login":
          this.Login();
          break;
        case "register":
          this.CreateUser();
          break;
        case "forget":
          this.forget();
          break;
        default:
          break;
      }
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  toggleTab = (tab) => () => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  modalForget = () => {
    this.setState({
      modalForget: !this.state.modalForget,
    });
  };

  UserInput(e) {
    this.setState({
      user: e.target.value,
    });
  }

  PassInput(e) {
    this.setState({
      pass: e.target.value,
    });
  }

  nameUser(e) {
    this.setState({
      nameUser: e.target.value,
    });
  }

  async forget() {
    let auth = firebase.auth();
    await auth
      .sendPasswordResetEmail(this.state.user)
      .then(async () => {
        toast.success("Emviamos un correo a la bandeja de entrada de  " + this.state.user, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.setState({
          modalForget: false,
        });
      })
      .catch(async () => {
        toast.error("Erro al enviar el correo", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  }

  async CreateUser() {
    this.setState({
      loading: true,
    });
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(this.state.user, this.state.pass)
      .then(() => {
        var user = firebase.auth().currentUser;
        user
          .updateProfile({
            photoURL: 1,
            displayName: this.state.nameUser,
          })
          .catch(() => {});
        user.sendEmailVerification().then(function () {
          // Email sent.
        });
        return {
          data:
            "Usaurio registrado exitosamente, revise la bandeja de entrada de " + this.state.user,
          ok: true,
        };
      })
      .catch((error) => {
        return { data: error.code, ok: false };
      });

    if (result.ok) {
      toast.success(result.data, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      this.setState({
        loading: false,
        modalRegister: false,
      });
    } else {
      toast.error(ErrorByFirebase(result.data), {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    this.setState({
      loading: false,
    });
  }

  async Login() {
    this.setState({
      loading: true,
    });

    const result = await loginForToken(this.state.user, this.state.pass);

    if (result.ok) {
      localStorage.setItem("token-rules", result.data.token_rules);
      localStorage.setItem(
        "nameUser",
        result.data.user.user.firstname + " " + result.data.user.user.lastname
      );
      localStorage.setItem("idUser", result.data.user.user.id);
      localStorage.setItem("isLogin", true);
      localStorage.setItem("rol", 2);
      localStorage.setItem("userLoggedIn", result.data.user.user.email);

      this.setState({
        modal: false,
        isLogin: result.data.user.auth,
        nameUser: result.data.fistname,
        rol: 2,
        userLoggedIn: result.data.email,
      });

      window.location.reload();
    } else {
      this.setState({
        loading: false,
      });
      toast.error("las credenciales no coinciden", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }

  logout() {
    this.setState({
      isLogin: false,
      nameUser: "",
      redirect: true,
    });
    localStorage.setItem("token-rules", "");
    localStorage.setItem("nameUser", "");
    localStorage.setItem("idUser", "");
    localStorage.setItem("isLogin", false);
    localStorage.setItem("rol", 0);
    localStorage.setItem("userLoggedIn", false);
  }

  async componentDidMount() {
    this.setState({
      isLogin: localStorage.getItem("isLogin"),
      nameUser: localStorage.getItem("nameUser"),
      rol: localStorage.getItem("rol"),
      emailVerified: true,
    });
    this.props.isLogin(true);
    let routers = [];
    const result = await getRouterByUser(localStorage.getItem("idUser"));
    if (result.data && result.data.routers) {
      result.data.routers.forEach((element) => {
        routers[element.router] = true;
      });
      this.setState({
        router: routers,
      });
    }

    //document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      this.props.isLogin(nextState.isLogin);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState || this.props !== nextProps;
  }

  render() {
    return (
      <div>
        <MDBNavbar color={config.color} dark expand="md">
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            {this.state.isLogin === "true" && (
              <MDBNavbarNav left>
                <MDBNavItem>
                  <MDBNavLink
                    to="/Home"
                    hidden={!this.state.router["dash"]}
                    className={this.state.activeItem === "1" ? "border-bottom border-white" : ""}
                    onClick={this.toggleTab("1")}
                    role="tab"
                  >
                    {" "}
                    <MDBIcon icon="tachometer-alt"></MDBIcon> Tablero
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    {/*nav caret*/}
                    <MDBDropdownToggle
                      hidden={!this.state.router["rule"]}
                      className={this.state.activeItem === "2" ? "border-bottom border-white" : ""}
                      onClick={this.toggleTab("2")}
                      role="tab"
                      caret
                      nav
                    >
                      <MDBIcon icon="align-left" /> Reglas
                    </MDBDropdownToggle>
                    <MDBDropdownMenu right>
                      <MDBNavLink to="/Rules" hidden={!this.state.router["rule"]}>
                        <MDBDropdownItem>
                          <MDBIcon icon="file" /> Reglas de Formularios
                        </MDBDropdownItem>
                      </MDBNavLink>
                      <MDBNavLink to="/Price" hidden={!this.state.router["rulesprice"]}>
                        <MDBDropdownItem>
                          <MDBIcon icon="dollar-sign" /> Reglas de Precios
                        </MDBDropdownItem>
                      </MDBNavLink>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    to="/Mask"
                    hidden={!this.state.router["mask"]}
                    className={this.state.activeItem === "3" ? "border-bottom border-white" : ""}
                    onClick={this.toggleTab("3")}
                    role="tab"
                  >
                    {" "}
                    <MDBIcon icon="asterisk"></MDBIcon> Mascaras
                  </MDBNavLink>
                </MDBNavItem>

                {/* <MDBNavItem >
              <MDBNavLink to="/Mails" className={this.state.activeItem==="4" ? "border-bottom border-white" : "" } onClick={this.toggleTab("4")} role="tab"><MDBIcon icon="envelope" ></MDBIcon> Correos</MDBNavLink>
            </MDBNavItem> */}
                {
                  <MDBNavItem>
                    <MDBNavLink
                      to="/Certifications"
                      hidden={!this.state.router["qr"]}
                      className={this.state.activeItem === "4" ? "border-bottom border-white" : ""}
                      onClick={this.toggleTab("4")}
                      role="tab"
                    >
                      <MDBIcon icon="qrcode"></MDBIcon> Certificaciones
                    </MDBNavLink>
                  </MDBNavItem>
                }
                <MDBNavItem>
                  <MDBDropdown>
                    {/*nav caret*/}
                    <MDBDropdownToggle
                      hidden={!this.state.router["form"]}
                      className={this.state.activeItem === "5" ? "border-bottom border-white" : ""}
                      onClick={this.toggleTab("5")}
                      role="tab"
                      caret
                      nav
                    >
                      <MDBIcon icon="file" /> Formularios
                    </MDBDropdownToggle>
                    <MDBDropdownMenu right>
                      <MDBNavLink to="/Formulary" hidden={!this.state.router["form"]}>
                        <MDBDropdownItem>
                          <MDBIcon icon="paper-plane" /> Envio de Formularios
                        </MDBDropdownItem>
                      </MDBNavLink>
                      <MDBNavLink to="/Configurations" hidden={!this.state.router["config"]}>
                        <MDBDropdownItem>
                          <MDBIcon icon="cogs" /> Configuracion de Campos
                        </MDBDropdownItem>
                      </MDBNavLink>

                      {/*<MDBNavLink to="/testRule">*/}
                      {/*  <MDBDropdownItem>*/}
                      {/*    <MDBIcon icon="align-left" /> Prueba de Reglas*/}
                      {/*  </MDBDropdownItem>*/}
                      {/*</MDBNavLink>*/}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    to="/tranfer"
                    hidden={!this.state.router["tranfer"]}
                    className={this.state.activeItem === "7" ? "border-bottom border-white" : ""}
                    onClick={this.toggleTab("7")}
                    role="tab"
                  >
                    <MDBIcon icon="exchange-alt"></MDBIcon> Transferencia
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBDropdown>
                    {/*nav caret*/}
                    <MDBDropdownToggle
                      hidden={!this.state.router["admin"]}
                      className={this.state.activeItem === "6" ? "border-bottom border-white" : ""}
                      onClick={this.toggleTab("5")}
                      role="tab"
                      caret
                      nav
                    >
                      <MDBIcon icon="cogs" /> Administrador
                    </MDBDropdownToggle>
                    <MDBDropdownMenu right>
                      <MDBNavLink to="/admin" hidden={!this.state.router["admin"]}>
                        <MDBDropdownItem>
                          <MDBIcon icon="file" /> Gestion de Formularios
                        </MDBDropdownItem>
                      </MDBNavLink>
                      <MDBNavLink to="/users" hidden={!this.state.router["users"]}>
                        <MDBDropdownItem>
                          <MDBIcon icon="user" /> Gestion de Usuarios{" "}
                        </MDBDropdownItem>
                      </MDBNavLink>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
              </MDBNavbarNav>
            )}
            <MDBNavbarNav right>
              <MDBNavItem>
                <MDBDropdown>
                  {/*nav caret*/}
                  <MDBDropdownToggle caret nav>
                    {this.state.nameUser + " "}
                    <MDBIcon icon="user" />
                  </MDBDropdownToggle>
                  <MDBDropdownMenu right>
                    {this.state.isLogin ? (
                      <div>
                        <MDBNavLink to="/Docs">
                          <MDBDropdownItem>Documentación</MDBDropdownItem>
                        </MDBNavLink>
                        <MDBNavLink to="/login">
                          <MDBDropdownItem onClick={() => this.logout()}>Salir</MDBDropdownItem>
                        </MDBNavLink>
                      </div>
                    ) : (
                      <div>
                        <MDBNavLink to="/login">
                          <MDBDropdownItem>Login</MDBDropdownItem>
                        </MDBNavLink>
                      </div>
                    )}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>

        {config.env !== "production" && (
          <MDBRow style={{ paddingRight: "20px", paddingTop: "4px" }} end>
            <MDBBadge color={config.color}> Ambiente: {config.env}</MDBBadge>
          </MDBRow>
        )}

        <div />
      </div>
    );
  }
}

export default Header;
