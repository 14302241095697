import config from "../../config";
import axios from "axios";

function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token-rules")}`;
  return axios;
}

function loginForToken(email, pass) {
  let body = {
    email: email,
    password: pass,
  };

  return axios_api(config.api + "/login")
    .post(config.api + "/login/", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function isAuth() {
  return axios_api(config.api + "/isAuth")
    .get(config.api + "/isAuth")
    .then((res) => {
      return { data: res.data, ok: true, code: res.status };
    })
    .catch((error) => {
      return { data: error, ok: false, code: error.response.status };
    });
}

function allUsers() {
  return axios_api(config.api + "/formulary/allusers")
    .get(config.api + "/formulary/allusers")
    .then((res) => {
      return { data: res.data, ok: true, code: res.status };
    })
    .catch((error) => {
      return { data: error, ok: false, code: error.response };
    });
}

export { isAuth, loginForToken, allUsers };
