import React, { Component } from "react";
import config from "../../../config";

export default class FSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {
        borderColor:
          config.env === "production"
            ? "#00695c"
            : config.env === "development"
            ? "#0d47a1"
            : "#424242",
        borderStyle: "dotted",
        borderWidth: 2,
      },
    };
  }

  componentWillReceiveProps(props) {}

  render() {
    const field = this.props.field;
    const i = this.props.id;

    return (
      <div>
        {this.props.textForMask ? (
          <div
            onClick={(e) =>
              this.props.handlerFromInput(
                e,
                field,
                this.props.grid,
                this.props.nameGrid,
                this.props.setModal
              )
            }
          >
            <div className="form-group">
              <label htmlFor={field.label}>{field.label}</label>
              <label className="float-right">
                mascara:{" "}
                <span style={{ fontSize: 12, color: "green", fontWeight: "bold" }}>
                  {this.props.fieldSave[
                    this.props.grid ? this.props.nameGrid + "_" + field.name : field.name
                  ]
                    ? this.props.fieldSave[
                        this.props.grid ? this.props.nameGrid + "_" + field.name : field.name
                      ].Mask &&
                      this.props.fieldSave[
                        this.props.grid ? this.props.nameGrid + "_" + field.name : field.name
                      ].Mask.label
                    : ""}
                </span>
              </label>
              <select
                key={i}
                id={"F" + field.name}
                disabled
                onChange={(e) =>
                  this.props.handlerFromInput(
                    field.name,
                    field.type,
                    {
                      label: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
                      value: e.target.value,
                    },
                    field.label,
                    e
                  )
                }
                style={
                  this.props.fieldFromInput && field.name === this.props.fieldFromInput.name
                    ? this.state.selected
                    : {}
                }
              >
                <option key={i} value={"00"}>
                  id: {field.name}
                </option>
                {field.values &&
                  field.values.map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="form-group">
            <label htmlFor={field.label}>{field.label}</label>
            <select
              key={i}
              id={"F" + field.name}
              onChange={(e) =>
                this.props.handlerFromInput(
                  field.name,
                  field.type,
                  {
                    label: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
                    value: e.target.value,
                  },
                  field.label,
                  e
                )
              }
            >
              <option key={i} value={"00"}>
                seleccione
              </option>
              {field.values &&
                field.values.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </div>
        )}
      </div>
    );
  }
}
