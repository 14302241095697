/*
 * LOCATION MASKS BY ID
 * 51 - PAIS
 * 52 - PROVINCIA
 * 53 - MUNICIPIO
 * 54 - SECTOR
 * */
export const LOCATION_MASK = ["51", "52", "53", "54"];

export const unitJson = [
  { label: "DIAS", value: "days" },
  { label: "MESES", value: "months" },
  { label: "AÑOS", value: "years" },
];
