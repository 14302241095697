import config from "../../config";
import axios from "axios";

function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token-rules")}`;
  return axios;
}

function saveRouter(body) {
  return axios_api(config.api + "/router")
    .post(config.api + "/router", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function getRouterByUser(id) {
  return axios_api(config.api + "/router/" + id)
    .get(config.api + "/router/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function deleteRouter(id, router) {
  return axios_api(config.api + "/router/" + id + "/" + router)
    .delete(config.api + "/router/" + id + "/" + router)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

export { saveRouter, getRouterByUser, deleteRouter };
