import React, { Component } from "react";
import { MDBCol, MDBRow } from "mdbreact";

export default class FCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is: false,
      check: "1",
    };
  }

  check(id) {
    let checkbox = document.getElementById(id);

    let inputFrom = document.getElementById("FromInput").value;
    if (inputFrom) {
      let checkOld = document.getElementById("F" + inputFrom.split("|")[1]);
    }

    console.log("checkbox.checked :", checkbox.checked);
    if (checkbox.checked) {
      this.setState({
        check: "0",
      });
    } else {
      this.setState({
        check: "1",
      });
    }
  }

  render() {
    const field = this.props.field;
    return (
      <div className="form-group">
        <MDBRow>
          {field.values.map((radio, i) => [
            <MDBCol
              key={"col-checkbox-" + i}
              size="12"
              onClick={(e) => [
                this.props.handlerFromInput(
                  field.name,
                  field.type,
                  {
                    label: e.target.checked ? "1" : "0",
                    value: e.target.checked ? "1" : "0",
                  },
                  field.label,
                  e
                ),
              ]}
              className={this.state.is ? "selected-component" : ""}
            >
              <input
                type="checkbox"
                name={field.name + "f"}
                value={radio.value}
                key={i}
                id={"F" + field.name}
                readOnly
              />
              &emsp;<label>{radio.label}</label>
            </MDBCol>,
          ])}
        </MDBRow>
      </div>
    );
  }
}
