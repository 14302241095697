import React, { Component } from "react";
import { MDBCol, MDBRow } from "mdbreact";

export default class TRadio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is: false,
    };
  }

  componentWillReceiveProps(props) {}

  render() {
    const field = this.props.field;
    return (
      <div className="form-group">
        <label htmlFor={field.label} onClick={(e) => this.props.handlerToInput(e, field.name)}>
          <b>{field.label}</b>
        </label>
        <MDBRow>
          {field.values.map((radio, i) => [
            <MDBCol
              size="6"
              className={this.state.is ? "selected-component" : ""}
              key={"col-radio-" + i}
            >
              <input
                type="radio"
                name={field.name}
                value={radio.value}
                key={i}
                id={field.name + radio.value}
                onChange={(e) => this.props.handlerToInput(e, field.name + radio.value)}
                readOnly
              />
              <label>{radio.label}</label>
            </MDBCol>,
          ])}
        </MDBRow>
      </div>
    );
  }
}
