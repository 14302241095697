import React, { Component } from "react";
import {
  MDBAnimation,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { Redirect } from "react-router-dom";
import { Rules } from "../../data/index";
import config from "../../config";

const { GetRulesById, deleteRulesById } = Rules;

class RulesListBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shadow: "",
      newRule: false,
      rules: "",
      modal: false,
      loading: false,
      effect: "bounceInUp",
    };
  }

  async viewRule() {
    const result = await GetRulesById(this.props.rules.id);
    this.setState({
      modal: true,
      rules: result.data.rules[0].rule,
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  async delete(id) {
    if (window.confirm("¿Estas seguro de eliminar esta regla?")) {
      this.setState({
        loading: true,
      });

      deleteRulesById(id).then(async () => {
        this.props.handlerRule();
        this.setState({
          loading: false,
          effect: "bounceOutUp",
        });
      });
    }
  }

  render() {
    if (this.state.newRule) {
      console.log(this.props.data);
      console.log(this.props.rules);
      console.log(this.props.idForm);
      return (
        <Redirect
          to={{
            pathname: "/render",
            state: {
              data: this.props.data,
              idForm: this.props.idForm,
              idRule: this.props.rules.id,
            },
          }}
        />
      );
    }

    return (
      <MDBCol size="3">
        <br />
        <MDBAnimation type={this.state.effect}>
          <MDBCard style={{ marginTop: "1rem" }} className="text-center">
            <MDBCardHeader color={config.color}>
              <MDBIcon
                onClick={() => this.viewRule()}
                onMouseEnter={() => this.setState({ shadow: "z-depth-1" })}
                icon="eye"
                style={{ color: "white" }}
                size="1x"
                className=" cursor"
              />
            </MDBCardHeader>
            <MDBCardBody>
              <MDBCardTitle>{" version: [" + this.props.rules.versionRule + "]"}</MDBCardTitle>
              <MDBBtn color="success" size="sm" onClick={() => this.setState({ newRule: true })}>
                Abrir regla
              </MDBBtn>

              {config.env !== "production" && (
                <MDBBtn color="danger" size="sm" onClick={() => this.delete(this.props.rules.id)}>
                  Borrar regla
                </MDBBtn>
              )}

              {this.state.loading && (
                <MDBIcon
                  icon="cog"
                  spin
                  size="1x"
                  style={{
                    color:
                      config.env === "production"
                        ? "#00695c"
                        : config.env === "development"
                        ? "#0d47a1"
                        : "#424242",
                  }}
                />
              )}
            </MDBCardBody>
            <MDBCardFooter color={config.color}>
              <b>creada el:</b> {this.props.rules.createdAt.split("T")[0]} -{" "}
              {this.props.rules.createdAt.split("T")[1].substring(0, 5)}
            </MDBCardFooter>
          </MDBCard>
        </MDBAnimation>

        {/** ==============================================MODAL IMPORT RULE========================== */}

        <MDBContainer>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader toggle={this.toggle}>
              Regla version no. {this.props.rules.versionRule}
            </MDBModalHeader>
            <MDBModalBody>
              <textarea disabled className="form-control" rows="4">
                {this.state.rules}
              </textarea>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </MDBCol>
    );
  }
}

export default RulesListBox;
