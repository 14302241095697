import config from "../../config";
import axios from "axios";

function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token-rules")}`;
  return axios;
}

async function sendForm(body) {
  return await axios_api(config.api + "/sendForms")
    .post(config.api + "/sendForms", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function GetFormularyByServices(id) {
  return await axios_api(config.api + "/formularyByService/" + id)
    .get(config.api + "/formularyByService/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function GetFormularyByServiceBK(id) {
  return await axios_api(config.api + "/formulary/service/" + id)
    .get(config.api + "/formulary/service/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function GetPriceByService(id) {
  return await axios_api(config.api + "/formulary/price/" + id)
    .get(config.api + "/formulary/price/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetLastVersionFormByService(id) {
  return axios_api(config.api + "/lastversionform/" + id)
    .get(config.api + "/lastversionform/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetLastAllInstitutions() {
  return axios_api(config.api + "/AllInstitutions")
    .get(config.api + "/AllInstitutions")
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetServicesByInstituion(id) {
  return axios_api(config.api + "/servicesByInstituion/" + id)
    .get(config.api + "/servicesByInstituion/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetServiceById(id) {
  return axios_api(config.api + "/service/" + id)
    .get(config.api + "/service/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetInstitutionById(id) {
  return axios_api(config.api + "/institution/" + id)
    .get(config.api + "/institution/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

export {
  sendForm,
  GetLastVersionFormByService,
  GetLastAllInstitutions,
  GetServicesByInstituion,
  GetServiceById,
  GetInstitutionById,
  GetFormularyByServices,
  GetFormularyByServiceBK,
  GetPriceByService,
};
