import config from "../../config";
import axios from "axios";

function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token-rules")}`;
  return axios;
}

function saveRol(body) {
  return axios_api(config.api + "/roles")
    .post(config.api + "/roles", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetRolesByUser(id) {
  return axios_api(config.api + "/roles/user/" + id)
    .get(config.api + "/roles/user/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetRolesById(id) {
  return axios_api(config.api + "/roles/" + id)
    .get(config.api + "/roles/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

/**
 * @param user id por user
 * @param form id formulary
 */
function GetRolesByUserAndForm(user, form) {
  return axios_api(config.api + "/roles/user/form/" + user + "/" + form)
    .get(config.api + "/roles/user/form/" + user + "/" + form)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function deleteRolesById(id, idForm) {
  return axios_api(config.api + "/roles/" + id + "/" + idForm)
    .delete(config.api + "/roles/" + id + "/" + idForm)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

export { saveRol, GetRolesById, GetRolesByUser, GetRolesByUserAndForm, deleteRolesById };
