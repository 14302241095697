import React from "react";
import { Pie } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";

class ChartsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPie: [],
    };
  }

  componentWillReceiveProps(props) {
    if (props.services) {
      this.setState({
        dataPie: {
          labels: Object.keys(props.services),
          datasets: [
            {
              data: Object.values(props.services),
              backgroundColor: [
                "rgb(116,180,12,0.6)",
                "rgb(100,140,211,0.6)",
                "rgb(28,36,108,0.6)",
                "rgb(76,56,104,0.6)",
                "rgb(16,76,104,0.6)",
                "rgb(16,20,136,0.6)",
                "rgb(16,20,112,0.6)",
              ],
              hoverBackgroundColor: [
                "rgb(116,180,12,0.8)",
                "rgb(100,140,211,0.8)",
                "rgb(28,36,108,0.8)",
                "rgb(76,56,104,0.8)",
                "rgb(16,76,104,0.8)",
                "rgb(16,20,136,0.8)",
                "rgb(16,20,112,0.8)",
              ],
              borderColor: [
                "rgb(116,180,12,1)",
                "rgb(100,140,211,1)",
                "rgb(28,36,108,1)",
                "rgb(76,56,104,1)",
                "rgb(16,76,104,1)",
                "rgb(16,20,136,1)",
                "rgb(16,20,112,1)",
              ],
            },
          ],
        },
      });
    }
  }

  render() {
    return (
      <MDBContainer className="padding">
        {this.state.dataPie.labels && (
          <Pie data={this.state.dataPie} options={{ responsive: true }} />
        )}
        <label style={{ fontSize: 25 }}>Instituciones con mas servicios</label>
      </MDBContainer>
    );
  }
}

export default ChartsPage;
