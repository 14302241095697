import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import { Rules } from "../../data/index";
import { toast } from "react-toastify";
import config from "../../config";

const { getLastVersionRule, saveRules } = Rules;

class BtnImportRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shadow: "",
      newRule: false,
      modal: false,
      rules: "",
    };
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  async importRule() {
    let lastVersion = await getLastVersionRule(this.props.idForm);
    let body = {
      idForm: this.props.idForm,
      rule: this.state.rules,
      version: lastVersion.data.rules + 1,
    };

    let result = await saveRules(body);
    if (result.ok) {
      toast.success("Se importo la regla exitosamente", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      this.props.handlerRule();
    } else {
      toast.error("Error al importa la mascara", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    this.setState({
      modal: false,
    });
  }

  render() {
    return (
      <div className="App padding">
        {/* <ToastContainer/> */}
        <MDBCol
          onClick={() => this.toggle()}
          onMouseOut={() => this.setState({ shadow: "" })}
          onMouseEnter={() => this.setState({ shadow: "z-depth-1" })}
          size="12"
          className={"border radius padding " + this.state.shadow}
        >
          <MDBIcon
            onMouseEnter={() => this.setState({ shadow: "z-depth-1" })}
            icon="arrow-circle-down"
            style={{
              color:
                config.env === "production"
                  ? "#00695c"
                  : config.env === "development"
                  ? "#0d47a1"
                  : "#424242",
            }}
            size="2x"
            className=" cursor"
          ></MDBIcon>
          <h5 onMouseEnter={() => this.setState({ shadow: "z-depth-1" })}>Importar Reglas</h5>
        </MDBCol>

        {/**
         ==============================================MODAL IMPORT RULE==========================
         */}

        <MDBContainer>
          <MDBModal isOpen={this.state.modal} toggle={() => this.toggle()}>
            <MDBModalHeader toggle={() => this.toggle()}>Importa nueva regla</MDBModalHeader>
            <MDBModalBody>
              <textarea
                onChange={(e) => this.setState({ rules: e.target.value })}
                className="form-control"
                rows="4"
              ></textarea>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="default" onClick={() => this.importRule()}>
                Importa
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  }
}

export default BtnImportRule;
