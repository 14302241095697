import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import ModalFieldGroup from "./modalFieldGroup";
import config from "../../config";
import Fields from "../formConfigurations/fields";
import "react-toastify/dist/ReactToastify.css";

class FieldListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalLoading: false,
      modal: false,
      json: [],
      fields: [],
      field: {
        label: "",
        name: "",
        type: "",
        value: {
          label: "",
          value: "",
        },
      },
      lvl: [],
      groupSelected: "",
      nameField: "",
      groupActived: [],
      rulesGroup: [],
    };

    this.create = this.create.bind(this);
    this.toggle = this.toggle.bind(this);
    this.arrayGrid = this.arrayGrid.bind(this);
  }

  create(e, name, type, option, groupActive = false, groupName = null) {
    let body = this.state.fields;
    let group = this.state.groupActived;
    let check = e.target.checked;

    // group
    if (groupActive) {
      if (group[groupName]) {
        group[groupName] = {
          key: groupName,
          required: option === "required" ? check : group[groupName]["required"],
          notRequired: option === "notRequired" ? check : group[groupName]["notRequired"],
          show: option === "show" ? check : group[groupName]["show"],
          hidden: option === "hidden" ? check : group[groupName]["hidden"],
          active: option === "active" ? check : group[groupName]["active"],
          click: option === "click" ? check : group[groupName]["click"],
          clean: option === "clean" ? check : group[groupName]["clean"],
          disabled: option === "disabled" ? check : group[groupName]["disabled"],
          cedula: option === "cedula" ? check : group[groupName]["cedula"],
          rnc: option === "rnc" ? check : group[groupName]["rnc"],
          pasaporte: option === "pasaporte" ? check : group[groupName]["pasaporte"],
          api: option === "api" ? check : group[groupName]["api"],
          type: type,
        };
      } else {
        group[groupName] = {
          key: groupName,
          required: option === "required" ? check : false,
          notRequired: option === "notRequired" ? check : false,
          show: option === "show" ? check : false,
          hidden: option === "hidden" ? check : false,
          active: option === "active" ? check : false,
          click: option === "click" ? check : false,
          clean: option === "clean" ? check : false,
          disabled: option === "disabled" ? check : false,
          cedula: option === "cedula" ? check : false,
          rnc: option === "rnc" ? check : false,
          pasaporte: option === "pasaporte" ? check : false,
          api: option === "api" ? check : false,
          type: type,
        };
      }
    }

    // individual

    if (body[name]) {
      body[name] = {
        key: name,
        required:
          option === "required" ? check : body[name]["required"] ? body[name]["required"] : false,
        notRequired:
          option === "notRequired"
            ? check
            : body[name]["notRequired"]
            ? body[name]["notRequired"]
            : false,
        show: option === "show" ? check : body[name]["show"] ? body[name]["show"] : false,
        hidden: option === "hidden" ? check : body[name]["hidden"] ? body[name]["hidden"] : false,
        active: option === "active" ? check : body[name]["active"] ? body[name]["active"] : false,
        click: option === "click" ? check : body[name]["click"] ? body[name]["click"] : false,
        clean: option === "clean" ? check : body[name]["clean"] ? body[name]["clean"] : false,
        disabled:
          option === "disabled" ? check : body[name]["disabled"] ? body[name]["disabled"] : false,
        cedula: option === "cedula" ? check : body[name]["cedula"] ? body[name]["cedula"] : false,
        rnc: option === "rnc" ? check : body[name]["rnc"] ? body[name]["rnc"] : false,
        pasaporte:
          option === "pasaporte"
            ? check
            : body[name]["pasaporte"]
            ? body[name]["pasaporte"]
            : false,
        api: option === "api" ? check : body[name]["api"] ? body[name]["api"] : false,
        type: type,
        group: groupName,
      };
    } else {
      body[name] = {
        key: name,
        required: option === "required" ? check : false,
        notRequired: option === "notRequired" ? check : false,
        show: option === "show" ? check : false,
        hidden: option === "hidden" ? check : false,
        active: option === "active" ? check : false,
        click: option === "click" ? check : false,
        clean: option === "clean" ? check : false,
        disabled: option === "disabled" ? check : false,
        cedula: option === "cedula" ? check : false,
        rnc: option === "rnc" ? check : false,
        pasaporte: option === "pasaporte" ? check : false,
        api: option === "api" ? check : false,
        type: type,
        group: groupName,
      };
    }

    console.log(body);

    this.setState({
      fields: body,
      groupActived: group,
    });

    console.log("json", this.state.json);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      field: nextProps.field,
      groupActived: this.isEmpty(nextProps.field.rules) ? [] : nextProps.field.rules.groupActived,
      fields: this.isEmpty(nextProps.field.rules) ? [] : nextProps.field.rules.rulesField,
    });

    let data = this.props.json;
    let pages = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i]) {
        pages[i] = this.fieldGroups(data[i][0]);
      }
    }

    this.setState({
      lvl: pages,
    });
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  actionConfirm(name, group, rules) {
    let fields = this.state.fields;
    let groupFieldSet = this.state.groupActived;
    // eslint-disable-next-line no-restricted-globals
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Para abrir accion de grupo, tiene que limpiar las reglas del grupo: " +
          name +
          ". Desea limpar las reglas ?"
      )
    ) {
      for (const i in rules) {
        fields[rules[i]] = {
          key: i,
          required: false,
          notRequired: false,
          show: false,
          hidden: false,
          active: false,
          click: false,
          clean: false,
          disabled: false,
          cedula: false,
          rnc: false,
          pasaporte: false,
          api: false,
          type: fields[rules[i]].type,
          group: null,
        };
      }
      groupFieldSet[group] = {
        key: group,
        required: false,
        notRequired: false,
        show: false,
        hidden: false,
        active: false,
        click: false,
        clean: false,
        disabled: false,
        cedula: false,
        rnc: false,
        pasaporte: false,
        api: false,
        type: "text",
      };
      this.setState({
        groupActived: groupFieldSet,
      });

      return true;
    }
    return false;
  }

  fieldGroups(json) {
    let data = json;
    let lvl = "";
    let lvlFather = "";
    let body = [];
    let value = [];
    let valuesGrp = [];
    for (const i in data) {
      if (data[i].type === "rules") {
        continue;
      }

      if (data[i].type === "header" && data[i].subtype === "h1") {
        lvlFather = data[i].name;
        lvl = "";
        body = [];
      }

      if (data[i].type === "header" && data[i].subtype === "h2") {
        lvl = data[i].name;
        value = [];
      }

      if (!lvl && lvlFather !== data[i].name) {
        body[data[i].name] = data[i];
        valuesGrp[lvlFather] = body;
        continue;
      }

      if (!lvlFather) {
        valuesGrp[data[i].name] = data[i];
        continue;
      }

      if (data[i].name !== lvl && data[i].name !== lvlFather) {
        value[data[i].name] = data[i];
        body[lvl] = value;
        valuesGrp[lvlFather] = body;
      }
    }

    return valuesGrp;
  }

  ruleExist(name) {
    let fields = this.state.fields;
    if (fields[name]) {
      if (
        fields[name]["required"] ||
        fields[name]["notRequired"] ||
        fields[name]["hidden"] ||
        fields[name]["show"] ||
        fields[name]["disabled"] ||
        fields[name]["active"] ||
        fields[name]["cedula"] ||
        fields[name]["pasaporte"] ||
        fields[name]["rnc"] ||
        fields[name]["portal"] ||
        fields[name]["click"] ||
        fields[name]["clean"]
      ) {
        return true;
      }
    }
    return false;
  }

  validateRule(name, id) {
    let lvl = this.state.lvl;
    let rules = [];

    for (const i in lvl) {
      if (lvl[i][id]) {
        console.log("lvl[i][id]", lvl[i][id]);
        let pagesGrp = lvl[i][id];
        for (const key in pagesGrp) {
          if (this.ruleExist(key)) {
            rules.push(key);
          }
        }
        continue;
      }
      let grp = lvl[i];

      for (const key in grp) {
        for (const i in grp[key]) {
          if (i === id) {
            let fieldset = grp[key][i];
            for (const key in fieldset) {
              if (this.ruleExist(key)) {
                rules.push(key);
              }
            }
          }
        }
      }
    }
    return rules;
  }

  groupSelected(e, name, id) {
    //console.log("name", name, id);
    let action = true;
    let rules = this.validateRule(name, id);
    //console.log("rules", rules);
    if (rules.length > 0) {
      action = this.actionConfirm(name, id, rules);
    }
    this.setState({
      groupSelected: id,
      modal: action,
      nameField: name,
    });
  }

  checkGroupButton(name) {
    if (this.state.groupActived && this.state.groupActived[name]) {
      if (
        this.state.groupActived[name]["required"] ||
        this.state.groupActived[name]["notRequired"] ||
        this.state.groupActived[name]["hidden"] ||
        this.state.groupActived[name]["show"] ||
        this.state.groupActived[name]["disabled"] ||
        this.state.groupActived[name]["active"] ||
        this.state.groupActived[name]["cedula"] ||
        this.state.groupActived[name]["pasaporte"] ||
        this.state.groupActived[name]["rnc"] ||
        this.state.groupActived[name]["portal"] ||
        this.state.groupActived[name]["clean"] ||
        this.state.groupActived[name]["click"]
      ) {
        return true;
      }
    }
    return false;
  }

  copyRule(e) {
    let rules = this.props.rulesList;
    let rulesOption = e.target.value;
    let nameRule = e.target.selectedOptions[0].getAttribute("data-name");
    if (rulesOption === "clean") {
      this.setState({
        fields: [],
      });
      return;
    }

    for (const i in rules) {
      if (rules[i].value === rulesOption && rules[i].field === nameRule) {
        this.setState({
          fields: rules[i].rules,
        });
      }
    }
  }

  arrayGrid(data) {
    this.setState({
      arrayGrid: data,
    });
  }

  render() {
    const { json } = this.props;
    return (
      <div className="App padding">
        {/**===============================================MODAL================================================= */}
        <MDBContainer>
          <MDBModal isOpen={this.props.modal} toggle={() => this.props.toggle()} size="fluid">
            <MDBModalHeader toggle={() => this.props.toggle()}>
              Configure la regla para: <b>{this.state.field.label}</b>
            </MDBModalHeader>
            <MDBModalBody id="modal-body-card-form">
              <MDBRow>
                <MDBCol>
                  <label>Nombre del campo</label>
                  <input
                    className="form-control"
                    disabled
                    value={this.state.field && this.state.field.label}
                  />
                </MDBCol>
                <MDBCol>
                  <label>valor del campo</label>
                  <input
                    className="form-control"
                    disabled
                    value={this.state.field && this.state.field.value.label}
                  />
                </MDBCol>
                <MDBCol>
                  <label>tipo del campo</label>
                  <input
                    className="form-control"
                    disabled
                    value={this.state.field && this.state.field.type}
                  />
                </MDBCol>
              </MDBRow>
              <br />
              <MDBRow>
                <MDBCol>
                  <label>Copiar regla</label>
                  <select className="form-control" onChange={(e) => this.copyRule(e)}>
                    <option value="clean">Seleccione</option>
                    {this.props.rulesList.length > 0 &&
                      this.props.rulesList.map((rules, i) => (
                        <option value={rules.value} data-name={rules.field} key={"option" + i}>
                          {rules.label + " " + rules.labelValue}
                        </option>
                      ))}
                  </select>
                </MDBCol>
                <MDBCol></MDBCol>
                <MDBCol></MDBCol>
              </MDBRow>
              <br />
              <hr />
              {json ? (
                json.length === 0 && (
                  <div>
                    <MDBIcon
                      icon="cog"
                      spin
                      size="2x"
                      style={{
                        color:
                          config.env === "production"
                            ? "#00695c"
                            : config.env === "development"
                            ? "#0d47a1"
                            : "#424242",
                      }}
                      fixed
                    />
                    <label>cargando....</label>
                  </div>
                )
              ) : (
                <h4>Este formulario tiene errores</h4>
              )}
              {json &&
                json.length > 0 &&
                json.map((pages, i) => (
                  <div key={"group" + i}>
                    <h5>
                      <b>Pagina: {i + 1}</b>
                    </h5>
                    <hr />
                    <MDBTable key={i} bordered>
                      <MDBTableHead>
                        <tr>
                          <th>Campo</th>
                          <th>Requerido</th>
                          <th>No Requerido</th>
                          <th>Oculto</th>
                          <th>Visualziar</th>
                          <th>Deshabilitado</th>
                          <th>Habilitar</th>
                          <th>Click</th>
                          <th>Limpiar</th>
                          <th>Cambiar a Cedula</th>
                          <th>Cambiar a Pasaporte</th>
                          <th>Cambiar a RNC</th>
                          <th>API - Portal</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {pages[0].length > 0 &&
                          pages[0].map(
                            (field, i) =>
                              this.state.field.name !== field.name &&
                              field.type !== "rules" && (
                                <tr key={"tr" + i}>
                                  <td align="right">
                                    {field.type === "header" && (
                                      <div key={"header-" + i}>
                                        <input
                                          type="checkbox"
                                          checked={this.checkGroupButton(field.name)}
                                          onChange={(e) =>
                                            this.groupSelected(e, field.label, field.name)
                                          }
                                        />
                                        &ensp;&ensp; Accion en Grupo
                                        <br />
                                        <br />
                                      </div>
                                    )}
                                    <Fields
                                      arrayGrid={this.arrayGrid}
                                      create={this.create}
                                      json={this.state.config}
                                      config={true}
                                      field={field}
                                      fields={this.state.fields}
                                      isRules={true}
                                    />
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "required"}
                                        disabled={
                                          this.state.fields[field.name]
                                            ? !!(
                                                this.state.fields[field.name]["notRequired"] ||
                                                this.state.fields[field.name]["hidden"] ||
                                                this.state.fields[field.name]["disabled"]
                                              )
                                            : false
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["required"]
                                        }
                                        hidden={
                                          field.type === "header" || field.type === "subtitle"
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "required")
                                        }
                                      />{" "}
                                      <br />
                                      <label
                                        hidden={
                                          field.type === "header" || field.type === "subtitle"
                                        }
                                      >
                                        REQUERIDO
                                      </label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "required"}
                                        disabled={
                                          this.state.fields[field.name]
                                            ? !!this.state.fields[field.name]["required"]
                                            : false
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["notRequired"]
                                        }
                                        hidden={
                                          field.type === "header" || field.type === "subtitle"
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "notRequired")
                                        }
                                      />{" "}
                                      <br />
                                      <label
                                        hidden={
                                          field.type === "header" || field.type === "subtitle"
                                        }
                                      >
                                        NO REQUERIDO
                                      </label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "hidden"}
                                        disabled={
                                          this.state.fields[field.name]
                                            ? !!(
                                                this.state.fields[field.name]["required"] ||
                                                this.state.fields[field.name]["show"] ||
                                                this.state.fields[field.name]["active"]
                                              )
                                            : false
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["hidden"]
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "hidden")
                                        }
                                      />
                                      <br />
                                      <label>OCULTO</label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "disabled"}
                                        disabled={
                                          this.state.fields[field.name]
                                            ? !!this.state.fields[field.name]["hidden"]
                                            : false
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["show"]
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "show")
                                        }
                                      />
                                      <br />
                                      <label>VISUALIZAR</label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "disabled"}
                                        disabled={
                                          this.state.fields[field.name]
                                            ? !!(
                                                this.state.fields[field.name]["active"] ||
                                                this.state.fields[field.name]["required"]
                                              )
                                            : false
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["disabled"]
                                        }
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "disabled")
                                        }
                                      />
                                      <br />
                                      <label
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                      >
                                        DESHABILITADO
                                      </label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "disabled"}
                                        disabled={
                                          this.state.fields[field.name]
                                            ? !!(
                                                this.state.fields[field.name]["disabled"] ||
                                                this.state.fields[field.name]["hidden"]
                                              )
                                            : false
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["active"]
                                        }
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "active")
                                        }
                                      />
                                      <br />
                                      <label
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                      >
                                        HABILITAR
                                      </label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "click"}
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["click"]
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "click")
                                        }
                                      />
                                      <br />
                                      <label
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                      >
                                        CLICK
                                      </label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "clean"}
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["clean"]
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "clean")
                                        }
                                      />
                                      <br />
                                      <label
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                      >
                                        LIMPIAR
                                      </label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "disabled"}
                                        disabled={
                                          this.state.fields[field.name]
                                            ? this.state.fields[field.name]["rnc"] ||
                                              this.state.fields[field.name]["pasaporte"]
                                            : false
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["cedula"]
                                        }
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "cedula")
                                        }
                                      />
                                      <br />
                                      <label
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                      >
                                        CAMBIAR A CEDULA
                                      </label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "disabled"}
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                        disabled={
                                          this.state.fields[field.name]
                                            ? this.state.fields[field.name]["cedula"] ||
                                              this.state.fields[field.name]["pasaporte"]
                                            : false
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["rnc"]
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "rnc")
                                        }
                                      />
                                      <br />
                                      <label
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                      >
                                        CAMBIAR A PASAPORTE
                                      </label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "disabled"}
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                        disabled={
                                          this.state.fields[field.name]
                                            ? this.state.fields[field.name]["rnc"] ||
                                              this.state.fields[field.name]["cedula"]
                                            : false
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["pasaporte"]
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "pasaporte")
                                        }
                                      />
                                      <br />
                                      <label
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                      >
                                        CAMBIAR A RNC
                                      </label>
                                    </center>
                                  </td>

                                  <td>
                                    <center className="form-group">
                                      <input
                                        type="checkbox"
                                        id={field.name + "disabled"}
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                        checked={
                                          this.state.fields[field.name] &&
                                          this.state.fields[field.name]["api"]
                                        }
                                        onClick={(e) =>
                                          this.create(e, field.name, field.type, "api")
                                        }
                                      />
                                      <br />
                                      <label
                                        hidden={
                                          field.type === "header" ||
                                          field.type === "subtitle" ||
                                          field.type === "grid"
                                        }
                                      >
                                        API - PORTAL
                                      </label>
                                    </center>
                                  </td>
                                </tr>
                              )
                          )}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                ))}
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                color={config.color}
                onClick={() =>
                  this.props.actionList(
                    this.state.groupActived,
                    this.state.fields,
                    this.state.field
                  )
                }
              >
                Guardar Regla
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>

        <ModalFieldGroup
          toggle={this.toggle}
          modal={this.state.modal}
          lvl={this.state.lvl}
          name={this.state.nameField}
          groupSelected={this.state.groupSelected}
          create={this.create}
          groupActived={this.state.groupActived}
        />
      </div>
    );
  }
}

export default FieldListModal;
