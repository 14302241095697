import React, { Component } from "react";
import CardText from "../Utilities/CardText";
import { Backend, Mask, Softexpert } from "../../data/index";
import FieldsLeft from "../RenderField/FieldsLeft";
import { toast, ToastContainer } from "react-toastify";
import { MDBCol, MDBIcon, MDBRow } from "mdbreact";
import { formatedMask } from "../../Helpers/helpers";
import Select from "react-select";
import config from "../../config";
import maskJson from "./maskJson";
import maskJsonSelect from "./maskJsonSelect";
import { LOCATION_MASK } from "./jsonData";
import { withRouter } from "react-router-dom";
// locations select options json

const { GetForms } = Softexpert;
const { GetFormularyByServiceBK } = Backend;
const { GetMaskVersionById, saveMask, GetLatestMaskVersion } = Mask;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class MaskBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mask: [],
      result: [],
      loading: false,
      field: null,
      fieldSave: [],
      msg: "flotante-label",
      maskJson: [],
      maskJsonSelect: [],
      free: false,
      errors: {},
      errorMin: "",
      amount: 0,
    };

    this.infoField = this.infoField.bind(this);
    this.arrayGrid = this.arrayGrid.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    const { location } = this.props;
    const form = location.state.form;
    const idMask = location.state.idMask;
    const mask = await GetMaskVersionById(idMask);
    let result = [];
    let jsonMaks = [];
    let jsonForm = [];

    if (form.provider === "se" || form.provider === null) {
      result = await GetForms(form.idForm);
      console.log("reuslt from ******** ERROR*******", result);
      if (result.error) {
        return this.setState({
          errors: result,
        });
      }
      result = result.ok ? result.data : [];
    }

    if (form.provider === "bk") {
      result = await GetFormularyByServiceBK(form.idservice);
      let json = result.data.Form.success ? result.data.Form.fields : [];
      result = [JSON.parse(json)];
    }

    if (idMask === "new") {
      this.setState({
        mask: [],
        result: result,
        loading: false,
        form: form,
        fieldSave: [],
        maskJson: Object.values(maskJson),
        maskJsonSelect: Object.values(maskJsonSelect),
        jsonObject: maskJson,
      });
      return;
    }

    if (mask.data.mask && !mask.data.mask.formatedFieldsMasked) {
      jsonMaks = JSON.parse(mask.data.mask.fieldsMasked);
    }

    if (mask.data.mask && mask.data.mask.formatedFieldsMasked) {
      for (let i = 0; i < result.length; i++) {
        for (let x = 0; x < result[i].length; x++) {
          if (result[i][x].name) {
            jsonForm[result[i][x].name] = result[i][x];
          }
        }
      }
      jsonMaks = formatedMask(mask.data.mask.formatedFieldsMasked, jsonForm, maskJson);
    }

    this.setState({
      mask: mask.data,
      result: result,
      loading: false,
      form: form,
      fieldSave: jsonMaks,
      maskJson: Object.values(maskJson),
      jsonObject: maskJson,
    });
  }

  hiddenPage(id) {
    let element = document.getElementById(id);

    if (element.style.display === "none") {
      element.style = "display:block";
    } else {
      element.style = "display:none";
    }
  }

  infoGridField(e, field, grid, nameGrid, setModal) {
    let fieldsGrid = field.fields;
    let allFields = [];
    for (const i in fieldsGrid) {
      console.log("fieldsGrid", fieldsGrid[i]);
      fieldsGrid[i].nameGrid = nameGrid;
      allFields[fieldsGrid[i].name] = fieldsGrid[i];
    }
    field.fieldsGrid = allFields;
    this.setState({
      field: field,
    });
    setModal && setModal(false);
    console.log("field", field, grid, nameGrid);
  }

  infoField(e, field, grid, nameGrid) {
    let selected = this.state.fieldSave[field.name];

    let fieldExample = {
      type: field.type,
      label: field.label,
      Mask: "",
      MaskParam: "",
      group: "grp01",
      orden: "100517100401",
      hidden: false,
      required: false,
      enabled: true,
      name: field.name,
      subtype: "text",
      length: 50,
      ruleIn: "",
      ruleOut: "",
      parentGrid: nameGrid,
    };

    this.setState({
      field: selected ? selected : fieldExample,
    });
  }

  changeValueGrid(e, field, fieldSave) {
    let grid = fieldSave[field.nameGrid];

    if (!grid) {
      let newField = [];
      field[e.target.name] = e.target.value;
      newField[field.name] = field;
      fieldSave[field.nameGrid] = newField;
    }
    if (grid) {
      field[e.target.name] = e.target.value;
      grid[field.name] = field;
    }
    this.setState({
      field: field,
      fieldSave: fieldSave,
      free: false,
    });
  }

  changeValue(e) {
    console.log(e);
    let field = this.state.field;
    let fieldSave = this.state.fieldSave;
    let free = true;

    if (field.grid) {
      return this.changeValueGrid(e, field, fieldSave);
    }

    field[e.target.name] = e.target.value;

    if (e.target.type === "select-one" && e.target.name === "Mask") {
      let label = e.target.label;
      field[e.target.name] = { value: e.target.value, label: label };
      this.setState({});
    }

    if (e.target.type === "select-one" && e.target.value === "-1") {
      field[e.target.name] = { value: "", label: "" };
    }

    if (
      e.target.type === "select-one" &&
      e.target.name === "valueDefault" &&
      e.target.value === "price"
    ) {
      field[e.target.name] = "price";
      delete field["quantity"];
      free = false;
    }

    if (
      e.target.type === "select-one" &&
      e.target.name === "valueDefault" &&
      e.target.value === "quantity"
    ) {
      field[e.target.name] = "quantity";
      field["quantity"] = true;
      free = false;
    }

    if (
      e.target.type === "select-one" &&
      e.target.name === "valueDefault" &&
      e.target.value === "free"
    ) {
      field[e.target.name] = "free";
      free = true;
    }

    if (
      e.target.type === "select-one" &&
      e.target.name === "valueDefault" &&
      e.target.value === "-1"
    ) {
      field[e.target.name] = "";
      free = false;
    }

    if (e.target.name === "min") {
      field["min"] = parseInt(e.target.value);
      field["min"] = field["min"] === 0 ? null : field["min"];

      if (e.target.value < field["max"]) {
        this.setState({
          errorMin: "",
        });
      }
    }

    if (e.target.name === "max") {
      field["max"] = parseInt(e.target.value);
      field["max"] = field["max"] === 0 ? null : field["max"];
    }

    if (field.nameGrid) {
      fieldSave[field.nameGrid + "_" + field.name] = field;
    }

    if (!field.nameGrid) {
      console.log("usando esta");
      fieldSave[field.name] = field;
    }

    this.setState({
      field: field,
      fieldSave: fieldSave,
      free: free,
    });
  }

  async saveAllMask() {
    let allMask = this.state.fieldSave;

    if (Object.keys(allMask).length > 0) {
      let json = JSON.stringify(Object.assign({}, allMask));
      let version = await GetLatestMaskVersion(this.state.form.id);
      let body = {
        fieldsMasked: json,
        idForm: this.state.form.id,
        maskVersion: version.data.mask + 1,
        formatedFieldsMasked: "",
        fieldLength: "",
        fieldsGridMasked: "",
      };

      await saveMask(body);

      toast.success("Se creo otra version correctamente, version: " + (version.data.mask + 1), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 250,
      });
    } else if (Object.keys(allMask).length === 0) {
      toast.warn("No se ha cambiado ninguna configuración en los campos", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }

  clean() {
    let field = this.state.field;
    field.Mask = { value: "-1", label: "" };
    field.MaskParam = "";
    field.length = "";
    field.valueDefault = "-1";
    delete this.state.fieldSave[field.name];
    this.setState({
      field: field,
    });
  }

  label(value) {
    this.setState({
      msg: value ? "flotante-label-hover" : "flotante-label",
    });
  }

  valueDefault(e) {
    let field = this.state.field;
    field.valueDefault = e.target.value;
    this.setState({
      field: field,
    });
  }

  arrayGrid(data) {
    this.setState({
      arrayGrid: data,
    });
  }

  render() {
    const { history } = this.props;

    return (
      <div>
        {this.state.loading && !this.state.errors.error && (
          <MDBIcon
            icon="cog"
            size="2x"
            spin
            style={{
              color:
                config.env === "production"
                  ? "#00695c"
                  : config.env === "development"
                  ? "#0d47a1"
                  : "#424242",
            }}
            fixed
          />
        )}
        <MDBRow>
          <ToastContainer />
          <MDBCol size="9">
            <MDBCol className="scrollingDivField">
              <br />
              {this.state.errors.error && (
                <div>
                  <h1>{this.state.errors.error}</h1>
                  <h3>{this.state.errors.data}</h3>
                  <h6>
                    {"CAMPO: " +
                      this.state.errors.field.name +
                      " - LABEL: " +
                      this.state.errors.field.label}
                  </h6>
                </div>
              )}
              {this.state.result.map((fields, i) => (
                <MDBRow key={i}>
                  <MDBCol>
                    <div className="cursor" onClick={() => this.hiddenPage("FromPage" + i)}>
                      <CardText color="#00695c" text={"Pagina: " + (i + 1)} />
                    </div>
                    <div id={"FromPage" + i}>
                      <FieldsLeft
                        key={i}
                        onlyText={true}
                        field={[fields, fields]}
                        fieldSave={this.state.fieldSave}
                        FromInput={this.infoField}
                        FromInputGrid={this.infoGridField}
                        FieldFromInput={this.state.field}
                        arrayGrid={this.arrayGrid}
                      />
                    </div>
                  </MDBCol>
                  <hr />
                </MDBRow>
              ))}
            </MDBCol>
          </MDBCol>
          <MDBCol size="3" className="border scrollingDivField">
            <h4>Información de campo</h4>
            <hr />
            {this.state.field ? (
              <div style={{ marginBottom: "100px" }}>
                <label>Nombre:</label>
                <input className="form-control inputMask" value={this.state.field.name} disabled />
                <br />
                <label>Tipo:</label>
                <input className="form-control inputMask" value={this.state.field.type} disabled />
                <br />
                <label>Titulo:</label>
                <input className="form-control inputMask" value={this.state.field.label} disabled />
                <br />
                {this.state.field && this.state.field.grid && (
                  <div>
                    <label>Id del grid:</label>
                    <input
                      className="form-control inputMask"
                      value={this.state.field && this.state.field.nameGrid}
                      disabled
                    />
                    <br />
                  </div>
                )}
                <br />

                <label>Mascara:</label>
                <Select
                  name="Mask"
                  value={
                    this.state.field.Mask && {
                      mask: this.state.field.Mask.value,
                      label: this.state.field.Mask.label,
                    }
                  }
                  onChange={(e) =>
                    this.changeValue({
                      target: {
                        name: "Mask",
                        value: e.mask,
                        label: e.label,
                        type: "select-one",
                      },
                    })
                  }
                  options={
                    this.state.field.type !== "select"
                      ? [...this.state.maskJson, ...this.state.maskJsonSelect]
                      : this.state.maskJsonSelect
                  }
                  placeholder="seleccione una mascara"
                />
                <br />

                {LOCATION_MASK.includes(this.state?.field?.Mask?.value) && (
                  <>
                    <label>Componente padre:</label>
                    <select
                      className="form-control inputMask"
                      name="typeSelect"
                      value={this.state.field.parentComponent || "-1"}
                      onChange={(e) =>
                        this.changeValue({
                          target: {
                            name: "parentComponent",
                            value: e.target.value,
                            label: e.target.value,
                            type: "select-one",
                          },
                        })
                      }
                    >
                      <option value="-1">Seleccione una opción</option>
                      {this.state.result.map((fields, i) =>
                        fields.map((field, j) => (
                          <option key={i + " " + j} value={field.name}>
                            {field.label} - {field.name}
                          </option>
                        ))
                      )}
                    </select>
                    <br />
                  </>
                )}

                {this.state.field.Mask &&
                  ["1", "18", "19", "20"].includes(this.state.field.Mask.value) && (
                    <>
                      <br />
                      <label>Campo Razón Social:</label>
                      <select
                        className="form-control inputMask"
                        name="razonsocial-select"
                        value={this.state.field.razonsocial_field || ""}
                        onChange={(e) =>
                          this.changeValue({
                            target: {
                              name: "razonsocial_field",
                              value: e.target.value,
                              label: e.target.value,
                              type: "select-one",
                            },
                          })
                        }
                      >
                        <option value="">Seleccione una opción</option>
                        {this.state.result.map((fields) =>
                          fields
                            .filter(
                              (field) =>
                                field.type === "text" && field.name !== this.state.field.name
                            )
                            .map((field, i) => (
                              <option key={i} value={field.name}>
                                {field.label} - {field.name}
                              </option>
                            ))
                        )}
                      </select>
                    </>
                  )}

                <br />

                <label>Cantidad de caracteres:</label>
                <input
                  type={"number"}
                  className="form-control inputMask"
                  name="length"
                  value={this.state.field.length}
                  onChange={(e) => this.changeValue(e)}
                />
                <br />

                <label>Valor por defecto:</label>
                <select
                  className="form-control inputMask"
                  name="valueDefault"
                  value={this.state.field.valueDefault}
                  onChange={(e) => this.changeValue(e)}
                >
                  <option value="-1">Seleccione una opción</option>
                  <option value="price">1- Precio del servicio</option>
                  <option value="quantity">2- Cantidad seleccionada en el precio</option>
                  <option value="free">4- Valor libre</option>
                </select>
                <br />

                {this.state.field.Mask && this.state.field.Mask.value !== "6" && (
                  <div>
                    <label>Parámetro de Mascara:</label>
                    <input
                      className="form-control inputMask"
                      name="MaskParam"
                      value={this.state.field.MaskParam}
                      onChange={(e) => this.changeValue(e)}
                    />
                    <br />
                  </div>
                )}

                {this.state.field.Mask && this.state.field.Mask.value === "6" && (
                  <div>
                    <label>DESDE:</label>
                    {this.state.errorMin && (
                      <label style={{ color: "red", fontSize: 10 }}>{this.state.errorMin}</label>
                    )}

                    <input
                      type="number"
                      className="form-control inputMask"
                      name="min"
                      value={this.state.field.min}
                      onChange={(e) => this.changeValue(e)}
                    />
                    <br />
                    <label>HASTA:</label>
                    <input
                      type="number"
                      className="form-control inputMask"
                      name="max"
                      value={this.state.field.max}
                      onChange={(e) => this.changeValue(e)}
                    />
                    <br />
                  </div>
                )}

                {/* Add default value to component*/}
                {this.state.free && this.state.field.valueDefault !== "" && (
                  <div>
                    <label>Agregue el valor:</label>
                    <input
                      className="form-control inputMask"
                      value={this.state.field.valueDefault}
                      onChange={(e) => this.valueDefault(e)}
                    />
                  </div>
                )}
              </div>
            ) : (
              <label>No hay campo seleccionado</label>
            )}

            <button
              className={"btn-float flotante " + config.color}
              onMouseOver={() => this.label(true)}
              onMouseOut={() => this.label(false)}
              onClick={() =>
                this.saveAllMask().then(() => {
                  sleep(2500).then(() => {
                    history.goBack();
                  });
                })
              }
            >
              <MDBIcon
                icon="save"
                onMouseOver={() => this.label(true)}
                onMouseOut={() => this.label(false)}
                size="lg"
                className="text-white cursor"
              />
            </button>

            <label className={" btn-label " + this.state.msg}>Guardar Reglas</label>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default withRouter(MaskBuilder);
