import React, { Component } from "react";

export default class SubTitle extends Component {
  render() {
    const field = this.props.field;
    const i = this.props.id;

    return (
      <div className="form-group">
        <h5 className="text-left" key={i} id={field.name}>
          <b>{field.label}</b>
        </h5>
      </div>
    );
  }
}
