import React, { Component } from "react";
import { MDBCol, MDBIcon } from "mdbreact";
import config from "../../config";
import * as PropTypes from "prop-types";

function Back(props) {
  return (
    <div className="App padding">
      <MDBCol
        onClick={props.onClick}
        onMouseOut={props.onMouseOut}
        onMouseEnter={props.onMouseEnter}
        size="12"
        className={"border radius padding " + props.shadow}
      >
        <MDBIcon
          onMouseEnter={props.onMouseEnter}
          icon="arrow-left"
          style={{
            color:
              config.env === "production"
                ? "#00695c"
                : config.env === "development"
                ? "#0d47a1"
                : "#424242",
          }}
          size="2x"
          className=" cursor"
        ></MDBIcon>
        <h5 onMouseEnter={props.onMouseEnter}>Volver</h5>
      </MDBCol>
    </div>
  );
}

Back.propTypes = {
  onClick: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseEnter: PropTypes.func,
  shadow: PropTypes.any,
};

class BtnBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shadow: "",
      newRule: false,
    };
  }

  render() {
    return (
      <Back
        onClick={() => (window.location.href = "/rules")}
        onMouseOut={() => this.setState({ shadow: "" })}
        onMouseEnter={() => this.setState({ shadow: "z-depth-1" })}
        shadow={this.state.shadow}
      />
    );
  }
}

export default BtnBack;
