import config from "../../config";
import axios from "axios";

function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token-rules")}`;
  return axios;
}

function saveRulesInit(body) {
  return axios_api(config.api + "/ruleinit")
    .post(config.api + "/ruleinit", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function updateRulesInit(body, id) {
  return axios_api(config.api + "/ruleinit/" + id)
    .put(config.api + "/ruleinit/" + id, body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function setRulesInitSelected(body) {
  return axios_api(config.api + "/ruleinit/selected")
    .post(config.api + "/ruleinit/selected", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetRulesInitByForm(id) {
  return axios_api(config.api + "/ruleinit/" + id)
    .get(config.api + "/ruleinit/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetRulesInitById(id) {
  return axios_api(config.api + "/ruleinit/id/" + id)
    .get(config.api + "/ruleinit/id/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetRulesInitSelected(id) {
  return axios_api(config.api + "/ruleinit/selected/" + id)
    .get(config.api + "/ruleinit/selected/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function deleteRulesInitByForm(id) {
  return axios_api(config.api + "/ruleinit/" + id)
    .delete(config.api + "/ruleinit/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

export {
  GetRulesInitByForm,
  updateRulesInit,
  deleteRulesInitByForm,
  saveRulesInit,
  GetRulesInitById,
  GetRulesInitSelected,
  setRulesInitSelected,
};
