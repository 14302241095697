import React, { Component } from "react";

export default class File extends Component {
  render() {
    const field = this.props.field;
    const i = this.props.id;
    return (
      <div className="form-group">
        <label htmlFor="formGroupExampleInput">{field.label}</label>
        <input type="file" className="form-control" key={i} id={field.name} disabled />
      </div>
    );
  }
}
