import React, { Component } from "react";

export default class TTextarea extends Component {
  componentWillReceiveProps(props) {}

  render() {
    const field = this.props.field;
    const i = this.props.id;
    return (
      <div className="form-group">
        <label htmlFor="formGroupExampleInput">{field.label}</label>
        <textarea
          className="form-control"
          key={i}
          name={field.name}
          id={field.name}
          disabled="disabled"
        ></textarea>
      </div>
    );
  }
}
