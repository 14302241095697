function fieldSelected(id) {
  let field = ExistField(id);
  if (field) {
    DelteSelected();
    field.setAttribute("style", "border-style: dotted;border-width: 2px; border-color:#16B9B3");
  } else {
    alert("Este campo tiene atributos null y no se puede tomar el id");
  }
}

function DelteSelected() {
  let oldField = document.getElementById("ToInput").value;
  oldField &&
    (oldField.indexOf(",") > -1
      ? allDelteSelected(oldField)
      : ExistField(oldField).setAttribute("style", ""));
}

function allDelteSelected(fields) {
  let arrayField = fields.split(",");
  for (let i = 0; i < arrayField.length; i++) {
    const id = arrayField[i];
    if (ExistField(id)) {
      ExistField(id).setAttribute("style", "");
    }
  }
}

function putStatusMask(mask) {
  switch (parseInt(mask)) {
    case 0:
      return "Cédula";
    case 1:
      return " RNC";
    case 2:
      return "Teléfono";
    case 3:
      return "Celular";
    case 4:
      return "Codia";
    case 5:
      return "Email";
    case 6:
      return "Solo Número";
    case 7:
      return "Solo Letra";
    case 8:
      return "Inmueble";
    case 9:
      return "COD.SISTEMA CAASD";
    case 10:
      return "Fecha";
    case 11:
      return "Hora";
    case 12:
      return "Decimal";
    case 13:
      return "Expediente CAID";
    case 14:
      return "NSS INABIMA";
    case 15:
      return "CARGA EDESUR";
    case 16:
      return "MONTO INABIMA";
    case 17:
      return "LATITUD";
    case 18:
      return "LONGITUD";
    case 19:
      return "MAYÚSCULAS";
    case 20:
      return "CEDULA INABIMA";
    case 21:
      return "EXPEDIENTE CAID";
    case 22:
      return "FECHA NO MAYOR ACTUAL";
    case 23:
      return "FECHA NO MENOR ACTUAL";
    case 24:
      return "EDAD NO MAYOR A LA ENVIADA";
    case 25:
      return " Máscara 25 ";
    case 26:
      return "Máscara 26 ";
    case 27:
      return " Monto general No negativo ";
    case 28:
      return "Entero no negativo";
    case 29:
      return " Fecha de vuelo menor a 48 horas ";
    case 30:
      return "Fecha de vuelo menor a 48 horas";
    case 31:
      return " fecha de embarque ";
    case 32:
      return "Validacion de maestro y solicitud abierta";
    default:
      return "Ninguna";
  }
}

function allFieldSelected(fields) {
  let arrayField = fields.split(",");
  for (let i = 0; i < arrayField.length; i++) {
    const id = arrayField[i];
    if (ExistField(id)) {
      ExistField(id).setAttribute(
        "style",
        "border-style: dotted;border-width: 2px; border-color:#16B9B3"
      );
    } else {
      alert("Este campo tiene atributos null y no se puede tomar el id");
    }
  }
}

function setMask(resultMask, json) {
  let res = resultMask.data.mask["fieldsMasked"];
  let jsonGridFields = JSON.parse(resultMask.data.mask["fieldsGridMasked"]);
  let resLength = resultMask.data.mask["fieldLength"];
  let fieldsMaskedSetters = res;
  let fieldsLength;
  let lengthValues = resLength;
  let arrayFieldsLength;

  try {
    arrayFieldsLength = lengthValues.split("#");
  } catch (e) {}
  // let fieldsMaskedSettersGrid = resultMask.data.mask['fieldsMasked'].split("\\");
  fieldsLength = resLength.split(",");
  fieldsLength.forEach(function (values) {
    lengthValues += values;
  });
  if (res[0] === "D") {
    fieldsMaskedSetters = res.split("D");
  } else if (res[0] === "\\") {
    fieldsMaskedSetters = res.split("\\");
  }
  // console.log(arrayFieldsLength,'resultado de el split2')
  /*insertando mascaras en estructura general (JSON)*/
  for (const i in json) {
    json[i].forEach(function (value) {
      /*Agregando longitud especifica en textos*/
      for (const key in arrayFieldsLength) {
        if (arrayFieldsLength[key].substring(1) === value["name"] && value["type"] === "text") {
          let x = key;
          if (arrayFieldsLength[x] !== undefined)
            value["length"] = arrayFieldsLength[parseInt(x) + 1];
          // console.log(arrayFieldsLength[parseInt(x)+1],'here')
        }
      }
      if (value["type"] === "text" || value["type"] === "time" || value["type"] === "date") {
        for (const key in fieldsMaskedSetters) {
          if (fieldsMaskedSetters[key] === value["name"]) {
            let i = key - 1;
            let maskValue = fieldsMaskedSetters[i].split("%");

            value["Mask"] = maskValue[0];
            value["MaskParam"] = maskValue[1];
          }
          if (isNaN(value["Mask"])) {
            value["Mask"] = "";
          }
        }
      }
      /*TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT*/
      if (value["type"] === "grid") {
        for (const key in value["fields"]) {
          for (const i in jsonGridFields) {
            if (value["fields"][key]["name"] + "-" + value["name"] === jsonGridFields[i]["name"]) {
              value["fields"][key]["Mask"] = jsonGridFields[i]["mask"];
            }
          }
          for (const x in arrayFieldsLength) {
            if (
              value["fields"][key]["name"] + "-" + value["name"] + "L" ===
                arrayFieldsLength[x].substring(1, 4000) &&
              arrayFieldsLength[x] !== undefined
            ) {
              value["fields"][key]["length"] = arrayFieldsLength[parseInt(x) + 1];
            }
          }
        }
      }
    });
  }
  return json;
}

function setPages(data) {
  let page = [];
  for (const key in data) {
    let field = [];
    for (const i in data[key]) {
      field.push(data[key][i]);
    }
    page.push([field]);
  }
  return page;
}

function exportMaskConfig(json, isChecked) {
  if (isChecked) {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(json));
    element.setAttribute("download", "maskConfig.txt");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    // Generate download of hello.txt file with some content
  } else {
  }
}

function MaskConfig(mask, json) {
  let maskResult = mask.data.mask;
  if (maskResult.formatedFieldsMasked) return { ok: false, json: json };
  let jsonMask = JSON.parse(maskResult.fieldsMasked);
  let selected = [];

  for (const x in jsonMask) {
    if (jsonMask[x].nameGrid) {
      selected[jsonMask[x].name] = jsonMask[x];
      jsonMask[jsonMask[x].nameGrid] = selected;
    }
  }
  for (const i in json) {
    for (const key in json[i]) {
      // set masks for fields in grid
      if (json[i][key].type === "grid") {
        for (const x in json[i][key].fields) {
          if (jsonMask[json[i][key].fields[x].name]) {
            json[i][key].fields[x].Mask = jsonMask[json[i][key].fields[x].name].Mask.value;

            json[i][key].fields[x].MaskParam = jsonMask[json[i][key].fields[x].name].MaskParam;

            json[i][key].fields[x].length = jsonMask[json[i][key].fields[x].name].length;

            json[i][key].fields[x].valueDefault =
              jsonMask[json[i][key].fields[x].name].valueDefault;

            json[i][key].fields[x].quantity = jsonMask[json[i][key].fields[x].name].quantity;

            json[i][key].fields[x].min = jsonMask[json[i][key].fields[x].name].min;

            json[i][key].fields[x].max = jsonMask[json[i][key].fields[x].name].max;

            json[i][key].fields[x].parentComponent =
              jsonMask[json[i][key].fields[x].name].parentComponent;

            json[i][key].fields[x].minDate = jsonMask[json[i][key].fields[x].name].minDate;

            json[i][key].fields[x].maxDate = jsonMask[json[i][key].fields[x].name].maxDate;

            json[i][key].fields[x].parentGrid = jsonMask[json[i][key].fields[x].name].parentGrid;

            json[i][key].fields[x].razonsocial_field =
              jsonMask[json[i][key].fields[x].name].razonsocial_field;
          }
        }
      }

      // set masks for fields
      if (jsonMask[json[i][key].name]) {
        if (json[i][key].type !== "grid") {
          json[i][key].Mask = jsonMask[json[i][key].name].Mask.value;

          json[i][key].MaskParam = jsonMask[json[i][key].name].MaskParam;

          json[i][key].length = jsonMask[json[i][key].name].length;

          json[i][key].valueDefault = jsonMask[json[i][key].name].valueDefault;

          json[i][key].quantity = jsonMask[json[i][key].name].quantity;

          json[i][key].min = jsonMask[json[i][key].name].min;

          json[i][key].max = jsonMask[json[i][key].name].max;

          json[i][key].parentComponent = jsonMask[json[i][key].name].parentComponent;

          json[i][key].minDate = jsonMask[json[i][key].name].minDate;

          json[i][key].maxDate = jsonMask[json[i][key].name].maxDate;

          json[i][key].parentGrid = jsonMask[json[i][key].name].parentGrid;

          json[i][key].razonsocial_field = jsonMask[json[i][key].name].razonsocial_field;
        }
      }
    }
  }
  return { ok: true, json: json };
}

function changePath(path) {
  switch (path) {
    case "/formulary":
      return "form";

    case "/admin":
      return "admin";

    case "/mask":
      return "mask";

    case "/dash":
      return "home";

    case "/qr":
      return "qr";

    case "/rules":
      return "rule";

    case "/tranfer":
      return "tranfer";

    case "/config":
      return "Configurations";

    case "/price":
      return "rulesprice";

    case "/users":
      return "users";

    default:
      return "dash";
  }
}

function getRouterPermission(routers, path) {
  if (path === "/home") {
    path = "dash";
  }

  if (routers && routers.length === 0) return false;

  for (const i in routers) {
    if (routers[i].router === changePath(path)) {
      return true;
    }
  }
  return false;
}

function nameRule(rule) {
  switch (rule) {
    case "0":
      return "Ocultar";
    case "1":
      return "Visualizar";
    case "2":
      return "inhabilitado";
    case "3":
      return "habilitado";
    case "4":
      return "Requerido";
    case "5":
      return "clickear";
    case "6":
      return "No Requerido";
    case "7":
      return "limpiar";
    case "8":
      return "Cambiar a CEDULA";
    case "9":
      return "Cambiar a PASAPORTE";
    case "10":
      return "Cambiar a RNC";
    case "12":
      return "Validar cedula del portal";
    default:
      break;
  }
}

function ExistField(id) {
  let field = document.getElementById(id);
  if (field) {
    return field;
  } else {
    field = document.getElementsByName(id)[0];
    if (field) {
      return field;
    } else {
      return null;
    }
  }
}

function cleanForm(json) {
  for (const key in json) {
    if (
      json[key].type === "radio-group" ||
      json[key].type === "select" ||
      json[key].type === "checkbox-group"
    ) {
      let values = json[key].values;
      for (const i in values) {
        values[i].rule = "";
      }
    }

    if (
      json[key].type !== "checkbox-group" ||
      json[key].type !== "select" ||
      json[key].type !== "radio-group" ||
      json[key].type !== "header" ||
      json[key].type !== "grid"
    ) {
      json[key].rulein = "";
      json[key].ruleOut = "";
    }

    if (json[key].type === "grid") {
      let values = json[key].fields;

      for (const i in values) {
        if (
          values[i].type === "radio-group" ||
          values[i].type === "select" ||
          values[i].type === "checkbox-group"
        ) {
          let valuesField = values[i].values;
          for (const key in valuesField) {
            valuesField[key].rule = "";
          }
        }
      }
    }
  }
  return json;
}

function CreateRuleRadioButton(data, i, key, rule) {
  for (const idRule in rule) {
    if (idRule.includes("|")) {
      if (data[i][key].name === idRule.split("|")[1]) {
        for (const idValue in data[i][key].values) {
          if (data[i][key].values[idValue].value === idRule.split("|")[0]) {
            data[i][key].values[idValue].rule = rule[idRule].split("=")[1];
          }
        }
      }
    } else {
      OldRuleRadioButton(data, i, key, rule);
    }
  }
}

function CreateRuleCheckbox(data, i, key, rule) {
  for (const idRule in rule) {
    if (data[i][key].name == idRule.split("|")[1]) {
      for (const idValue in data[i][key].values) {
        if (data[i][key].values[idValue].value == idRule.split("|")[1]) {
          if (idRule.split("|")[0] == 1) {
            data[i][key].values[idValue].rule = rule[idRule].split("=")[1];
          } else {
            data[i][key].values[idValue].ruleF = rule[idRule].split("=")[1];
          }
        }
      }
    }
  }
}

function CreateRuleSelect(data, i, key, rule) {
  for (const idRule in rule) {
    if (data[i][key].name === idRule.split("|")[1]) {
      for (const idValue in data[i][key].values) {
        if (data[i][key].values[idValue].value === idRule.split("|")[0]) {
          data[i][key].values[idValue].rule = rule[idRule].split("=")[1];
        }
      }
    }
  }
}

function CreateRuleText(data, i, key, rule) {
  for (const idRule in rule) {
    if (data[i][key].name === idRule.split("|")[1]) {
      if (idRule.split("|")[0] === "I") {
        data[i][key].ruleIn = rule[idRule].split("=")[1];
      }

      if (idRule.split("|")[0] === "O") {
        data[i][key].ruleOut = rule[idRule].split("=")[1];
      }
    }
  }
}

function OldRuleRadioButton(data, i, key, rule) {
  for (const idRule in rule) {
    if (data[i][key].name === idRule.substr(1, idRule.length)) {
      for (const idValue in data[i][key].values) {
        if (data[i][key].values[idValue].value === idRule.substr(0, 1)) {
          data[i][key].values[idValue].rule = rule[idRule].split("=")[1];
        }
      }
    }
  }
}

function convertConfigurations(resultConfig) {
  let config = resultConfig.data.configurations.config;
  let arrayConfig = config.split("&");
  let json = [];
  let resultGrid = [];
  for (const key in arrayConfig) {
    if (arrayConfig[key].includes(":(")) {
      //console.log('arrayConfig[key]', arrayConfig[key])
      resultGrid = formatGridConfig(arrayConfig[key]);
      json[resultGrid.name] = resultGrid.fields;
    }

    if (!arrayConfig[key].includes(":(")) {
      // console.log('arrayConfig[key] :', arrayConfig);
      let i = Object.keys(JSON.parse("{" + arrayConfig[key] + "}"))[0];
      json[i] = JSON.parse("{" + arrayConfig[key] + "}")[i];
    }
  }
  return { json: json, arrayGrid: resultGrid.fields };
}

function formatGridConfig(arrayConfig) {
  let jsonGrid = [];
  let gridValue = arrayConfig.slice(0, -1);
  let grid = gridValue.split(":(");
  let name = grid[0].replace(/"/g, "");
  let r = grid[1].replace(/},/gi, "}&");
  let arrayR = r.split("&");
  for (let i = 0; i < arrayR.length; i++) {
    let namePosition = Object.keys(JSON.parse("{" + arrayR[i] + "}"))[0];
    jsonGrid[namePosition] = JSON.parse("{" + arrayR[i] + "}")[namePosition];
  }

  return { name: name, fields: jsonGrid };
}

function ErrorByFirebase(idError) {
  switch (idError) {
    case "auth/email-already-in-use":
      return "Ya existe este usuario";
    case "auth/weak-password":
      return "la contraseña tiene ser minimo de  6 caracteres";
    case "auth/wrong-password":
      return "la contraseña es incorrecta";
    case "auth/too-many-requests":
      return "Se estan haciendo muchos intentos sesion en esta cuenta";
    case "auth/user-not-found":
      return "No existe una cuenta con este usuario";
    case "auth/invalid-email":
      return "El formato del email no es correcto";
    case "auth/user-disabled":
      return "Este usuario esta fue desactivado, por favor contacte al administrador";
    default:
      break;
  }
}

function userData() {
  return [
    {
      type: "text",
      label: "Nombre (s):",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100292100034",
      hidden: false,
      required: true,
      enabled: true,
      name: "a3nombres",
      subtype: "text",
      userElement: true,
    },
    {
      type: "text",
      label: "Primer Apellido",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100292100225",
      hidden: false,
      required: true,
      enabled: true,
      name: "a4apellido1",
      subtype: "text",
      userElement: true,
    },
    {
      type: "text",
      label: "Segundo Apellido",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100293100416",
      hidden: false,
      required: false,
      enabled: true,
      name: "a5apellido2",
      subtype: "text",
      userElement: true,
    },
    {
      type: "text",
      label: "Cédula",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100034",
      hidden: false,
      required: true,
      enabled: true,
      name: "a2idciudadano",
      subtype: "text",
      userElement: true,
    },
    {
      type: "date",
      label: "Fecha",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100034",
      hidden: false,
      required: true,
      enabled: true,
      name: "a7fecharegistro",
      subtype: "text",
      userElement: true,
    },
    {
      type: "time",
      label: "Hora",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100034",
      hidden: false,
      required: true,
      enabled: true,
      name: "a8horaregistro",
      subtype: "text",
      userElement: true,
    },
    {
      type: "text",
      label: "civil",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100034",
      hidden: false,
      required: true,
      enabled: true,
      name: "b4estadocivil",
      subtype: "text",
      userElement: true,
    },
    {
      type: "date",
      label: "Fecha de nacimiento",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100034",
      hidden: false,
      required: true,
      enabled: true,
      name: "b5fechanac",
      subtype: "text",
      userElement: true,
    },
    {
      type: "text",
      label: "lugar de nacimiento",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100034",
      hidden: false,
      required: true,
      enabled: true,
      name: "b6lugarnac",
      subtype: "text",
      userElement: true,
    },
    {
      type: "text",
      label: "pais",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100034",
      hidden: false,
      required: true,
      enabled: true,
      name: "b8paisdir",
      subtype: "text",
      userElement: true,
    },
    {
      type: "text",
      label: "sexo",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100034",
      hidden: false,
      required: true,
      enabled: true,
      name: "c2sexo",
      subtype: "text",
      userElement: true,
    },

    {
      type: "text",
      label: "portal",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100034",
      hidden: false,
      required: true,
      enabled: true,
      name: "logico01",
      subtype: "text",
      userElement: true,
    },

    {
      type: "text",
      label: "Teléfono",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100225",
      hidden: false,
      required: true,
      enabled: true,
      name: "c7telefono",
      subtype: "text",
      userElement: true,
    },
    {
      type: "text",
      label: "Celular",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100332100416",
      hidden: false,
      required: true,
      enabled: true,
      name: "c6celular",
      subtype: "text",
      userElement: true,
    },
    {
      type: "text",
      label: "Dirección (calle / no. ): ",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100373100033",
      hidden: false,
      required: true,
      enabled: true,
      name: "b3direccion",
      subtype: "text",
      userElement: true,
    },
    {
      type: "text",
      father_id: null,
      entity: "provincia",
      group: "grp01",
      label_persist: "A",
      hidden: false,
      required: true,
      enabled: true,
      label: "Provincia",
      orden: "100409100033",
      name: "b9provincia",
      values: "null",
      data_portal: true,
      select_portal_type: "provincia",
      userElement: true,
    },
    {
      type: "text",
      father_id: null,
      entity: "municipio",
      group: "grp01",
      label_persist: "A",
      hidden: false,
      required: false,
      enabled: true,
      label: "Ciudad/Municipio",
      orden: "100409100225",
      name: "c4municipio",
      values: "null",
      data_portal: true,
      select_portal_type: "municipio",
      userElement: true,
    },
    {
      type: "text",
      father_id: null,
      entity: "sector",
      group: "grp01",
      label_persist: "A",
      hidden: false,
      required: false,
      enabled: true,
      label: "Sector",
      orden: "100409100416",
      name: "c1sectordir",
      values: "null",
      data_portal: true,
      select_portal_type: "sector",
      userElement: true,
    },
    {
      type: "text",
      label: "Correo electrónico",
      Mask: "",
      MaskParam: false,
      group: "grp01",
      orden: "100445100035",
      hidden: false,
      required: true,
      enabled: true,
      name: "c5correo",
      subtype: "text",
      userElement: true,
    },
  ];
}

function formatedMask(json, form, maskJson) {
  let format = json.split(`\\`);
  let fields = [];

  for (let i = 0; i < format.length; i++) {
    const element = format[i];
    let resultMaks = element.split("=>");
    console.log("resultMasks", resultMaks);

    if (element) {
      let field = resultMaks[0].split(" ");
      let mask = field[0].split("-")[1];
      let name = field[1];
      if (form[name]) {
        let maskParam = mask.split("%");
        fields[name] = form[name];
        console.log("form[name]");
        if (maskParam.length === 1) {
          console.log("name", name);
          console.log("fields", fields);
          fields[name].Mask = { value: mask, label: maskJson[mask].label };
        }
        if (maskParam.length > 1) {
          fields[name].Mask = {
            value: maskParam[0],
            label: maskJson[maskParam[0]].label,
          };
          fields[name].MaskParam = maskParam[1];
        }
        fields[name].valueDefault = "";
      }
    }
  }
  return fields;
}

export {
  setPages,
  putStatusMask,
  fieldSelected,
  nameRule,
  allFieldSelected,
  DelteSelected,
  CreateRuleRadioButton,
  CreateRuleSelect,
  CreateRuleCheckbox,
  ErrorByFirebase,
  setMask,
  exportMaskConfig,
  userData,
  convertConfigurations,
  CreateRuleText,
  getRouterPermission,
  formatedMask,
  MaskConfig,
  cleanForm,
};
