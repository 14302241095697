import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import {
  FText,
  TDate,
  TFile,
  THeaders,
  TRadio,
  TSelect,
  TSubTitle,
  TText,
  TTextarea,
  TTime,
} from "../index";

import Fields from "../../formConfigurations/fields";

export default class TGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.setModal = this.setModal.bind(this);
  }

  setModal(modal) {
    this.setState({
      modal: modal,
    });
  }

  toggle = () => {
    if (!this.state.modal) {
      this.props.config && this.props.arrayGrid([]);
      this.setState({
        modal: true,
      });
    }

    if (this.state.modal) {
      this.props.config && this.props.arrayGrid([]);
      this.setState({
        modal: false,
      });
    }
    if (this.props.config) {
      this.props.selectedCheck(this.props.json, "hola");
    }
  };

  fieldsGrid(field) {
    field.name = this.props.field.name + "_" + field.name;
    return field;
  }

  render() {
    const field = this.props.field;
    const i = this.props.id;

    if (this.props.config) {
      return (
        <MDBContainer>
          <MDBBtn onClick={this.toggle}>{"GRID: " + field.label}</MDBBtn>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader>{field.label}</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBTable key={i} bordered>
                  <MDBTableHead>
                    <tr>
                      <th>Campo</th>
                      <th>Requerido</th>
                      <th>Oculto</th>
                      <th>Deshabilitado</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {field.fields.map((field, i) => (
                      <tr key={"tr-" + i}>
                        <td key={"tr-fields-" + i}>
                          <Fields config={true} field={field} />
                        </td>

                        <td key={"td-required-" + i}>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "required"}
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                              onClick={() =>
                                this.props.create(
                                  field.name,
                                  this.props.field.type,
                                  this.props.field.name
                                )
                              }
                            />
                          </div>
                        </td>

                        <td key={"td-hidden-" + i}>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "hidden"}
                              onClick={() =>
                                this.props.create(
                                  field.name,
                                  this.props.field.type,
                                  this.props.field.name
                                )
                              }
                            />
                          </div>
                        </td>

                        <td key={"td-disabled-" + i}>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "disabled"}
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                              onClick={() =>
                                this.props.create(
                                  field.name,
                                  this.props.field.type,
                                  this.props.field.name
                                )
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      );
    }
    let nameGrid = field.name;
    return (
      <div id={i} className="form-group">
        <MDBContainer>
          <MDBBtn
            onClick={() => {
              this.toggle();
            }}
          >
            {"GRID: " + field.label}
          </MDBBtn>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
            <MDBModalHeader onClick={(e) => this.props.ToInput(e, field.name)}>
              {field.label}
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                {field.fields.map((field, i) =>
                  field.type === "text" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => {
                        console.log(field);
                        this.props.ToInput(e, field, true, this.props.field.name);
                      }}
                      key={"grid-col-text-" + i + field.label}
                    >
                      <TText
                        key={i}
                        id={i + field.label}
                        fieldToInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "text" && this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      key={"col-" + i + field.label}
                      onClick={(e) => {
                        console.log(field);
                        this.props.ToInput(e, field, true, this.props.field.name);
                      }}
                    >
                      <FText
                        key={i}
                        id={i + field.label}
                        setModal={this.setModal}
                        nameGrid={nameGrid}
                        grid={true}
                        fieldSave={this.props.fieldSave}
                        textForMask={this.props.onlyText}
                        handlerFromInput={this.props.ToInput}
                        fieldFromInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "radio-group" && !this.props.onlyText ? (
                    <MDBCol size="6" className="cursor" key={"col-" + i + field.label}>
                      <TRadio
                        key={i}
                        fieldToInput={this.props.FieldToInput}
                        handlerToInput={this.props.ToInput}
                        id={i + field.label}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "select" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) =>
                        this.props.ToInput(
                          field.name,
                          field.type,
                          { label: "", value: "" },
                          field.label,
                          e
                        )
                      }
                      key={"col-" + i + field.label}
                    >
                      <TSelect key={i} id={i + field.label} field={field} />
                    </MDBCol>
                  ) : field.type === "file" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                      key={"col-" + i + field.label}
                    >
                      <TFile key={i} id={i + field.label} field={field} />
                    </MDBCol>
                  ) : field.type === "header" && !this.props.onlyText ? (
                    <MDBCol
                      size="12"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                      key={"col-" + i + field.label}
                    >
                      <hr />
                      <THeaders
                        id={i + field.label}
                        handlerToInput={this.props.ToInput}
                        field={field}
                        key={i + field.label}
                      />
                    </MDBCol>
                  ) : field.type === "textarea" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                      key={"col-" + i + field.label}
                    >
                      <TTextarea
                        key={i}
                        id={i + field.label}
                        fieldToInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "time" && this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      id={"col-" + i + field.label}
                      key={"col-" + i + field.label}
                    >
                      <FText
                        key={i}
                        id={i + field.label}
                        setModal={this.setModal}
                        nameGrid={nameGrid}
                        grid={true}
                        fieldSave={this.props.fieldSave}
                        textForMask={this.props.onlyText}
                        handlerFromInput={this.props.ToInput}
                        fieldFromInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "date" && this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      id={"col-" + i + field.label}
                      key={"col-" + i + field.label}
                    >
                      <FText
                        key={i}
                        id={i + field.label}
                        setModal={this.setModal}
                        nameGrid={nameGrid}
                        grid={true}
                        fieldSave={this.props.fieldSave}
                        textForMask={this.props.onlyText}
                        handlerFromInput={this.props.ToInput}
                        fieldFromInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "time" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                      key={"col-" + i + field.label}
                    >
                      <TTime
                        key={i}
                        id={i + field.label}
                        fieldToInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "date" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                      key={"col-" + i + field.label}
                    >
                      <TDate
                        key={i}
                        id={i + field.label}
                        fieldToInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : (
                    field.type === "subtitle" &&
                    !this.props.onlyText && (
                      <MDBCol
                        size="12"
                        className="cursor"
                        onClick={(e) => this.props.ToInput(e, field.name)}
                        key={"col-" + i + field.label}
                      >
                        <TSubTitle key={i} id={i + field.label} field={field} />
                      </MDBCol>
                    )
                  )
                )}
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  }
}
