import React, { Component } from "react";
import config from "../../config";
import { MDBIcon } from "mdbreact";

class Body extends Component {
  render() {
    const { option } = this.props;
    console.log("option :", option);
    switch (option) {
      case 0:
        return this.welcome();
      case 1:
        return this.NewForm();
      case 2:
        return this.sendForm();
      case 3:
        return this.configfield();
      case 4:
        return this.rulesType();
      case 5:
        return this.rulesfield();
      case 6:
        return this.rulesInit();
      case 7:
        return this.rulesPrice();
      case 9:
        return this.masks();
      case 11:
        return this.configPortal();
      default:
        return this.notFound();
    }
  }

  //=========================== RENDER TEMPLATES================================

  notFound() {
    return (
      <div>
        <br />
        <br />
        <br />
        <img
          alt=""
          className="img-fluid"
          width="200"
          src="https://png.pngtree.com/element_our/png_detail/20181206/magnifying-glass-vector-icon-png_262124.jpg"
        />
        <h1>No se ha encontrado esta documentación</h1>
        <h6>
          Estamos trabajando para crear la documentación más completa y sencilla para usted. Este
          documento se estará actualizando semanal hasta tener una documentación completa de la
          aplicación de reglas. Gracias de antemano
        </h6>
      </div>
    );
  }

  welcome() {
    return (
      <div>
        <h1>Bienvenido a la documentación!</h1>
        <img
          alt=""
          className="img-fluid"
          src="https://static.vecteezy.com/system/resources/previews/000/357/523/non_2x/documentation-vector-icon.jpg"
        />
        <h4>
          <b>En el menú a tu izquierda elige una opción para leer un tema en especifico.</b>
        </h4>
        <h6>
          <b>
            Si no encuentras la solución o los pasos para completar una configuración, por favor
            comunicarlo al equipo técnico, Gracias!.
          </b>
        </h6>
      </div>
    );
  }

  NewForm() {
    return (
      <div>
        <h1>CREAR UN FORMULARIO</h1>
        <br />
        <br />
        <p className="text-left">
          <b> ¿Qué es? </b>
        </p>
        <p className="text-left">
          Este módulo es para crear formularios que vienen desde softexpert o desde el portal
          administrativo. Solo se necesita el id del formulario, la institución y el servicio que
          quiere guardar. Sino le han suministrados estos datos lo puede encontrar en la plataforma
          de softexpert o en el portal administrativo. En dado caso no lo pueda conseguir por favor
          informarlo a su supervisor.
        </p>
        <br />
        <p className="text-left">
          <b>¿Cómo se hace?</b>
        </p>
        <p className="text-left">
          Existe dos forma para obtener un formulario en la aplicación,{" "}
          <b> desde el proveedor softexper.</b> y <b> desde el proveedor portal administrativo</b>
        </p>
        <p className="text-left">
          <b>1) Guardar un formulario de softexpert</b>
        </p>
        <p className="text-left">
          <b>1.1 ir a la opción Formularios/envió de formulario</b>
        </p>
        <img alt="" src={require("../../img/1.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.2 elegir la opción nuevo formulario de SE</b>
        </p>
        <img alt="" src={require("../../img/5.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.3 insertar el id del formulario que quiere guardar en la aplicación</b>
        </p>
        <img alt="" src={require("../../img/6.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.4 buscar la institución al cual pertenece el formulario</b>
        </p>
        <img alt="" src={require("../../img/2.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.5 buscar el servicio que necesite</b>
        </p>
        <img alt="" src={require("../../img/7.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.6 inserta el logo de la institución (OPCIONAL)</b>
        </p>
        <img alt="" src={require("../../img/3.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.7 dar click al boton guardar para terminar</b>
        </p>
        <img alt="" src={require("../../img/4.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>2) Guardar un formulario del portal administrativo</b>
        </p>
        <p className="text-left">
          <b>2.1 elegir la opción nuevo formulario de BK</b>
        </p>
        <img alt="" src={require("../../img/bk.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>2.2 elegir la institución del formulario</b>
        </p>
        <img alt="" src={require("../../img/instbk.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>2.3 elegir el servicio que desea</b>
        </p>
        <img alt="" src={require("../../img/sbk.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>2.4 se mostrará el nombre del formulario en pantalla</b>
        </p>
        <img alt="" src={require("../../img/namebk.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>2.5 inserta la imagen de la institución (OPCIONAL)</b>
        </p>
        <img alt="" src={require("../../img/imgbk.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>2.6 dar clic al botón guardar para terminar</b>
        </p>
        <img alt="" src={require("../../img/imgbk.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>2.7 buscar el formulario guardado</b>
        </p>
        <img alt="" src={require("../../img/final.JPG")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }

  sendForm() {
    return (
      <div>
        <h1>Envió de formulario al portal</h1>
        <br />
        <br />
        <p className="text-left">
          <b>¿Qué es?</b>
        </p>
        <p className="text-left">
          Este módulo fue creado para enviar los formularios con todas sus configuraciones hacia el
          portal servicio en línea. Podrás crear formularios dinámicos en la aplicación y simular el
          comportamiento de los formularios de softexpert en el portal servicio en línea.{" "}
        </p>
        <br />
        <p className="text-left">
          <b>¿Cómo se hace?</b>
        </p>
        <p className="text-left">
          En el modulo de <b>Envió de formularios</b> se muestra una tabla con todos los formularios
          disponible para el portal.
        </p>
        <p className="text-left">
          <b>1.1 ir a Formularios/envío de formulario</b>
        </p>
        <img alt="" src={require("../../img/1.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.2 se busca el formulario y le damos al botón ver</b>
        </p>
        <img alt="" src={require("../../img/f1.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.3 Al darle clic al botón ver se asigna todas las configuraciones del formulario. Nota:
            si aparecen errores de formulario dirigirse a (Errores comunes) en el menú
          </b>
        </p>
        <img alt="" src={require("../../img/f2.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.4 si el icono se vuelve verde, es porque se le asigno una configuración, de lo
            contrario el formulario se enviará sin esa configuración
          </b>
        </p>
        <img alt="" src={require("../../img/f2.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.5 seleccione una regla para enviar. Puede seleccionar (Enviar sin reglas si no tiene).
            Si quiere crear una regla de formulario poder ir a (Reglas de formularios) en el menú
          </b>
        </p>
        <img alt="" src={require("../../img/f3.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.6 Luego seleccione una versión de máscara. Puede seleccionar (Enviar sin máscara sino
            tiene) o (Enviar con máscara de softexpert) si quiere enviar reglas desde softexpert.
            Para crear reglas en la aplicación puede ir a (Mascaras) en el menú. Si quiere aprender
            a hacer máscara desde softexpert entre al siguiente link{" "}
            <a href="https://docs.google.com/document/d/1tdZlOWZvWuPG0LLI2K8GUWKEy01EWk6uCMjnAH5eS94/edit?ts=5c1a630a">
              Configuracion para el portal
            </a>
          </b>
        </p>
        <img alt="" src={require("../../img/f4.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.7 ir a la opción validar formulario. Si aparecen errores por favor ir a (Errores
            comunes) y resolverlos
          </b>
        </p>
        <img alt="" src={require("../../img/f5.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/f6.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/f7.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.1 ir a la opción enviar al portal y se enviara una versión nueva al portal. En este
            punto comuníquese con el administrador del portal para que le activen su versión de
            formulario
          </b>
        </p>
        <img alt="" src={require("../../img/f8.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }

  configfield() {
    return (
      <div>
        <h1>Configuración de campos</h1>
        <br />
        <br />
        <p className="text-left">
          <b>¿Qué es?</b>
        </p>
        <p className="text-left">
          Este modulo esta creado para darle comportamiento a los campos como requerido, oculto y
          deshabilitado al cargar un formulario en el portal. Algunos campos tienen restricciones
          con estas configuraciones con las cabeceras y los grid.{" "}
        </p>
        <br />
        <p className="text-left">
          <b>¿Cómo se hace?</b>
        </p>
        <p className="text-left">
          En el modulo de <b>Configuración de Campos</b> se muestra una tabla con todos los
          formularios disponible para el portal.
        </p>
        <p className="text-left">
          <b>1.1 ir a Formularios/Configuración de Campos</b>
        </p>
        <img alt="" src={require("../../img/c1.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/c2.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.2 se busca el formulario y le damos al botón CONFIGURAR CAMPOS</b>
        </p>
        <img alt="" src={require("../../img/c3.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.3 Al darle clic al botón (configurar campos) se muestran todos los campos del
            formulario con 3 checkbox para seleccionar su configuración
          </b>
        </p>
        <img alt="" src={require("../../img/c4.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.4 seleccione una configuración por campo</b>
        </p>
        <img alt="" src={require("../../img/c5.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.5 dependiendo la opción seleccionada, otras opciones se desactivarán. Ejemplo: si
            activa oculto la opción requerido se desactivará
          </b>
        </p>
        <img alt="" src={require("../../img/c6.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.6 Existen campos que no se le puede asignar algunas configuraciones como a las
            cabeceras no se le puede asignar requerido
          </b>
        </p>
        <img alt="" src={require("../../img/c7.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.7 luego de asignar las configuración le damos a guardar</b>
        </p>
        <img alt="" src={require("../../img/c8.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/c9.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.8 Ahora solo tenemos que ir a (Envió de formulario) enviar el formulario y esperar que
            el icono de configuración se ponga verde
          </b>
        </p>
        <img alt="" src={require("../../img/c10.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.9 sino guardamos una configuración por lo menos una vez, el formulario se enviara con
            la configuración de softexpert
          </b>
        </p>
        <img alt="" src={require("../../img/c11.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }

  rulesType() {
    return (
      <div>
        <h1>Tipos de Reglas de formulario y su funcionamiento</h1>
        <br />
        <br />
        <p className="text-left">
          <b>¿Qué es?</b>
        </p>
        <p className="text-left">
          Las reglas se dividen en 2, en campo que accionan reglas y en campos que las reciben{" "}
        </p>
        <br />
        <p className="text-left">
          Ejemplo: un radiobutton que al cliquearse esconde un campo texto. En este caso el
          radiobutton es el campo que acciona y el campo texto es el campo que recibe la regla{" "}
        </p>
        <br />
        <img alt="" src={require("../../img/t1.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            Las reglas se comportan de la siguiente manera. (CAMPO QUE ACCIONA = REGLAS : CAMPO QUE
            RECIBE)
          </b>
        </p>
        <img alt="" src={require("../../img/t2.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            Esta combinación se mostrara en la parte del resumen en la ventana de reglas de
            formularios, cuando vaya a crear una regla.
          </b>
        </p>
        <img alt="" src={require("../../img/t3.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>Las reglas cambian el comportamiento dependiendo el campo que las accionen.</b>
        </p>
        <p className="text-left">
          <b>1) reglas de radiobutton.</b>
        </p>
        <p className="text-left">
          <b>2) reglas de listas.</b>
        </p>
        <p className="text-left">
          <b>3) reglas de checkbox.</b>
        </p>
        <p className="text-left">
          <b>4) reglas de entra y salida en campos de textos.</b>
        </p>
        <hr />
        <h5>REGLAS DE RADIOBUTTON</h5>
        <p className="text-left">
          <b>
            1.1 Las reglas de radiobutton se componen de la siguiente manera (OPCION SELECCIONADA |
            ID DEL RADIOBUTTON).
          </b>
        </p>
        <img alt="" src={require("../../img/T4.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.2 hacemos clic en el radibutton (EN LA PARTE DE CAMPOS QUE ACCIONAN REGLAS) y se
            mostrara la información de radiobutton en (CAMPO).
          </b>
        </p>
        <img alt="" src={require("../../img/t5.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/t6.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/t7.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.3 si queremos cambiar el valor solo tenemos que dar clic en otro valor del radibutton
            (EN LA PARTE DE CAMPOS QUE ACCIONAN REGLAS) y la información cambiara.
          </b>
        </p>
        <img alt="" src={require("../../img/t8.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.4 el siguiente paso es seleccionar un campo en la ventana para aplicar reglas.</b>
        </p>
        <img alt="" src={require("../../img/t9.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.5 seleccionamos la regla deseada y vemos el resumen.</b>
        </p>
        <img alt="" src={require("../../img/t10.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <hr />
        <h5>REGLAS DE LISTAS</h5>
        <p className="text-left">
          <b>
            2.1 las reglas con listas se componen de la siguiente forma. (OPCION SELECCIONADA | ID
            DE LA LISTA){" "}
          </b>
        </p>
        <img alt="" src={require("../../img/t11.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            2.2 hacemos clic en la lista (EN LA PARTE DE CAMPOS QUE ACCIONAN REGLAS) y se mostrara
            la información de la lista en (CAMPO).
          </b>
        </p>
        <img alt="" src={require("../../img/t12.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            2.3 si quieremos cambiar el valor solo tenemos que dar clic en otro valor de la lista
            (EN LA PARTE DE CAMPOS QUE ACCIONAN REGLAS) y la información cambiara.
          </b>
        </p>
        <img alt="" src={require("../../img/t12.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>2.4 el siguiente paso es seleccionar un campo en la ventana para aplicar reglas.</b>
        </p>
        <img alt="" src={require("../../img/t13.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>2.5 seleccionamos la regla deseada y vemos el resumen.</b>
        </p>
        <img alt="" src={require("../../img/t14.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <hr />
        <h5>REGLAS DE CHECKBOX</h5>
        <p className="text-left">
          <b>
            3.1 Las reglas de checkbox se componen de la siguiente manera (VALOR DE CHECK | ID DEL
            CHECKBOX).
          </b>
        </p>
        <img alt="" src={require("../../img/t15.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            3.2 hacemos clic en el checkbox (EN LA PARTE DE CAMPOS QUE ACCIONAN REGLAS) y se
            mostrara la informacion de checkbox en (CAMPO).
          </b>
        </p>
        <img alt="" src={require("../../img/t16.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            3.3 si quieremos cambiar el valor solo tenemos que dar clic encima del checkbox (EN LA
            PARTE DE CAMPOS QUE ACCIONAN REGLAS) y la informacion cambiara.
          </b>
        </p>
        <img alt="" src={require("../../img/t17.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>3.4 el siguiente paso es seleccionar un campo en la ventana para aplicar reglas.</b>
        </p>
        <img alt="" src={require("../../img/t18.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>3.5 seleccionamos la regla deseada y vemos el resumen.</b>
        </p>
        <img alt="" src={require("../../img/t19.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <hr />
        <h5>REGLAS DE ENTRA Y SALIDA</h5>
        <p className="text-left">
          <b>
            4.1 Las reglas de entra y salida se componen de la siguiente manera (OPCION | ID DEL
            CAMPO) nota: solo con campo de texto, fecha, hora y descripcion.
          </b>
        </p>
        <img alt="" src={require("../../img/t20.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            4.2 hacemos clic en el icono de salida de un campo (EN LA PARTE DE CAMPOS QUE ACCIONAN
            REGLAS) y se mostrara la informacion del campo en (CAMPO).
          </b>
        </p>
        <img alt="" src={require("../../img/t21.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            4.3 si quieremos cambiar el valor solo tenemos que dar clic encima de la otra opcion del
            campo (EN LA PARTE DE CAMPOS QUE ACCIONAN REGLAS) y la informacion cambiara.
          </b>
        </p>
        <img alt="" src={require("../../img/t22.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>4.4 el siguiente paso es seleccionar un campo en la ventana para aplicar reglas.</b>
        </p>
        <img alt="" src={require("../../img/t23.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>4.5 seleccionamos la regla deseada y vemos el resumen.</b>
        </p>
        <img alt="" src={require("../../img/t244.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>AHORA ESTAS LISTO PARA SEGUIR A LA DOCUMENTACION DE (REGLAS DE FORMULARIOS).</b>
        </p>
      </div>
    );
  }

  rulesfield() {
    return (
      <div>
        <h1>Reglas de formularios</h1>
        <br />
        <br />
        <p className="text-left">
          <b>¿Qué es?</b>
        </p>
        <p className="text-left">
          Este módulo esta creado para darle replicar el comportamiento de las reglas de softexpert
          en los formularios del portal. Podrá crear todo tipos de reglas y cambiar el
          comportamiento de su formulario a su gusto.{" "}
        </p>
        <br />
        <p className="text-left">
          <b>¿Cómo se hace?</b>
        </p>
        <p className="text-left">
          En el módulo de <b>Reglas de Formularios</b> se muestra una tabla con todos los
          formularios disponible para el portal.
        </p>
        <p className="text-left">
          <b>
            se necesita saber el funcionamiento de las reglas y los tipos de reglas. Para saber esta
            información por favor ir a documentación (TIPOS DE REGLAS DE FORMULARIOS) en el menú
          </b>
        </p>
        <p className="text-left">
          <b>1.1 ir a Reglas/Reglas de Formularios</b>
        </p>
        <img alt="" src={require("../../img/r1.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/r2.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.2 se busca el formulario y le damos al botón VER REGLAS</b>
        </p>
        <img alt="" src={require("../../img/r3.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/r4.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.3 Al darle clic al botón (VER REGLAS), se mostraran cuatros botones entre ellos se
            encuentra el botón (NUEVA REGLA). aquí se creara una reglas desde cero
          </b>
        </p>
        <img alt="" src={require("../../img/r5.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.4 Creando reglas con radiobutton</b>
        </p>
        <iframe
          title="1"
          width="1280"
          height="699"
          src="https://www.youtube.com/embed/nY46Bv8YPrE"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="text-left">
          <b>1.5 Creando reglas con listas de valores</b>
        </p>
        <iframe
          title="1"
          width="1280"
          height="699"
          src="https://www.youtube.com/embed/TZKOFQwHXyc"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="text-left">
          <b>1.6 Creando reglas con checkbox</b>
        </p>
        <iframe
          title="1"
          width="1280"
          height="699"
          src="https://www.youtube.com/embed/QxJZFXCL0Lw"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="text-left">
          <b>1.7 Creando reglas con campos de entrada y salida</b>
        </p>
        <iframe
          title="1"
          width="1280"
          height="699"
          src="https://www.youtube.com/embed/07BVGIhj6KQ"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="text-left">
          <b>1.8 Copiando reglas de otros campos</b>
        </p>
        <iframe
          title="1"
          width="1280"
          height="699"
          src="https://www.youtube.com/embed/ANFxOiQ6sjo"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>

        {/* <p className="text-left"><b>1.4 se muestra una ventana dividida en dos una para (CAMPOS QUE ACCIONAN REGLAS) y otra para (RESUMEN DE LAS ACCIONES)</b></p>
        <img alt="" src={require('../../img/r6.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.5 si le damos clic a un campo la pantalla se dividirá en tres partes y se mostraran los campos para aplicar las reglas</b></p>
        <img alt="" src={require('../../img/r7.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.6 si le damos clic a un campo donde se aplicara una regla, se mostraran las reglas disponibles</b></p>
        <img alt="" src={require('../../img/r8.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.7 Encima tenemos la información de los campos</b></p>
        <img alt="" src={require('../../img/r10.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.8 si le damos clic a una regla se muestra el resultado en el resumen de lo que hicimos</b></p>
        <img alt="" src={require('../../img/r9.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.9 podemos darle clic al botón rojo con la x para eliminar una combinación en especifico</b></p>
        <img alt="" src={require('../../img/r11.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>

        <p className="text-left"><b>1.10 existen reglas que no puede estar activa al mismo tiempo en el mismo campo, ocultar y visualizar en el mismo campo</b></p>
        <img alt="" src={require('../../img/r12.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.11 de igual manera existen reglas contrarias que no puede estar activa en el mismo campo, como ocultar y requerido</b></p>
        <img alt="" src={require('../../img/r13.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.12 la aplicación tiene funciones para que el usuario puede hacer el trabajo más fácil, como la opción de (ctrl + clic izquierdo) para seleccionar varios campos</b></p>
        <img alt="" src={require('../../img/r14.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.13 los campos seleccionados estarán en la sección de campo separado por comas y hacer clic en una regla se crearan las modificaciones</b></p>
        <img alt="" src={require('../../img/r15.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.14 otra función es la de seleccionar varios campos arrastrando el raton por encima de lo campos y hará la misma funcionalidad que la del punto 1.12</b></p>
        <img alt="" src={require('../../img/r16.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.15 A la hora de abrir un grid se le puede cliquear encima para asignarle regla o abrirlo para asignarle reglas a los campos de los grid</b></p>
        <img alt="" src={require('../../img/r17.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <img alt="" src={require('../../img/r18.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.16 si  terminamos con las combinaciones le damos al botón generar reglas</b></p>
        <img alt="" src={require('../../img/r19.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.17 se mostrará un resumen de lo que hicimos más detallado, le damos aguardar y se creará una nueva versión de regla</b></p>
        <img alt="" src={require('../../img/r20.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <img alt="" src={require('../../img/r21.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.18 si volvemos hacia atrás en la lista de reglas estará la versión creada</b></p>
        <img alt="" src={require('../../img/r22.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <img alt="" src={require('../../img/r19.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left"><b>1.19 solo faltaría enviar un formulario con la versión de la regla, para saber cómo enviar formulario puede ir a documentación y elegir (ENVIO DE FORMULARIO) </b></p>
        <p className="text-left"><b>1.20 se puede crear otra regla desde cero en el botón (CREAR NUEVA REGLA) o partir desde una regla creada dándole clic aun regla en la lista</b></p>
        <img alt="" src={require('../../img/r23.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <p className="text-left">(SI VA A CREAR UNA REGLA APARTIR DE UNA REGLA CREADA SE CREARA OTRA VERSION Y ESTA QUEDARA INTACTA)</p>
        <p className="text-left">1.21 si le damos clic al ojo encima de una regla podemos ver el algoritmo de la regla. Este algoritmo es para copiar una regla e importarla en otro ambiente u otro formulario con el mismo comportamiento</p>
        <img alt="" src={require('../../img/r24.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <img alt="" src={require('../../img/r25.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <img alt="" src={require('../../img/r26.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/>
        <img alt="" src={require('../../img/r27.jpg')} className="img-fluid"/><br/><br/><br/><br/><br/> */}
      </div>
    );
  }

  rulesInit() {
    return (
      <div>
        <h1>Reglas de Carga</h1>
        <br />
        <br />
        <p className="text-left">
          <b>¿Qué es?</b>
        </p>
        <p className="text-left">
          Este módulo puede llamar reglas en la cargar de un formulario en el portal. Los unicos
          campos que se puede cargar son las listas, los radio y los checkbox. La funcion que se
          realizar en este modulo es similar a un click sobre el campo que acciona la regla{" "}
        </p>
        <br />
        <p className="text-left">
          <b>¿Cómo se hace?</b>
        </p>
        <p className="text-left">
          En el módulo de <b>Reglas de Formularios</b> se muestra una tabla con todos los
          formularios disponible para el portal.
        </p>
        <p className="text-left">
          <b>
            se necesita saber el funcionamiento de las reglas y los tipos de reglas. Para saber esta
            información por favor ir a documentación (TIPOS DE REGLAS DE FORMULARIOS) Y (REGLAS DE
            FORMULARIO) en el menú
          </b>
        </p>
        <p className="text-left">
          <b>1.1 ir a Reglas/Reglas de Formularios</b>
        </p>
        <img alt="" src={require("../../img/r1.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/r2.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.2 se busca el formulario y le damos al botón VER REGLAS</b>
        </p>
        <img alt="" src={require("../../img/r3.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/r4.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.3 Al darle clic al botón (VER REGLAS), se mostraran cuatros botones entre ellos se
            encuentra el botón (REGLAS DE CARGA).
          </b>
        </p>
        <img alt="" src={require("../../img/rc1.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.4 se muestra una lista de reglas de carga, sino tiene solo aparecera el boton de crear
          </b>
        </p>
        <img alt="" src={require("../../img/rc2.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.5 si le damos clic a un checkbox encima de la regla, asignara por default esa regla en
            la carga, sino no esta seleccionada ninguna regla significa que ese formulario no tendra
            reglas de carga
          </b>
        </p>
        <img alt="" src={require("../../img/rc3.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.6 para verificar que se asigno la regla, podemos ir a (Envio de formulario) y enviar
            un formulario si el icono de carga vuelve verde el formulario se enviara con
            configuracion de carga. Sino sabe como Enviar un formulario puede ir a la documentacion
            de (ENVIO DE FORMULARIO) en el menu.{" "}
          </b>
        </p>
        <img alt="" src={require("../../img/rc4.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.7 para crear una nueva regla de carga, le damos a crear</b>
        </p>
        <img alt="" src={require("../../img/rc5.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.8 se mostraran todos los campos que se puede inicializar a la carga de un formulario
          </b>
        </p>
        <img alt="" src={require("../../img/rc6.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.9 selecionamo el campo que queremos selecionar al cargar (NOTA: SI EL CAMPO TIENE
            REGLAS DE ACCION HA LA HORA DE CARGAR ESE CAMPO SE ACTIVARAN LAS REGLAS DE FORMULARIO DE
            ESE CAMPO)
          </b>
        </p>
        <img alt="" src={require("../../img/rc7.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.10 seleccionamos el chebox y le damos a guardar</b>
        </p>
        <img alt="" src={require("../../img/rc8.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.11 se creara una version nueva de reglas de carga, solo queda seleccionarla para
            enviarla
          </b>
        </p>
        <img alt="" src={require("../../img/rc9.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/rc10.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.12 si queremos actualizar una regla solo tenemos que entrar a la regla de cargar y
            cambiar la configuracion
          </b>
        </p>
        <img alt="" src={require("../../img/rc11.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }

  rulesPrice() {
    return (
      <div>
        <h1>Reglas de Precios</h1>
        <br />
        <br />
        <p className="text-left">
          <b>¿Qué es?</b>
        </p>
        <p className="text-left">
          Este módulo esta creado para llamar reglas de carga dependiendo el precio seleccionado en
          el portal. Esta funcion es utilizada para cambiar el comportamientos de formularios a hora
          de asignarle precios{" "}
        </p>
        <br />
        <p className="text-left">
          <b>¿Cómo se hace?</b>
        </p>
        <p className="text-left">
          En el módulo de <b>Reglas de Precios</b> se muestra una tabla con todos los formularios
          disponible para el portal.
        </p>
        <p className="text-left">
          <b>
            se necesita saber el funcionamiento de las reglas, los tipos de reglas y reglas de
            carga. Para saber esta información por favor ir a documentación (TIPOS DE REGLAS DE
            FORMULARIOS) , (REGLAS DE FORMULARIO) y (REGLAS DE CARGA) en el menú
          </b>
        </p>
        <p className="text-left">
          <b>
            se necesitan los precios creados en el portal administrativo para usar este modulo. Sino
            tiene los precios del servicio creado por favor contacte a su supervisor
          </b>
        </p>
        <p className="text-left">
          <b>1.1 ir a Reglas/Reglas de Precios</b>
        </p>
        <img alt="" src={require("../../img/p1.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img alt="" src={require("../../img/p2.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>1.2 se busca el formulario y le damos al botón VER PRECIOS</b>
        </p>
        <img alt="" src={require("../../img/p3.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />

        <p className="text-left">
          <b>1.3 si el formulario tiene precios asignados, se mostrara una lista de precios</b>
        </p>
        <img alt="" src={require("../../img/p4.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />

        <p className="text-left">
          <b>
            1.4 al darle click a un precio, se mostrara una lista de variaciones de precios con una
            lista de reglas encima
          </b>
        </p>
        <img alt="" src={require("../../img/p5.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.5 si no tiene regla de carga por favor crearlas en la seccion de (REGLAS DE CARGA).
            Sino sabe como crearlas puede ir a la documentacion en (REGLAS DE CARGA).
          </b>
        </p>
        <img alt="" src={require("../../img/p5.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.6 al seleccionar una regla de carga automaticamente se asignara al precio de servico
          </b>
        </p>
        <img alt="" src={require("../../img/p6.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.7 para verificar que el formulario se enviara con reglas de carga, entre a (ENVIO DE
            FORMULARIO) busque el formulario y si el icono se pone verde, el formulario se enviara
            con las reglas de precio. Sino sabe enviar un formulario entre a la documentacion de
            (ENVIO DE FORMULARIO)
          </b>
        </p>
        <img alt="" src={require("../../img/p7.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p className="text-left">
          <b>
            1.8 si queremos quitar las reglas en un precio, solo tenemos que entrar a la variacion y
            enviarlo con la opcion (enviar sin reglas de carga)
          </b>
        </p>
        <img alt="" src={require("../../img/p8.jpg")} className="img-fluid" />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }

  configPortal() {
    return (
      <div>
        <h1>Configuración Previa al Envío de Formularios al Portal</h1>
        <br />
        <br />
        <p className="text-left">
          <b>
            Esta documentacion se realizar antes de enviar un formulario a la aplicación de reglas.
            Puede descargar el manual en PDF o seguir en esta sección de la aplicación.
          </b>
        </p>

        <iframe
          title="1"
          src="https://drive.google.com/file/d/1A-CyuVUtcRqBOTHqFGt1AM4nWtw8Ufau/preview"
          width="940"
          height="480"
        ></iframe>
      </div>
    );
  }

  masks() {
    return (
      <div>
        <h1>Asignación de Máscaras</h1>
        <br />
        <br />
        <p className="text-left">
          <b>¿Qué es?</b>
        </p>
        <p className="text-left">
          <b>
            Este módulo nos permite asignar máscaras a los formularios que provienen desde
            Softexpert y los formularios creados desde el Backend del portal de servcios en línea
          </b>
          .
        </p>
        <br />
        <p className="text-left">
          <b>¿Cómo se hace?</b>
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp;Dentro del módulo de máscaras, nos encontramos con la siguiente pantalla:
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask1.JPG")} className="img-fluid" />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp;En el buscador(Search) debemos colocar el nombre del servicio o formulario
            al cual queremos asignarle máscaras a sus campos:
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask2.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp;Luego de haber encontrado el formulario en especifico , hacemos clic en{" "}
            <b>Asignar Máscaras </b>y se nos mostraran los campos disponibles de dicho formulario
            para aplicarle máscaras:{" "}
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask3.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp;Escogemos una máscara de la lista, en este caso <b>RNC</b>:{" "}
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask4.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp;Y se lo asignamos al campo deseado, al hacer clic notaremos que se el campo
            se sombrea de color verde y el recuadro pequeño indica que máscara se asigno en ese
            campo seleccionado:{" "}
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask5.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp;Si el formulario que proviene de SoftExpert no esta utilizando grids , el
            boton <b>Grids</b> estara deshabilitado de lo contrario este se habilitará:{" "}
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask6.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp;Al hacer clic en el botón <b>Grids </b>nos aparecera el siguiente recuadro:
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask7.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp; Su funcionamiento es el mismo que el de la pantalla anterior, se escoge la
            mascara y se hace clic en el campo deseado. Luego de que las mascaras se asignan es
            requerido hacer clic en el boton <b>Guardar Asignación</b>:{" "}
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask8.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp; Y nos saldra este mensaje indicando que la configuracion se ha hecho
            correctamente:{" "}
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask9.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp; Luego de esto procedemos a configurar la longitud de los campos, esto nos
            permitira limitar la cantidad de caracteres o digitos que pueden tener los campos
            especificamente, debemos hacer clic en el boton con el icono con tuerca &nbsp;
            <MDBIcon
              icon="cog"
              size="1x"
              style={{
                color:
                  config.env === "production"
                    ? "#00695c"
                    : config.env === "development"
                    ? "#0d47a1"
                    : "#424242",
              }}
              fixed
            />
            :
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask15.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp; Podemos buscar el nombre del campo en especifico que queremos agregar
            longitud en carácteres y/o dígitos
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask13.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>&nbsp;&nbsp; Luego de asignar , procedemos a guardar los cambios</b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask14.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp; Y después de asignar máscaras y longitudes a los campos correspondientes ,
            procedemos a guardar nuestros cambios haciendo clic en Guardar Configuración, nos saldra
            este mensaje para confirmar los cambios y le damos a guardar
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask16.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
        <p className="text-left">
          <b>
            &nbsp;&nbsp; Y Finalmente nos indicara que se ha guardado una nueva version de mascaras
            y longitud para el formulario editado
          </b>
          <br />
          <br />
          <img alt="" src={require("../../img/mask12.JPG")} className="img-fluid" />
          <br />
          <br />
          <br />
        </p>
      </div>
    );
  }
}

export default Body;
