export default {
  0: { mask: "0", label: "0 - CÉDULA" },
  1: { mask: "1", label: "1 - RNC" },
  2: { mask: "2", label: "2 - TELÉFONO" },
  3: { mask: "3", label: "3 - CELULAR" },
  5: { mask: "5", label: "5 - EMAIL" },
  6: { mask: "6", label: "6 - SOLO NUMERO" },
  7: { mask: "7", label: "7 - SOLO LETRAS" },
  8: { mask: "8", label: "8 - INMUEBLE" },
  9: { mask: "9", label: "9 - RANGO DE FECHA" },
  10: { mask: "10", label: "10 - FECHA" },
  11: { mask: "11", label: "11 - HORA" },
  12: { mask: "12", label: "12 - DECIMAL" },
  13: { mask: "13", label: "13 - AÑO" },
  14: { mask: "14", label: "14 - PASAPORTE NO EXISTE EN SIGA" },
  15: { mask: "15", label: "15 - COMPAÑÍA EXISTE EN SIGA" },
  16: { mask: "16", label: "16 - PASAPORTE EXISTE EN SIGA" },
  17: { mask: "17", label: "17 - COMPAÑÍA NO EXISTE EN SIGA" },
  18: { mask: "18", label: "18 - COMPAÑÍA EXISTE EN SIGA CON RNC" },
  19: { mask: "19", label: "19 - COMPAÑÍA NO EXISTE EN SIGA CON RNC" },
  20: { mask: "20", label: "20 - RNC FECHA DE CONSTITUCIÓN MAYOR A 3" },
  21: { mask: "21", label: "21 - PAGO YA EXISTE" },
};
