import config from "../../config";
import axios from "axios";

function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token-rules")}`;
  return axios;
}

function saveRules(body) {
  return axios_api(config.api + "/rule")
    .post(config.api + "/rule", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetRulesById(id) {
  return axios_api(config.api + "/rule/" + id)
    .get(config.api + "/rule/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetRulesByForm(id) {
  return axios_api(config.api + "/ruleByForm/" + id)
    .get(config.api + "/ruleByForm/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function deleteRulesByForm(id) {
  return axios_api(config.api + "/rule/" + id)
    .delete(config.api + "/rule/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function deleteRulesById(id) {
  return axios_api(config.api + "/rule/one/" + id)
    .delete(config.api + "/rule/one/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function getLastVersionRule(id) {
  return axios_api(config.api + "/lastversion/" + id)
    .get(config.api + "/lastversion/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

export {
  getLastVersionRule,
  GetRulesByForm,
  deleteRulesByForm,
  saveRules,
  GetRulesById,
  deleteRulesById,
};
