import React, { Component } from "react";

export default class TMaskDate extends Component {
  componentWillReceiveProps(props) {}

  render() {
    const field = this.props.field;
    const i = this.props.id;
    return (
      <div className="form-group">
        <label htmlFor="formGroupExampleInput">
          <b>{field.label}</b>
        </label>
        <input
          type="date"
          className="form-control"
          id={this.props.id}
          key={i}
          name={this.props.name}
          disabled="disabled"
        />
      </div>
    );
  }
}

//            id={field.name}
