import React, { Component } from "react";
import { MDBBox, MDBBtn, MDBBtnGroup, MDBContainer, MDBIcon } from "mdbreact";
import { getDocuments } from "../../data/collections/softexpert";
import config, { apiSoftexpert } from "../../config";
import { generateDocument } from "./qrGenerator";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  isLoading: false,
  documents: [],
  base64: "",
  buffer: "",
  blobUrl: "",
  options: [],
  serviceID: "",
  documentID: "",
  position: "top-right",
  qrSize: 100,
  sourcePdf: "",
};

class Certifications extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.searchRef = React.createRef();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async searchDocument() {
    const value = this.searchRef.current.value;

    if (!value) {
      toast.error("Debe ingresar un número de solicitud", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    try {
      this.setState({ isLoading: true });
      const response = await getDocuments(value);
      let documents = response.data.data;

      if (!documents) {
        this.setState(initialState);
        toast.error("No se han encontrado documentos", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const docsOpt = documents.map((doc) => ({
        label: `${doc.NMTITLE} - (${doc.IDDOCUMENT})`,
        value: doc.IDDOCUMENT,
      }));

      this.setState({
        options: docsOpt,
        documents: documents,
        isLoading: false,
        documentID: docsOpt[0].value,
        serviceID: value,
      });
    } catch (e) {
      toast.error("Ha ocurrido un error: " + e.toString(), {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState(initialState);
      console.error(e);
    }
  }

  async addQrToDocument() {
    try {
      this.setState({
        isLoading: true,
      });

      if (!this.state.documentID) {
        toast.error("Debe seleccionar una opción", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const sourcePdfUrl = `${apiSoftexpert}/documents/${this.state.documentID}/download`;

      let sourcePdf = await fetch(sourcePdfUrl).then((res) => {
        return res.json();
      });

      this.setState({
        sourcePdf: sourcePdf,
      });

      const qrBase64 = await generateDocument({
        position: this.state.position,
        documentId: this.state.documentID,
        dataForQR: `${process.env.REACT_APP_QR_URL}/undefined`, // TEMPORAL RUL BEFORE GENERATING THE CODE
        sourcePdf: sourcePdf,
        imageSize: parseInt(this.state.qrSize),
      });

      if (qrBase64.success) {
        const blob = new Blob([qrBase64.buffer], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        this.setState({
          base64: qrBase64.base64,
          buffer: qrBase64.buffer,
          blobUrl: url,
          isLoading: false,
        });
      } else {
        toast.error(qrBase64.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ isLoading: false });
      }
    } catch (e) {
      toast.error("Ha ocurrido un error: " + e.toString(), {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState(initialState);
      console.error(e);
    }
  }

  async sendDocument() {
    try {
      this.setState({ isLoading: true });
      const doc = this.state.documents.find((doc) => doc.IDDOCUMENT === this.state.documentID);

      const responseToken = await axios.post(`${apiSoftexpert}/request/documents/qr`, {
        request_id: this.searchRef.current.value,
        document_id: this.state.documentID,
      });

      if (!responseToken.data.success) {
        toast.error(responseToken.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState(initialState);
        return;
      }

      const qrBase64 = await generateDocument({
        position: this.state.position,
        documentId: this.state.documentID,
        dataForQR: `${process.env.REACT_APP_QR_URL}/${responseToken.data.data}`,
        sourcePdf: this.state.sourcePdf,
        imageSize: parseInt(this.state.qrSize),
      });

      if (!qrBase64.success) {
        toast.error(qrBase64.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const responseSE = await axios.post(
        `${apiSoftexpert}/documents/${this.state.documentID}/upload`,
        {
          iddocument: this.state.documentID,
          file: qrBase64.base64,
          title: doc.NMTITLE + "_QR",
        }
      );

      if (responseSE.data.success) {
        toast.success(responseSE.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState(initialState);
      } else {
        toast.error(responseSE.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState(initialState);
      }
    } catch (e) {
      toast.error("Error al enviar archivo: " + e.toString(), {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({ isLoading: false });
      console.error(e);
    }
  }

  setPosition(value) {
    this.setState(
      {
        position: value,
      },
      this.addQrToDocument
    );
  }

  render() {
    return (
      <>
        <ToastContainer />

        <h1>Certifications</h1>
        <MDBContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <input ref={this.searchRef} className={"form-control"} name={"serviceID"} />

            <button
              className={"btn btn-blue btn-md font-weight-bold"}
              onClick={() => this.searchDocument()}
            >
              Buscar
            </button>
          </div>

          {this.state.isLoading ? (
            <MDBIcon
              icon="cog"
              spin
              size="4x"
              style={{
                color:
                  config.env === "production"
                    ? "#00695c"
                    : config.env === "development"
                    ? "#0d47a1"
                    : "#424242",
              }}
              fixed
            />
          ) : (
            <>
              <MDBBox mt={"4"}>
                {this.state.options.length > 0 && (
                  <>
                    <MDBBox>
                      <select
                        name="documentID"
                        value={this.state.documentID}
                        onChange={(e) => this.handleChange(e)}
                      >
                        {this.state.options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>

                      <label htmlFor={"qrSize"} style={{ marginTop: "10px" }}>
                        Tamaño
                      </label>

                      <input
                        type={"number"}
                        id={"qrSize"}
                        name={"qrSize"}
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.qrSize}
                      />

                      <MDBBtn onClick={() => this.addQrToDocument()}>Agregar codigo QR</MDBBtn>

                      <MDBBtn
                        disabled={!this.state.documentID || !this.state.sourcePdf}
                        onClick={() => this.sendDocument()}
                      >
                        Enviar a SOFTEXPERT
                      </MDBBtn>
                    </MDBBox>
                  </>
                )}
              </MDBBox>
            </>
          )}

          {this.state.base64 !== "" && (
            <>
              <MDBBox mt={8}>
                <MDBBtnGroup aria-label="Basic example" size={"sm"} vertical>
                  {/* Top */}
                  <MDBBtnGroup aria-label="Basic example" size={"sm"}>
                    {/* Top - Left*/}
                    <button
                      className={"btn btn-blue"}
                      color={"light"}
                      onClick={() => this.setPosition("top-left")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-radius-top-left"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M5 19v-6a8 8 0 0 1 8 -8h6"></path>
                      </svg>
                    </button>

                    {/* Top - Center */}
                    <button
                      className={"btn btn-blue"}
                      color={"light"}
                      onClick={() => this.setPosition("top-center")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-minus"
                        width="30"
                        height="30"
                        viewBox="0 7 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                    </button>

                    {/* Top - Right */}
                    <button
                      className={"btn btn-blue"}
                      color={"light"}
                      onClick={() => this.setPosition("top-right")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-radius-top-right"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M5 5h6a8 8 0 0 1 8 8v6"></path>
                      </svg>
                    </button>
                  </MDBBtnGroup>

                  {/* Middle */}
                  <MDBBtnGroup aria-label="Basic example" size={"sm"}>
                    {/* Middle - Left */}
                    <button
                      className={"btn btn-blue"}
                      color={"light"}
                      onClick={() => this.setPosition("middle-left")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-minus-vertical"
                        width="30"
                        height="30"
                        viewBox="6 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 5v14"></path>
                      </svg>
                    </button>

                    {/* Middle - Center */}
                    <button
                      className={"btn btn-blue"}
                      color={"light"}
                      onClick={() => this.setPosition("middle-center")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-square"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                      </svg>
                    </button>

                    {/* Middle - Right */}
                    <button
                      className={"btn btn-blue"}
                      color={"light"}
                      onClick={() => this.setPosition("middle-right")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-minus-vertical"
                        width="30"
                        height="30"
                        viewBox="-6 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 5v14"></path>
                      </svg>
                    </button>
                  </MDBBtnGroup>

                  {/* Bottom */}
                  <MDBBtnGroup aria-label="Basic example" size={"sm"}>
                    {/* Bottom - Left */}
                    <button
                      className={"btn btn-blue"}
                      color={"light"}
                      onClick={() => this.setPosition("bottom-left")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-radius-bottom-left"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M19 19h-6a8 8 0 0 1 -8 -8v-6"></path>
                      </svg>
                    </button>

                    {/* Bottom - Center */}
                    <button
                      className={"btn btn-blue"}
                      color={"light"}
                      onClick={() => this.setPosition("bottom-center")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-minus"
                        width="30"
                        height="30"
                        viewBox="0 -7 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                    </button>

                    {/* Bottom - Right */}
                    <button
                      className={"btn btn-blue"}
                      color={"light"}
                      onClick={() => this.setPosition("bottom-right")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-radius-bottom-right"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M19 5v6a8 8 0 0 1 -8 8h-6"></path>
                      </svg>
                    </button>
                  </MDBBtnGroup>
                </MDBBtnGroup>

                <object
                  data={this.state.blobUrl}
                  type="application/pdf"
                  width={"100%"}
                  height={"1300px"}
                >
                  <div>No online PDF viewer installed</div>
                </object>
              </MDBBox>
            </>
          )}
        </MDBContainer>
      </>
    );
  }
}

export default Certifications;
