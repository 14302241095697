import config from "../../config";
import axios from "axios";

function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token-rules")}`;
  return axios;
}

function saveMask(body) {
  return axios_api(config.api + "/saveMask")
    .post(config.api + "/saveMask", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetLatestMaskVersion(idForm) {
  return axios_api(config.api + "/GetLatestMaskVersion/" + idForm)
    .get(config.api + "/GetLatestMaskVersion/" + idForm)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetMaskVersionById(id) {
  return axios_api(config.api + "/GetMaskVersionById/" + id)
    .get(config.api + "/GetMaskVersionById/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetAllMaskVersionsPerForm(idForm) {
  return axios_api(config.api + "/GetAllMaskVersionsPerForm/" + idForm)
    .get(config.api + "/GetAllMaskVersionsPerForm/" + idForm)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function GetOneMaskVersionsPerForm(idForm) {
  return await axios_api(config.api + "/GetMaskVersionByIdForm/" + idForm)
    .get(config.api + "/GetMaskVersionByIdForm/" + idForm)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function deleteMaskByForm(id) {
  return axios_api(config.api + "/mask/" + id)
    .delete(config.api + "/mask/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function deleteMaskById(id) {
  return axios_api(config.api + "/mask/id/" + id)
    .delete(config.api + "/mask/id/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

export {
  deleteMaskByForm,
  GetAllMaskVersionsPerForm,
  GetMaskVersionById,
  GetLatestMaskVersion,
  GetOneMaskVersionsPerForm,
  saveMask,
  deleteMaskById,
};
