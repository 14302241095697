import React, { Component } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import {
  FCheckbox,
  FDate,
  FFile,
  FHeader,
  FRadio,
  FSelect,
  FText,
  Ftextarea,
  TGrid,
} from "../form/index";

class FieldsLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: true,
      class: "padding ",
      rule: this.props.rule,
      Resumen: [],
    };
  }

  render() {
    const FromField = this.props.field[0];
    return (
      <MDBRow>
        {FromField.map((field, i) =>
          field.type === "radio-group" && !this.props.onlyText ? (
            <MDBCol size="6" className="cursor" key={"col-radio-" + i}>
              <FRadio
                key={"radio" + i}
                id={i + field.label}
                handlerFromInput={this.props.FromInput}
                fieldFromInput={this.props.FieldFromInput}
                field={field}
              />
            </MDBCol>
          ) : field.type === "select" && !field.data_portal ? (
            <MDBCol size="6" className="cursor" key={"col-select-" + i}>
              {this.props.onlyText ? (
                // onlyText = true
                <FSelect
                  key={"select" + i}
                  id={i + field.label}
                  handlerFromInput={this.props.FromInput}
                  fieldFromInput={this.props.FieldFromInput}
                  field={field}
                  fieldSave={this.props.fieldSave}
                  textForMask={this.props.onlyText}
                />
              ) : (
                // onlyText = false
                <FSelect
                  key={"select" + i}
                  id={i + field.label}
                  handlerFromInput={this.props.FromInput}
                  fieldFromInput={this.props.FieldFromInput}
                  field={field}
                />
              )}
            </MDBCol>
          ) : field.type === "text" ? (
            <MDBCol size="6" className="cursor" key={"col-text-" + i}>
              <FText
                key={"text" + i}
                id={i + field.label}
                fieldSave={this.props.fieldSave}
                textForMask={this.props.onlyText}
                handlerFromInput={this.props.FromInput}
                fieldFromInput={this.props.FieldFromInput}
                field={field}
              />
            </MDBCol>
          ) : field.type === "header" ? (
            <MDBCol size="12" className="cursor" key={"col-header" + i}>
              <hr />
              <FHeader key={"header" + i} id={i + field.label} field={field} />
            </MDBCol>
          ) : field.type === "date" ? (
            <MDBCol size="6" className="cursor" key={"col-date-" + i}>
              {this.props.onlyText ? (
                <FText
                  key={"date-" + i}
                  id={i + field.label}
                  fieldSave={this.props.fieldSave}
                  textForMask={this.props.onlyText}
                  handlerFromInput={this.props.FromInput}
                  fieldFromInput={this.props.FieldFromInput}
                  field={field}
                />
              ) : (
                <FDate
                  key={i}
                  id={i + field.label}
                  handlerFromInput={this.props.FromInput}
                  fieldFromInput={this.props.FieldFromInput}
                  field={field}
                />
              )}
            </MDBCol>
          ) : field.type === "time" ? (
            <MDBCol size="6" className="cursor" key={"col-time-" + i}>
              {this.props.onlyText ? (
                <FText
                  key={"time" + i}
                  id={i + field.label}
                  fieldSave={this.props.fieldSave}
                  textForMask={this.props.onlyText}
                  handlerFromInput={this.props.FromInput}
                  fieldFromInput={this.props.FieldFromInput}
                  field={field}
                />
              ) : (
                <FDate
                  key={"text-time" + i}
                  id={i + field.label}
                  handlerFromInput={this.props.FromInput}
                  fieldFromInput={this.props.FieldFromInput}
                  field={field}
                />
              )}
            </MDBCol>
          ) : field.type === "file" && !this.props.onlyText ? (
            <MDBCol size="6" className="cursor" key={"col-file-" + i}>
              <FFile
                key={"file-" + i}
                id={i + field.label}
                handlerFromInput={this.props.FromInput}
                fieldFromInput={this.props.FieldFromInput}
                field={field}
              />
            </MDBCol>
          ) : field.type === "textarea" ? (
            <MDBCol size="6" className="cursor" key={"col-textarea-" + i}>
              <Ftextarea
                key={"textarea-" + i}
                id={i + field.label}
                textForMask={this.props.onlyText}
                handlerFromInput={this.props.FromInput}
                fieldFromInput={this.props.FieldFromInput}
                field={field}
              />
            </MDBCol>
          ) : field.type === "grid" ? (
            <MDBCol size="12" className="cursor" key={"col-grid-" + i}>
              <TGrid
                key={"grid-" + i}
                selectableKey={field.name}
                onlyText={this.props.onlyText}
                fieldSave={this.props.fieldSave}
                id={field.name}
                ToInput={this.props.FromInput}
                fieldToInput={this.props.FieldFromInput}
                field={field}
                arrayGrid={this.props.arrayGrid}
              />
            </MDBCol>
          ) : (
            field.type === "checkbox-group" &&
            !this.props.onlyText && (
              <MDBCol size="6" className="cursor" key={"col-checkbox-group-" + i}>
                <FCheckbox
                  key={"checkbox-group-" + i}
                  id={i + field.label}
                  handlerFromInput={this.props.FromInput}
                  fieldFromInput={this.props.FieldFromInput}
                  field={field}
                />
              </MDBCol>
            )
          )
        )}
      </MDBRow>
    );
  }
}

export default FieldsLeft;
