import React, { Component } from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import {
  FText,
  TDate,
  TFile,
  THeaders,
  TRadio,
  TSelect,
  TSubTitle,
  TText,
  TTextarea,
  TTime,
} from "../index";

import Fields from "../../formConfigurations/fields";

export default class TGridRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.setModal = this.setModal.bind(this);
  }

  setModal(modal) {
    this.setState({
      modal: modal,
    });
  }

  toggle = () => {
    if (!this.state.modal) {
      this.props.config && this.props.arrayGrid([]);
      !this.props.config &&
        this.props.ToInput(false, this.props.field, true, this.props.field.name);
      this.setState({
        modal: true,
      });
    }

    if (this.state.modal) {
      this.props.config && this.props.arrayGrid([]);
      this.setState({
        modal: false,
      });
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    console.log("nextProps", nextProps);
  }

  fieldsGrid(field) {
    field.name = this.props.field.name + "_" + field.name;
    return field;
  }

  render() {
    const fieldProp = this.props.field;
    const childFields = this.props.childFields;

    const i = this.props.id;

    if (this.props.config) {
      return (
        <MDBContainer>
          <MDBBtn onClick={this.toggle}>{"GRID: " + fieldProp.label}</MDBBtn>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="fluid">
            <MDBModalHeader>{fieldProp.label}</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBTable key={i} bordered>
                  <MDBTableHead>
                    <tr>
                      <th>Campo</th>
                      <th>Requerido</th>
                      <th>No Requerido</th>
                      <th>Oculto</th>
                      <th>Visualziar</th>
                      <th>Deshabilitado</th>
                      <th>Habilitar</th>
                      <th>Click</th>
                      <th>Limpiar</th>
                      <th>Cambiar a Cedula</th>
                      <th>Cambiar a Pasaporte</th>
                      <th>Cambiar a RNC</th>
                      <th>API - Portal</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {fieldProp.fields.map((field, i) => (
                      <tr key={"col-row-" + i}>
                        <td>
                          <Fields config={true} field={field} />
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "required"}
                              disabled={
                                childFields[field.name]
                                  ? !!(
                                      childFields[field.name].notRequired ||
                                      childFields[field.name].hidden ||
                                      childFields[field.name].disabled
                                    )
                                  : false
                              }
                              checked={childFields[field.name] && childFields[field.name].required}
                              hidden={field.type === "header" || field.type === "subtitle"}
                              onClick={(e) =>
                                this.props.create(e, field.name, field.type, "required")
                              }
                            />{" "}
                            <br />
                            <label hidden={field.type === "header" || field.type === "subtitle"}>
                              REQUERIDO
                            </label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "required"}
                              disabled={
                                childFields[field.name] ? !!childFields[field.name].required : false
                              }
                              checked={
                                childFields[field.name] && childFields[field.name].notRequired
                              }
                              hidden={field.type === "header" || field.type === "subtitle"}
                              onClick={(e) =>
                                this.props.create(e, field.name, field.type, "notRequired")
                              }
                            />{" "}
                            <br />
                            <label hidden={field.type === "header" || field.type === "subtitle"}>
                              NO REQUERIDO
                            </label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "hidden"}
                              disabled={
                                childFields[field.name]
                                  ? !!(
                                      childFields[field.name].required ||
                                      childFields[field.name].show ||
                                      childFields[field.name].active
                                    )
                                  : false
                              }
                              checked={childFields[field.name] && childFields[field.name].hidden}
                              onClick={(e) =>
                                this.props.create(e, field.name, field.type, "hidden")
                              }
                            />
                            <br />
                            <label>OCULTO</label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "disabled"}
                              disabled={
                                childFields[field.name] ? !!childFields[field.name].hidden : false
                              }
                              checked={childFields[field.name] && childFields[field.name].show}
                              onClick={(e) => this.props.create(e, field.name, field.type, "show")}
                            />
                            <br />
                            <label>VISUALIZAR</label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "disabled"}
                              disabled={
                                childFields[field.name]
                                  ? !!(
                                      childFields[field.name].active ||
                                      childFields[field.name].required
                                    )
                                  : false
                              }
                              checked={childFields[field.name] && childFields[field.name].disabled}
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                              onClick={(e) =>
                                this.props.create(e, field.name, field.type, "disabled")
                              }
                            />
                            <br />
                            <label
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                            >
                              DESHABILITADO
                            </label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "disabled"}
                              disabled={
                                childFields[field.name]
                                  ? !!(
                                      childFields[field.name].disabled ||
                                      childFields[field.name].hidden
                                    )
                                  : false
                              }
                              checked={childFields[field.name] && childFields[field.name].active}
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                              onClick={(e) =>
                                this.props.create(e, field.name, field.type, "active")
                              }
                            />
                            <br />
                            <label
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                            >
                              HABILITAR
                            </label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "click"}
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                              checked={childFields[field.name] && childFields[field.name].click}
                              onClick={(e) => this.props.create(e, field.name, field.type, "click")}
                            />
                            <br />
                            <label
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                            >
                              CLICK
                            </label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "clean"}
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                              checked={childFields[field.name] && childFields[field.name].clean}
                              onClick={(e) => this.props.create(e, field.name, field.type, "clean")}
                            />
                            <br />
                            <label
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                            >
                              LIMPIAR
                            </label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "disabled"}
                              disabled={
                                childFields[field.name]
                                  ? childFields[field.name].rnc || childFields[field.name].pasaporte
                                  : false
                              }
                              checked={fieldProp[field.name] && fieldProp[field.name].cedula}
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                              onClick={(e) =>
                                this.props.create(e, field.name, field.type, "cedula")
                              }
                            />
                            <br />
                            <label
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                            >
                              CAMBIAR A CEDULA
                            </label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "disabled"}
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                              disabled={
                                childFields[field.name]
                                  ? childFields[field.name].cedula ||
                                    childFields[field.name].pasaporte
                                  : false
                              }
                              checked={childFields[field.name] && childFields[field.name].rnc}
                              onClick={(e) => this.props.create(e, field.name, field.type, "rnc")}
                            />
                            <br />
                            <label
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                            >
                              CAMBIAR A RNC
                            </label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "disabled"}
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                              disabled={
                                childFields[field.name]
                                  ? childFields[field.name].rnc || childFields[field.name].cedula
                                  : false
                              }
                              checked={fieldProp[field.name] && fieldProp[field.name].pasaporte}
                              onClick={(e) =>
                                this.props.create(e, field.name, field.type, "pasaporte")
                              }
                            />
                            <br />
                            <label
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                            >
                              CAMBIAR A PASAPORTE
                            </label>
                          </center>
                        </td>

                        <td>
                          <center className="form-group">
                            <input
                              type="checkbox"
                              id={field.name + "disabled"}
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                              checked={childFields[field.name] && childFields[field.name].api}
                              onClick={(e) => this.props.create(e, field.name, field.type, "api")}
                            />
                            <br />
                            <label
                              hidden={
                                field.type === "header" ||
                                field.type === "subtitle" ||
                                field.type === "grid"
                              }
                            >
                              API - PORTAL
                            </label>
                          </center>
                        </td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      );
    }

    let nameGrid = fieldProp.name;
    return (
      <div id={i} className="form-group">
        <MDBContainer>
          <MDBBtn onClick={this.toggle}>{"GRID: " + fieldProp.label}</MDBBtn>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
            <MDBModalHeader onClick={(e) => this.props.ToInput(e, fieldProp.name)}>
              {fieldProp.label}
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                {fieldProp.fields.map((field, i) =>
                  field.type === "text" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                    >
                      <TText
                        key={i}
                        id={i + field.label}
                        fieldToInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "text" && this.props.onlyText ? (
                    <MDBCol size="6" className="cursor">
                      <FText
                        key={i}
                        id={i + field.label}
                        setModal={this.setModal}
                        nameGrid={nameGrid}
                        grid={true}
                        fieldSave={this.props.fieldSave}
                        textForMask={this.props.onlyText}
                        handlerFromInput={this.props.ToInput}
                        fieldFromInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "radio-group" && !this.props.onlyText ? (
                    <MDBCol size="6" className="cursor">
                      <TRadio
                        key={i}
                        fieldToInput={this.props.FieldToInput}
                        handlerToInput={this.props.ToInput}
                        id={i + field.label}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "select" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                    >
                      <TSelect key={i} id={i + field.label} field={field} />
                    </MDBCol>
                  ) : field.type === "file" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                    >
                      <TFile key={i} id={i + field.label} field={field} />
                    </MDBCol>
                  ) : field.type === "header" && !this.props.onlyText ? (
                    <MDBCol
                      size="12"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                    >
                      <hr />
                      <THeaders
                        key={i}
                        id={i + field.label}
                        handlerToInput={this.props.ToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "textarea" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                    >
                      <TTextarea
                        key={i}
                        id={i + field.label}
                        fieldToInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "time" && this.props.onlyText ? (
                    <MDBCol size="6" className="cursor">
                      <FText
                        key={i}
                        id={i + field.label}
                        setModal={this.setModal}
                        nameGrid={nameGrid}
                        grid={true}
                        fieldSave={this.props.fieldSave}
                        textForMask={this.props.onlyText}
                        handlerFromInput={this.props.ToInput}
                        fieldFromInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "date" && this.props.onlyText ? (
                    <MDBCol size="6" className="cursor">
                      <FText
                        key={i}
                        id={i + field.label}
                        setModal={this.setModal}
                        nameGrid={nameGrid}
                        grid={true}
                        fieldSave={this.props.fieldSave}
                        textForMask={this.props.onlyText}
                        handlerFromInput={this.props.ToInput}
                        fieldFromInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "time" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                    >
                      <TTime
                        key={i}
                        id={i + field.label}
                        fieldToInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : field.type === "date" && !this.props.onlyText ? (
                    <MDBCol
                      size="6"
                      className="cursor"
                      onClick={(e) => this.props.ToInput(e, field.name)}
                    >
                      <TDate
                        key={i}
                        id={i + field.label}
                        fieldToInput={this.props.FieldToInput}
                        field={field}
                      />
                    </MDBCol>
                  ) : (
                    field.type === "subtitle" &&
                    !this.props.onlyText && (
                      <MDBCol
                        size="12"
                        className="cursor"
                        onClick={(e) => this.props.ToInput(e, field.name)}
                      >
                        <TSubTitle key={i} id={i + field.label} field={field} />
                      </MDBCol>
                    )
                  )
                )}
              </MDBRow>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  }
}
