import { Redirect, Route } from "react-router-dom";
import React from "react";

const auth = () => {
  const token = localStorage.getItem("token-rules");
  return !!token;
};

const PrivateRoute = (props) => (auth() ? <Route {...props} /> : <Redirect to="/login" />);

export default PrivateRoute;
