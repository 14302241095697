import React, { Component } from "react";
import { MDBBtn, MDBCard, MDBCol, MDBIcon, MDBRow } from "mdbreact";
import { RulesPrice } from "../../data/index";
import config from "../../config";
import { ToastContainer } from "react-toastify";
import Variations from "./allVariations";

const { getRulesPriceSelected } = RulesPrice;

export default class allPrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      modal: false,
      json: [],
      prices: [],
      ruleinits: [],
      idPrice: 0,
    };

    this.openModal = this.openModal.bind(this);
  }

  async componentDidMount() {
    console.log("this.props :", this.props.location);
    if (!this.props.location.prices) {
      return;
    }
    if (this.props.location.prices.length > 0) {
      let prices = this.props.location.prices;
      for (const key in prices) {
        this.DataSelected(prices[key].id);
      }

      this.setState({
        prices: this.props.location.prices,
        ruleinits: this.props.location.rulesInit,
      });
    }
  }

  openModal(id) {
    if (this.state.modal) {
      this.setState({
        modal: !this.state.modal,
      });
      return;
    }

    this.setState({
      modal: !this.state.modal,
      idPrice: id && id,
    });
  }

  async DataSelected(id) {
    const result = await getRulesPriceSelected(id, "price");
    if (result.data.rules) {
      this.setState({
        [id + "price"]: result.data.rules.idRuleinit,
      });
    }
  }

  render() {
    return (
      <div className="form-group">
        <ToastContainer />
        <h2>Precios</h2>
        <br />
        <MDBRow>
          {this.state.prices.length === 0 && (
            <MDBCol size="12">
              <h5> Este servicio no tiene precios asignados.</h5>
              <MDBBtn
                className="btn  btn-md text-white"
                color={config.color}
                onClick={() => this.props.history.push("/Price")}
              >
                volver
              </MDBBtn>
            </MDBCol>
          )}
          {this.state.prices.length > 0 &&
            this.state.prices.map((p, i) => (
              <MDBCol size="3">
                <br />
                <MDBCard
                  className={this.state[i + "shadow"] + " cursor"}
                  onMouseOut={() => this.setState({ [i + "shadow"]: "" })}
                  onMouseEnter={() => this.setState({ [i + "shadow"]: "z-depth-2" })}
                  style={{
                    height: "auto",
                    alignSelf: "left",
                    alignContent: "left",
                  }}
                  onClick={() => this.openModal(p.id)}
                >
                  <div
                    onMouseOut={() => this.setState({ [i + "shadow"]: "" })}
                    onMouseEnter={() => this.setState({ [i + "shadow"]: "z-depth-2" })}
                    style={{
                      alignSelf: "left",
                      alignContent: "left",
                      paddingLeft: 1,
                      paddingRight: 1,
                      color: "white",
                      backgroundColor:
                        config.env === "production"
                          ? "#00695c"
                          : config.env === "development"
                          ? "#0d47a1"
                          : "#424242",
                    }}
                  >
                    <label>{p.id}</label>
                  </div>
                  <br />
                  <br />
                  <MDBIcon
                    onMouseOut={() => this.setState({ [i + "shadow"]: "" })}
                    onMouseEnter={() => this.setState({ [i + "shadow"]: "z-depth-2" })}
                    icon="dollar-sign"
                    style={{
                      color:
                        config.env === "production"
                          ? "#00695c"
                          : config.env === "development"
                          ? "#0d47a1"
                          : "#424242",
                    }}
                    size="4x"
                  />
                  <br /> <br />
                  <div
                    onMouseOut={() => this.setState({ [i + "shadow"]: "" })}
                    onMouseEnter={() => this.setState({ [i + "shadow"]: "z-depth-2" })}
                    style={{
                      alignSelf: "left",
                      alignContent: "left",
                      paddingLeft: 1,
                      paddingRight: 1,
                      color: "white",
                      backgroundColor:
                        config.env === "production"
                          ? "#00695c"
                          : config.env === "development"
                          ? "#0d47a1"
                          : "#424242",
                    }}
                  >
                    <label
                      onMouseOut={() => this.setState({ [i + "shadow"]: "" })}
                      onMouseEnter={() => this.setState({ [i + "shadow"]: "z-depth-2" })}
                      style={{ alignSelf: "center" }}
                    >
                      {" "}
                      {p.concept.length > 44 ? p.concept.substr(0, 70) + "..." : p.concept}
                    </label>
                  </div>
                </MDBCard>
                <br />
                <br />
              </MDBCol>
            ))}
        </MDBRow>
        <Variations
          toggle={this.openModal}
          data={this.props.location}
          modal={this.state.modal}
          price={this.state.idPrice}
        />
      </div>
    );
  }
}
