import React, { Component } from "react";
import { MDBIcon } from "mdbreact";

export default class Ttextarea extends Component {
  componentWillReceiveProps(props) {}

  onCLickRule(e, name) {
    if (e.target.getAttribute("data-selected") === "false") {
      this.props.handlerFromInput(e, name);
      e.target.style = "color: #00695c";
      e.target.setAttribute("data-selected", "true");
      return;
    }

    if (e.target.getAttribute("data-selected") === "true") {
      this.props.handlerFromInput(e, "");
      e.target.style = "color: black";
      e.target.setAttribute("data-selected", "false");
      return;
    }
    console.log("e.target :", e.target.getAttribute("data-selected"));
  }

  render() {
    const field = this.props.field;
    const i = this.props.id;
    return (
      <div>
        {this.props.textForMask ? (
          <div className="form-group">
            <label htmlFor="formGroupExampleInput">{field.label}</label>
            <br />

            <textarea
              className="form-control"
              key={i}
              name={field.name}
              id={"F" + field.name}
              disabled="disabled"
            ></textarea>
          </div>
        ) : (
          <div className="form-group">
            <label htmlFor="formGroupExampleInput">{field.label}</label>
            <br />
            <MDBIcon
              icon="arrow-alt-circle-left"
              data-selected={false}
              id={"I|" + field.name}
              onClick={(e) =>
                this.props.handlerFromInput(
                  field.name,
                  field.type,
                  { label: "ENTRADA", value: "I" },
                  field.label,
                  e
                )
              }
            />{" "}
            <MDBIcon
              icon="arrow-alt-circle-right"
              id={"O|" + field.name}
              data-selected={false}
              onClick={(e) =>
                this.props.handlerFromInput(
                  field.name,
                  field.type,
                  { label: "SALIDA", value: "O" },
                  field.label,
                  e
                )
              }
            />
            <textarea
              className="form-control"
              key={i}
              name={field.name}
              id={"F" + field.name}
              disabled="disabled"
            ></textarea>
          </div>
        )}
      </div>
    );
  }
}
