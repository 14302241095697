// Require the package
import QRCode from "qrcode";
import { PDFDocument } from "pdf-lib";

function getBase64(string) {
  return QRCode.toDataURL(string)
    .then((url) => {
      return url;
    })
    .catch((err) => {
      console.error(err);
    });
}

function getPosition({ position, pageWidth, pageHeight, imageSize }) {
  switch (position) {
    case "top-left":
      return {
        x: 35,
        y: pageHeight - imageSize - 35,
        width: imageSize,
        height: imageSize,
      };
    case "top-center":
      return {
        x: pageWidth / 2 - imageSize / 2,
        y: pageHeight - imageSize - 35,
        width: imageSize,
        height: imageSize,
      };
    case "top-right":
      return {
        x: pageWidth - imageSize - 35,
        y: pageHeight - imageSize - 35,
        width: imageSize,
        height: imageSize,
      };
    case "middle-left":
      return {
        x: 35,
        y: pageHeight / 2 - imageSize / 2,
        width: imageSize,
        height: imageSize,
      };
    case "middle-center":
      return {
        x: pageWidth / 2 - imageSize / 2,
        y: pageHeight / 2 - imageSize / 2,
        width: imageSize,
        height: imageSize,
      };
    case "middle-right":
      return {
        x: pageWidth - imageSize - 35,
        y: pageHeight / 2 - imageSize / 2,
        width: imageSize,
        height: imageSize,
      };
    case "bottom-left":
      return {
        x: 35,
        y: 35,
        width: imageSize,
        height: imageSize,
      };
    case "bottom-center":
      return {
        x: pageWidth / 2 - imageSize / 2,
        y: 35,
        width: imageSize,
        height: imageSize,
      };
    case "bottom-right":
      return {
        x: pageWidth - imageSize - 35,
        y: 35,
        width: imageSize,
        height: imageSize,
      };
    default:
      return { x: 35, y: 35, width: imageSize, height: imageSize };
  }
}

const generateDocument = async ({ dataForQR, position, sourcePdf, imageSize }) => {
  try {
    const qrImage = await getBase64(dataForQR);

    // Create a new PDFDocument from the source PDF
    if (sourcePdf.files[0].type === "application/pdf") {
      sourcePdf = sourcePdf.files[0].encode;
      const pdfDoc = await PDFDocument.load(sourcePdf);
      const img = await pdfDoc.embedPng(qrImage);
      const imagePage = pdfDoc.getPages()[0];

      imagePage.drawImage(
        img,
        getPosition({
          position: position,
          pageWidth: imagePage.getWidth(),
          pageHeight: imagePage.getHeight(),
          imageSize: imageSize,
        })
      );

      return {
        success: true,
        message: "QR Code added successfully",
        base64: await pdfDoc.saveAsBase64(),
        buffer: await pdfDoc.save(),
      };
    } else {
      return {
        success: false,
        message: "Codigo QR no agreagado: el documento no esta formato pdf.",
        base64: null,
        buffer: null,
      };
    }
  } catch (e) {
    console.error(e);
    return {
      success: false,
      message: "Codigo QR no agreagado: Error al cargar el archivo",
      base64: null,
      buffer: null,
    };
  }
};

export { generateDocument, getBase64 };
