import React, { Component } from "react";
import { MDBCol, MDBIcon, MDBRow } from "mdbreact";
import CardText from "../Utilities/CardText";
import "../RenderField/RenderField.css";
import FieldsLeft from "../RenderField/FieldsLeft";
import FieldListModal from "./fieldListModal";
import config from "../../config";
import { Rules } from "../../data/index";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { saveRules, getLastVersionRule, GetRulesById } = Rules;

export default class rulesBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      modal: false,
      rulesList: [],
      msg: "flotante-label",
    };

    this.FromInput = this.FromInput.bind(this);
    this.toggle = this.toggle.bind(this);
    this.actionList = this.actionList.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  hiddenPage(id) {
    let element = document.getElementById(id);

    if (element.style.display === "none") {
      element.style = "display:block";
    } else {
      element.style = "display:none";
    }
  }

  FromInput(name, type, radio, label, e) {
    this.setState({
      field: {
        label: label,
        name: name,
        type: type,
        value: {
          label: radio.label,
          value: radio.value,
        },
        rules: this.searchRules(name, radio.value),
      },
      modal: true,
    });
  }

  searchRules(name, value) {
    let rules = this.state.rulesList;
    for (const i in rules) {
      if (rules[i].field === name && rules[i].value === value) {
        return {
          rulesField: rules[i].rules,
          groupActived: rules[i].groupActived,
        };
      }
    }
    return {};
  }

  actionList(groupActive, fields, field) {
    let rules = [];
    let rulesList = this.state.rulesList;

    console.log("actionList");

    for (const i in rulesList) {
      if (rulesList[i].field === field.name && rulesList[i].value === field.value.value) {
        rulesList[i].rules = fields;
        rulesList[i].groupActived = groupActive;
        this.setState({
          rulesList: rulesList,
          modal: false,
        });
        console.log("rulesList", rulesList);
        return;
      }
    }

    rules = {
      type: field.type,
      label: field.label,
      field: field.name,
      value: field.value.value,
      labelValue: field.value.label,
      rules: fields,
      groupActived: groupActive,
    };

    rulesList.push(rules);

    console.log("rulesList", rulesList);

    this.setState({
      rulesList: rulesList,
      modal: false,
    });
  }

  removeRule(i) {
    let rulesList = this.state.rulesList;
    delete rulesList[i];
    let filtered = rulesList.filter(function (el) {
      return el != null;
    });
    this.setState({
      rulesList: filtered,
    });
  }

  label(value) {
    this.setState({
      msg: value ? "flotante-label-hover" : "flotante-label",
    });
  }

  async saveRules() {
    let rulesList = this.state.rulesList;
    let rulesFull = [];
    if (rulesList.length === 0) {
      return toast.warn("No tienes acciones para guardar reglas", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    if (rulesList.length > 0) {
      for (const i in rulesList) {
        let field = rulesList[i].value + "|" + rulesList[i].field;
        let rules = this.formatRule(rulesList[i].rules);
        rulesFull.push(field + "=" + rules);
      }
    }

    let lastVersion = await getLastVersionRule(this.props.location.state.idForm);

    let version = lastVersion.data.err ? 0 : lastVersion.data.rules;
    let rules = {
      rule: rulesFull.join("&"),
      idForm: this.props.location.state.idForm,
      version: version + 1,
    };

    console.log("rules", rules);

    //return;

    const result = await saveRules(rules);

    try {
      if (result.data.rules.id && result.ok) {
        toast.success("Se Guardo Correctamente la regla con la version: " + rules.version, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        toast.error("Error al guardar la regla!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (e) {
      toast.success("Se Guardo Correctamente la regla con la version: " + rules.version, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }

  formatRule(rules) {
    let data = [];
    let field = [];
    for (const i in rules) {
      let dataRule = this.noRule(rules[i], i);
      //console.log("data", data);
      data.push(dataRule[0]);
      field.push(dataRule[1]);
    }

    return data.toString() + ":" + field.toString();
  }

  noRule(rules, field) {
    let data = [];
    let fields = [];
    if (rules.required) {
      data.push("4");
      fields.push(field);
    }

    if (rules.notRequired) {
      data.push("6");
      fields.push(field);
    }

    if (rules.show) {
      data.push("1");
      fields.push(field);
    }

    if (rules.hidden) {
      data.push("0");
      fields.push(field);
    }

    if (rules.active) {
      data.push("3");
      fields.push(field);
    }

    if (rules.disabled) {
      data.push("2");
      fields.push(field);
    }

    if (rules.clean) {
      data.push("7");
      fields.push(field);
    }

    if (rules.click) {
      data.push("5");
      fields.push(field);
    }
    if (rules.cedula) {
      data.push("8");
      fields.push(field);
    }

    if (rules.rnc) {
      data.push("9");
      fields.push(field);
    }

    if (rules.pasaporte) {
      data.push("10");
      fields.push(field);
    }

    if (rules.api) {
      data.push("12");
      fields.push(field);
    }

    return [data, fields];
  }

  async componentDidMount() {
    const result = await GetRulesById(this.props.location.state.idRule);

    console.log("result", result);

    if (result.data.rules.length === 0) {
      return;
    }

    let rules = result.data.rules[0].rule.split("&");

    let arrayRules = [];

    if (!Array.isArray(rules)) {
      rules = [rules];
    }

    for (const i in rules) {
      let resultRules = this.fieldFormat(rules[i]);
      if (resultRules !== false && resultRules.length > 0) {
        arrayRules.push(resultRules[0]);
      }
    }

    console.log("arrayRules", arrayRules);
    console.log("rules", result.data.rules);

    this.setState({
      dataOfRule: result.data.rules,
      rulesList: arrayRules,
    });
  }

  fieldFormat(rules) {
    let pages = this.props.location.state.data;

    let fieldFull = [];
    let rulesFull = rules.split("=");
    let nameField = rulesFull[0].split("|")[1];
    let valueField = rulesFull[0].split("|")[0];
    let ruleList = rulesFull[1];
    let dataFull = [];
    ruleList = this.noRuleFormat(ruleList);

    if (!ruleList) {
      return false;
    }

    for (const i in pages) {
      let data = pages[i][0];
      for (const key in data) {
        if (data[key].name === nameField) {
          if (
            data[key].type === "text" ||
            data[key].type === "file" ||
            data[key].type === "date" ||
            data[key].type === "time" ||
            data[key].type === "textarea"
          ) {
            fieldFull = {
              label: data[key].label,
              field: data[key].name,
              type: data[key].type,
              value: valueField,
              labelValue: valueField === "I" ? "ENTRADA" : "SALIDA",
              rules: ruleList,
              groupActived: [],
            };
          } else {
            let values = this.selectOption(data[key].values, valueField, data[key].type);

            // IF THERE IS NO VALUE APPLIED TO THE FIELD SKIP ITERATION
            if (!values) continue;

            fieldFull = {
              label: data[key].label,
              field: data[key].name,
              type: data[key].type,
              value: values && values.value,
              labelValue:
                data[key].type !== "checkbox-group"
                  ? values && values.label
                  : values && values.value,
              rules: ruleList,
              groupActived: [],
            };
          }
          dataFull.push(fieldFull);
        }
      }
    }

    return dataFull;
  }

  selectOption(values, valueField, type) {
    for (const i in values) {
      if (values[i].value === valueField && type !== "checkbox-group") {
        return values[i];
      }
      if (type === "checkbox-group") {
        values[i].value = valueField;
        return values[i];
      }
    }
  }

  sort(rules) {
    let no = rules[0].split(",");
    let fields = rules[1].split(",");
    let array = [];
    for (let i = 0; i < fields.length; i++) {
      array.push({ field: fields[i], rule: no[i] });
    }
    let sorted = array.sort((a, b) => (a.field > b.field ? 1 : b.field > a.field ? -1 : 0));

    for (const i in sorted) {
      fields.push(sorted[i].field);
      no.push(sorted[i].rule);
    }

    return [fields, no];
  }

  noRuleFormat(rules) {
    if (!rules) {
      return false;
    }
    let rule = rules.split(":");
    let field = [];
    let campo = [];
    let selected = "";
    let sort = this.sort(rule);
    let fields = sort[0];
    let no = sort[1];
    //console.log('fields$%#%%$', fields)

    for (let i = 0; i < fields.length; i++) {
      if (selected !== fields[i]) {
        campo = [];
        selected = fields[i];
      }
      campo[this.nameRule(no[i])] = true;
      field[fields[i]] = campo;
    }
    return field;
  }

  nameRule(key) {
    switch (key) {
      case "0":
        return "hidden";
      case "1":
        return "show";
      case "2":
        return "disabled";
      case "3":
        return "active";
      case "4":
        return "required";
      case "5":
        return "click";
      case "6":
        return "notRequired";
      case "7":
        return "clean";
      case "8":
        return "cedula";
      case "9":
        return "rnc";
      case "10":
        return "pasaporte";
      case "12":
        return "api";
      default:
        break;
    }
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <MDBRow>
          <MDBCol size="9" className="">
            <MDBCol className="scrollingDivField">
              <br />
              {this.props.location.state.data.map((fields, i) => (
                <MDBRow key={i}>
                  <MDBCol>
                    <div className="cursor" onClick={() => this.hiddenPage("FromPage" + i)}>
                      <CardText color="#00695c" text={"Pagina: " + (i + 1)} />
                    </div>
                    <div id={"FromPage" + i}>
                      <FieldsLeft
                        key={i}
                        field={fields}
                        FromInput={this.FromInput}
                        FieldFromInput={this.state.fieldInput}
                      />
                    </div>
                  </MDBCol>
                  <hr />
                </MDBRow>
              ))}
            </MDBCol>
          </MDBCol>
          <MDBCol size="3" className="border scrollingDivField">
            <h2>Todas las acciones</h2>
            <hr />
            {this.state.rulesList.length > 0 ? (
              this.state.rulesList.map((field, i) => (
                <div key={i} id={field.field}>
                  <MDBRow>
                    <MDBCol md="10">
                      <p className="text-left">{field.label + " - " + field.labelValue}</p>
                    </MDBCol>

                    <MDBCol md="2">
                      <MDBIcon
                        icon="times-circle"
                        size="lg"
                        className="text-danger cursor"
                        onClick={() => this.removeRule(i)}
                      />
                    </MDBCol>
                  </MDBRow>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.FromInput(
                        field.field,
                        field.type,
                        { label: field.labelValue, value: field.value },
                        field.label
                      )
                    }
                  >
                    Accion de regla
                  </button>
                  <br />
                  <br />
                  <MDBRow>
                    <MDBCol>
                      <p style={{ fontSize: 12 }} className="text-left">
                        {"id: " + field.field}
                      </p>
                    </MDBCol>

                    <MDBCol>
                      <p style={{ fontSize: 12 }} className="text-right">
                        {"Campo: " + field.type}
                      </p>
                    </MDBCol>
                  </MDBRow>

                  <hr />
                </div>
              ))
            ) : (
              <label>No hay acciones</label>
            )}
          </MDBCol>
          <FieldListModal
            modal={this.state.modal}
            rulesList={this.state.rulesList}
            actionList={this.actionList}
            field={this.state.field}
            json={this.props.location.state.data}
            toggle={this.toggle}
          />
        </MDBRow>

        <button
          className={"btn-float flotante " + config.color}
          onClick={() => {
            this.saveRules();
          }}
        >
          <MDBIcon icon="save" size="lg" className="text-white cursor" />
        </button>

        <label className={" btn-label " + this.state.msg}>Guardar Reglas</label>
      </div>
    );
  }
}
