import config from "../../config";
import axios from "axios";

function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token-rules")}`;

  return axios;
}

async function saveForm(body) {
  return await axios_api(config.api + "/formulary")
    .post(config.api + "/formulary", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function formTransfer(body) {
  return await axios_api(config.api + "/formulary/transfer")
    .post(config.api + "/formulary/transfer", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetAllFormulary() {
  return axios_api(config.api + "/formulary")
    .get(config.api + "/formulary")
    .then((res) => {
      console.log(" allForm res.data", res.data);
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = "/login";
      }
      return { data: error, ok: false };
    });
}

function seachFormulary(param) {
  return axios_api(config.api + "/seachFormularyById/" + param)
    .get(config.api + "/seachFormularyById/" + param)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetAllFormularyDataTable() {
  return axios_api(config.api + "/GetFormularyDataTable")
    .get(config.api + "/GetFormularyDataTable")
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
      return { data: error, ok: false };
    });
}

async function GetFormularyById(id) {
  return await axios_api(config.api + "/formulary/" + id)
    .get(config.api + "/formulary/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function deleteFormularyById(id) {
  return axios_api(config.api + "/formulary/" + id)
    .delete(config.api + "/formulary/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function updateFormularyById(body, id) {
  return axios_api(config.api + "/formulary/" + id)
    .put(config.api + "/formulary/" + id, body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function isAuth() {
  return axios_api(config.api + "/formulary/")
    .get(config.api + "/formulary/")
    .then((res) => {
      return { data: res, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

export {
  GetAllFormulary,
  GetAllFormularyDataTable,
  GetFormularyById,
  saveForm,
  deleteFormularyById,
  updateFormularyById,
  seachFormulary,
  formTransfer,
  isAuth,
};
