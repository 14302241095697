import React, { Component } from "react";
import { MDBBtn, MDBDataTable, MDBIcon } from "mdbreact";
import { Form, Roles } from "../../data/index";
import config from "../../config";
import { toast, ToastContainer } from "react-toastify";

const { GetAllFormularyDataTable } = Form;
const { GetRolesByUserAndForm } = Roles;

export class MasksTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllForm: [],
      idForm: "",
      id: "",
      data2: [],
      serviceName: "",
      formName: "",
      loading: true,
    };
    this.toMaskFields = this.toMaskFields.bind(this);
  }

  async componentDidMount() {
    const data = await GetAllFormularyDataTable();
    let array = [];
    try {
      for (const i of data.data.Formulary) {
        let element = {
          id: i.id,
          idForm: i.idForm,
          service: i.service_name,
          institution: i.institution_name,
          mask: (
            <MDBBtn
              className="btn  btn-md text-white"
              color={config.color}
              onClick={() => this.toMaskFields(i)}
            >
              Asignar Máscaras
            </MDBBtn>
          ),
        };
        array.push(element);
        this.state.loading = false;
      }
    } catch (e) {
      console.log(e);
    }
    const column = [
      { label: "ID", field: "id", sort: "asc" },
      { label: "Formulario", field: "idForm", sort: "asc" },
      { label: "Servicio", field: "service", sort: "asc" },
      { label: "Institución", field: "institution", sort: "asc" },
      { label: "Acción", field: "mask", sort: "asc" },
    ];
    const result = { columns: column, rows: array };
    this.setState({
      AllForm: result,
    });
  }

  async toMaskFields(form) {
    this.state.loading = true;
    const roles = await GetRolesByUserAndForm(localStorage.getItem("idUser"), form.id);
    if (!roles.data.rol) {
      this.setState({
        loading: 0,
      });
      toast.error("Usted no tiene permiso para ver este formulario", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    this.props.history.push({
      pathname: "/masklist",
      state: form,
    });
  }

  render() {
    return (
      <div className="contentTable">
        <ToastContainer />
        <h1>Formularios para Máscaras</h1>
        <br />
        {this.state.AllForm.columns && (
          <MDBDataTable striped bordered hover data={this.state.AllForm} />
        )}
        {this.state.loading ? (
          <MDBIcon
            icon="cog"
            spin
            size="3x"
            style={{
              color:
                config.env === "production"
                  ? "#00695c"
                  : config.env === "development"
                  ? "#0d47a1"
                  : "#424242",
            }}
            fixed
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default MasksTable;
