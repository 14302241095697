import React from "react";
import { Doughnut } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";

class ChartsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDoughnut: {
        labels: ["Softexpert", "Backend administrativo"],
        datasets: [
          {
            data: [this.props.se, this.props.bk],
            backgroundColor: ["#F7464A", "#283593"],
            hoverBackgroundColor: ["#b71c1c ", "#1a237e "],
          },
        ],
      },
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      dataDoughnut: {
        labels: ["Softexpert", "Backend administrativo"],
        datasets: [
          {
            data: [props.se, props.bk],
            backgroundColor: ["rgb(116,180,12,0.6)", "rgb(100,140,211,0.6)"],
            hoverBackgroundColor: ["rgb(116,180,12,0.8)", "rgb(100,140,211,0.8)"],
            borderColor: ["rgb(116,180,12,1)", "rgb(100,140,211,1)"],
          },
        ],
      },
    });
  }

  render() {
    return (
      <MDBContainer className="padding">
        <Doughnut data={this.state.dataDoughnut} options={{ responsive: true }} />
        <label style={{ fontSize: 20 }}>Formularios registrados</label>
      </MDBContainer>
    );
  }
}

export default ChartsForm;
