import React, { Component } from "react";
import {
  Configuration,
  Form,
  Mask,
  Roles,
  Router,
  Rules,
  RulesInit,
  RulesPrice,
} from "../../data/index";
import { toast, ToastContainer } from "react-toastify";
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import config from "../../config";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import { getRouterPermission } from "../../Helpers/helpers";

const { GetFormularyById, GetAllFormularyDataTable } = Form;
const { GetConfigurations, saveConfigurations, deleteConfigurationsByForm } = Configuration;

const { GetRulesInitByForm, saveRulesInit } = RulesInit;
const { GetRulesPriceByForm, saveRulesPrice } = RulesPrice;
const { GetRulesByForm, saveRules } = Rules;
const { GetAllMaskVersionsPerForm, saveMask } = Mask;
const { GetRolesByUserAndForm } = Roles;
const { getRouterByUser } = Router;

class Tranfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalLoading: false,
      modal: false,
      idForm: "",
      rule: false,
      config: false,
      rulesprice: false,
      rulesinit: false,
      mask: false,
      form: false,
      loading: false,
      result: [],
      services: [],
      typeForm: "",
      encryptData: "",
      desncryptData: "",
      modalImport: false,
      loadingImport: false,
      loadingExport: false,
    };
  }

  async componentDidMount() {
    const dataGetter = await GetAllFormularyDataTable();
    if (dataGetter.ok) {
      this.setState({
        data: this.table(dataGetter.data.Formulary),
      });
    }
  }

  async toggleImport(id) {
    if (this.state.modalImport) {
      this.setState({
        modalImport: false,
      });
      return;
    }
    const roles = await GetRolesByUserAndForm(localStorage.getItem("idUser"), id);
    if (!roles.data.rol) {
      this.setState({
        progress: 0,
      });

      toast.error("Usted no tiene permiso para ver este formulario", {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      return;
    }
    this.setState({
      modalImport: !this.state.modalImport,
      idForm: id,
    });
  }

  async toggle(id) {
    let result = [];

    if (this.state.modal) {
      this.setState({
        modal: false,
      });
      return;
    }

    const roles = await GetRolesByUserAndForm(localStorage.getItem("idUser"), id);
    if (!roles.data.rol) {
      this.setState({
        progress: 0,
      });
      toast.error("Usted no tiene permiso para ver este formulario", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    this.setState({
      modalLoading: true,
      modal: true,
      encryptData: "",
    });

    const form = await GetFormularyById(id);

    if (form.ok) {
      this.setState({
        formData: form.data.Formulary[0],
      });
    }

    const config = await GetConfigurations(id);
    if (config.ok) {
      result.push({
        info: "config",
        data: config.data.configurations || [],
        import: false,
      });
      if (config.data.configurations) this.setState({ config: !!config.data.configurations });
    }

    const ruleInit = await GetRulesInitByForm(id);
    if (ruleInit.ok) {
      result.push({
        info: "rulesinit",
        data: ruleInit.data.rules,
        import: false,
      });
      this.setState({ rulesinit: ruleInit.data.rules.length > 0 });
    }

    const rulesprice = await GetRulesPriceByForm(id);
    if (rulesprice.ok) {
      result.push({
        info: "rulesprice",
        data: rulesprice.data.rules,
        import: false,
      });
      this.setState({ rulesprice: rulesprice.data.rules.length > 0 });
    }

    const mask = await GetAllMaskVersionsPerForm(id);
    if (mask.ok) {
      result.push({ info: "mask", data: mask.data.mask, import: false });
      this.setState({ mask: mask.data.mask.length > 0 });
    }

    const rules = await GetRulesByForm(id);
    if (rules.ok) {
      result.push({ info: "rules", data: rules.data.rules, import: false });
      this.setState({ rule: rules.data.rules.length > 0 });
    }

    this.setState({
      modalLoading: false,
      result: result,
    });
  }

  selected(e) {
    let element = e.target;
    let data = this.state.result;
    for (const key in data) {
      if (data[key].info === element.id) {
        data[key].import = element.checked;
      }
    }
  }

  table(data) {
    let array = [];
    for (const i of data) {
      let element = {
        id: i.id,
        idForm: i.idForm,
        logo: (
          <img
            className={" hoverable img-fluid"}
            alt=""
            width="80"
            src={i.url ? i.url : require("../../img/test.png")}
          />
        ),
        service: i.service_name,
        institution: i.institution_name,
        Accion: [
          <MDBBtn
            className="btn  btn-md text-white"
            color={config.color}
            onClick={() => this.toggleImport(i.id)}
            key={"btn-import" + i.id}
          >
            <MDBIcon icon="arrow-left" /> Importar
          </MDBBtn>,
          <MDBBtn
            className="btn  btn-md text-white"
            color="red"
            onClick={() => this.toggle(i.id, i.idForm)}
            key={"btn-export" + i.id}
          >
            <MDBIcon icon="arrow-right" /> Exportar
          </MDBBtn>,
        ],
      };
      array.push(element);
    }
    const column = [
      { label: "ID", field: "id", sort: "asc" },
      { label: "Formulario", field: "idForm", sort: "asc" },
      { label: "Logo", field: "logo", sort: "asc" },
      { label: "Servicio", field: "service", sort: "asc" },
      { label: "Institución", field: "institution", sort: "asc" },
      { label: "Acción", field: "Accion", sort: "asc" },
    ];

    return { columns: column, rows: array };
  }

  async componentWillMount() {
    const result = await getRouterByUser(localStorage.getItem("idUser"));
    const permission = getRouterPermission(result.data.routers, "tranfer");

    if (!permission) {
      window.location.href = "/Home";
    }
  }

  async export() {
    this.setState({
      loadingExport: true,
    });
    let result = [];
    for (const key in this.state.result) {
      if (this.state.result[key].import) {
        result.push(this.state.result[key]);
      }
    }
    let data = CryptoJS.AES.encrypt(JSON.stringify(result), config.SECRET_TOKEN);

    this.setState({
      encryptData: data.toString(),
      loadingExport: false,
    });
  }

  async import() {
    this.setState({
      loadingImport: true,
    });
    let ruleTextarea = document.getElementById("textEncript").value;
    let descriptionTextarea = CryptoJS.AES.decrypt(ruleTextarea, config.SECRET_TOKEN);
    let rulesTextareaConvert = descriptionTextarea.toString(CryptoJS.enc.Utf8);
    let resultData = JSON.parse(rulesTextareaConvert);

    for (const key in resultData) {
      if (resultData[key].info === "rulesprice") {
        let count = 0;
        let data = resultData[key].data;
        for (const i in data) {
          data[i].idForm = this.state.idForm;
          const result = await saveRulesPrice(data[i]);

          if (result.ok) {
            count++;
          }
        }
        if (count > 0) {
          toast.success("Se importo las reglas de precios exitosamente", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      }

      if (resultData[key].info === "rulesinit") {
        let count = 0;
        let data = resultData[key].data;
        for (const i in data) {
          data[i].idForm = this.state.idForm;
          const result = await saveRulesInit(data[i]);
          if (result.ok) {
            count++;
          }
        }
        if (count > 0) {
          toast.success("Se importo las reglas de carga exitosamente", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      }

      if (resultData[key].info === "rules") {
        let count = 0;
        let data = resultData[key].data;
        for (const i in data) {
          data[i].idForm = this.state.idForm;
          data[i]["version"] = data[i].versionRule;
          const result = await saveRules(data[i]);

          if (result.ok) {
            count++;
          }
        }
        if (count > 0) {
          toast.success("Se importo las reglas exitosamente", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      }

      if (resultData[key].info === "mask") {
        let count = 0;
        let data = resultData[key].data;
        for (const i in data) {
          data[i].idForm = this.state.idForm;
          const result = await saveMask(
            data[i].fieldsMasked,
            data[i].idForm,
            data[i].maskVersion,
            data[i].formatedFieldsMasked,
            data[i].fieldsLength,
            data[i].fieldsGridMasked
          );

          if (result.ok) {
            count++;
          }
        }
        if (count > 0) {
          toast.success("Se importo las mascaras exitosamente", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      }

      if (resultData[key].info === "config") {
        let count = 0;
        let data = resultData[key].data;
        data.idForm = this.state.idForm;
        await deleteConfigurationsByForm(data.idForm);
        const result = await saveConfigurations(data);

        if (result.ok) {
          count++;
        }

        if (count > 0) {
          toast.success("Se importo la configuracion de campos exitosamente", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      }
    }
    this.setState({
      loadingImport: false,
      modalImport: false,
    });
  }

  async copy() {
    document.execCommand("copy");
  }

  render() {
    return (
      <div className="App padding">
        <ToastContainer />

        <h2>Tranferencia de Formularios</h2>
        <br />
        <MDBContainer>
          {this.state.data && this.state.data.length === 0 && (
            <div className="col-md-12">
              <MDBIcon
                icon="cog"
                spin
                size="3x"
                style={{
                  color:
                    config.env === "production"
                      ? "#00695c"
                      : config.env === "development"
                      ? "#0d47a1"
                      : "#424242",
                }}
                fixed
              />
              <br></br>
              <br></br>
              <b>
                <h3>Cargando...</h3>
              </b>
            </div>
          )}

          {this.state.data.rows && <MDBDataTable bordered hover data={this.state.data} />}
        </MDBContainer>

        {/**===============================================MODAL================================================= */}
        <MDBContainer>
          <MDBModal isOpen={this.state.modal} toggle={() => this.toggle()} size="lg">
            <MDBModalHeader toggle={() => this.toggle()}>Exportar de datos</MDBModalHeader>
            <MDBModalBody id="modal-body-card-form">
              {this.state.modalLoading ? (
                <div className="col-md-12">
                  <MDBIcon
                    icon="cog"
                    spin
                    size="2x"
                    style={{
                      color:
                        config.env === "production"
                          ? "#00695c"
                          : config.env === "development"
                          ? "#0d47a1"
                          : "#424242",
                    }}
                    fixed
                  />
                  <br></br>
                </div>
              ) : (
                <div>
                  <label>
                    <b>¿Qué quieres importar?</b>
                  </label>
                  <br />
                  <br />
                  <input
                    type="checkbox"
                    disabled={!this.state.rule}
                    onClick={(e) => this.selected(e)}
                    id="rules"
                  />
                  &nbsp;&nbsp; Reglas &nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    disabled={!this.state.mask}
                    onClick={(e) => this.selected(e)}
                    id="mask"
                  />
                  &nbsp;&nbsp; Mascaras &nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    disabled={!this.state.rulesprice}
                    onClick={(e) => this.selected(e)}
                    id="rulesprice"
                  />
                  &nbsp;&nbsp; Reglas de Precio &nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    disabled={!this.state.rulesinit}
                    onClick={(e) => this.selected(e)}
                    id="rulesinit"
                  />
                  &nbsp;&nbsp; Reglas de Carga &nbsp;&nbsp;&nbsp;&nbsp;
                  {/* <input type="checkbox" disabled={!this.state.form} onClick={(e)=> this.selected(e)}  id="form"/>&nbsp;&nbsp; Reglas de Carga &nbsp;&nbsp;&nbsp;&nbsp; */}
                  <input
                    type="checkbox"
                    disabled={!this.state.config}
                    onClick={(e) => this.selected(e)}
                    id="config"
                  />
                  &nbsp;&nbsp; Configuracion de campos &nbsp;&nbsp;&nbsp;&nbsp;
                  <hr />
                  <br />
                  <label htmlFor="defaultFormLoginEmailEx">Datos encriptados:</label>
                  <br />
                  <textarea
                    className="form-control"
                    value={this.state.encryptData}
                    id="encript"
                    rows="6"
                    readOnly={true}
                  ></textarea>
                </div>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              {this.state.loadingExport && (
                <div className="col-md-12">
                  <MDBIcon
                    icon="cog"
                    spin
                    size="2x"
                    style={{
                      color:
                        config.env === "production"
                          ? "#00695c"
                          : config.env === "development"
                          ? "#0d47a1"
                          : "#424242",
                    }}
                    fixed
                  />
                  <br></br>
                </div>
              )}
              <MDBBtn color={config.color} onClick={() => this.export()}>
                Exportar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>

        {/**===============================================MODAL IMPORT ================================================= */}
        <MDBContainer>
          <MDBModal isOpen={this.state.modalImport} toggle={() => this.toggleImport()} size="lg">
            <MDBModalHeader toggle={() => this.toggleImport()}>Importar datos</MDBModalHeader>
            <MDBModalBody id="modal-body-card-form">
              <div>
                <label htmlFor="defaultFormLoginEmailEx">Copiar Datos:</label>
                <br />
                <textarea className="form-control" id="textEncript" rows="6"></textarea>
              </div>

              {this.state.loadingImport && (
                <div className="col-md-12">
                  <MDBIcon
                    icon="cog"
                    spin
                    size="2x"
                    style={{
                      color:
                        config.env === "production"
                          ? "#00695c"
                          : config.env === "development"
                          ? "#0d47a1"
                          : "#424242",
                    }}
                    fixed
                  />
                  <br></br>
                </div>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color={config.color} onClick={() => this.import()}>
                Importar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  }
}

export default Tranfer;
