import React, { Component } from "react";
import { MDBRow } from "mdbreact";
import { Rules } from "../../data/index";
import RulesListBox from "./rulesListBox";
import BtnNewRule from "./btnNewRule";
import BtnImportRule from "./BtnImportRule";
import BtnInitRule from "./BtnInitRule";
import Btnback from "./Btnback";

const { GetRulesByForm } = Rules;

class RulesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shadow: "",
      arrayRules: [],
    };
    this.renderRule = this.renderRule.bind(this);
  }

  async componentDidMount() {
    const result = await GetRulesByForm(this.props.formulary);
    this.setState({
      arrayRules: result.data.rules,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    console.log("props :", nextProps);
  }

  async renderRule() {
    const result = await GetRulesByForm(this.props.formulary);
    this.setState({
      arrayRules: result.data.rules,
    });
  }

  render() {
    return (
      <div className="App ">
        <MDBRow>
          <Btnback />
          <BtnNewRule idForm={this.props.formulary} data={this.props.data} />

          <BtnImportRule
            handlerRule={this.renderRule}
            idForm={this.props.formulary}
            data={this.props.data}
          />
          <BtnInitRule
            handlerRule={this.renderRule}
            idForm={this.props.formulary}
            data={this.props.data}
          />
        </MDBRow>
        <br />
        <br />
        {!!this.state.arrayRules ? (
          <MDBRow className="d-flex flex-wrap bd-highlight example-parent">
            {this.state.arrayRules.map((rules, i) => (
              <RulesListBox
                key={i}
                idForm={this.props.formulary}
                data={this.props.data}
                rules={rules}
                handlerRule={this.renderRule}
              />
            ))}
          </MDBRow>
        ) : (
          <center>
            <img width="200" alt="img" src={require("../../img/sad.png")} />
            <br />
            <br />
            <br />
            <h1 style={{ color: "#00695c" }}>
              <b> Este formulario no tiene reglas</b>
            </h1>
          </center>
        )}
      </div>
    );
  }
}

export default RulesList;
