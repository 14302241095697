import config from "../../config";
import axios from "axios";

function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token-rules")}`;
  return axios;
}

function saveRulesPrice(body) {
  return axios_api(config.api + "/ruleprice")
    .post(config.api + "/ruleprice", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function getRulesPriceSelected(id, type) {
  return axios_api(config.api + "/ruleprice/selected/" + id + "/" + type)
    .get(config.api + "/ruleprice/selected/" + id + "/" + type)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function setRulesPriceSelected(body) {
  return axios_api(config.api + "/ruleprice/selected")
    .post(config.api + "/ruleprice/selected", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function removeRulesPriceSelected(body) {
  return axios_api(config.api + "/ruleprice/selected/remove")
    .post(config.api + "/ruleprice/selected/remove", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function updateRulesPrice(body, id) {
  return axios_api(config.api + "/ruleprice/" + id)
    .put(config.api + "/ruleprice/" + id, body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetRulesPriceByForm(id) {
  return axios_api(config.api + "/ruleprice/" + id)
    .get(config.api + "/ruleprice/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetRulesPriceByPrice(id, rule, type) {
  return axios_api(config.api + "/ruleprice/price/" + id + "/" + rule + "/" + type)
    .get(config.api + "/ruleprice/price/" + id + "/" + rule + "/" + type)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function GetRulesPriceById(id) {
  return axios_api(config.api + "/ruleprice/id/" + id)
    .get(config.api + "/ruleprice/id/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

function deleteRulesPriceByForm(id) {
  return axios_api(config.api + "/ruleprice/" + id)
    .delete(config.api + "/ruleprice/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

export {
  GetRulesPriceByForm,
  updateRulesPrice,
  deleteRulesPriceByForm,
  saveRulesPrice,
  GetRulesPriceById,
  setRulesPriceSelected,
  GetRulesPriceByPrice,
  removeRulesPriceSelected,
  getRulesPriceSelected,
};
