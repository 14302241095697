import config from "../../config";
import axios from "axios";

function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token-rules")}`;
  return axios;
}

async function GetForms(id) {
  let json = [];
  let fieldInput = null;
  if (!id) {
    return;
  }
  const result = await axios_api(config.api)
    .post(config.api + "/forms", { idformulario: id, FormBuilder: true })
    .then((res) => {
      // console.log('id, res.data :', id, res.data);
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      console.log("error:", error);
      return { data: error, ok: false };
    });

  if (result.data.message) {
    return {
      data: "el id del formulario no existe o el fomulario tiene errores",
      ok: false,
    };
  }
  if (result.data.error) {
    if (result.data.field) {
      fieldInput = {
        name: result.data.field.name || result.data.field.NOMBRECAMPO,
        Mask: result.data.field.Mask || "no asignado",
        group: result.data.field.group || result.data.field.GRUPO,
        label: result.data.field.label || result.data.field.ETIQUETAEFM,
      };
    }
    return {
      data: result.data.solution,
      error: result.data.error,
      field: fieldInput,
      ok: false,
    };
  }
  for (const key in result.data.form) {
    json.push(JSON.parse(result.data.form[key]));
  }

  return { data: json, ok: result.ok, entity: result.data.entity };
}

async function validateForm(body) {
  return await axios_api(config.api + "/valideform")
    .post(config.api + "/valideform", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function createEntity(body) {
  return await axios_api(config.api + "/entity")
    .post(config.api + "/entity", body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function getEntity(body) {
  return await axios_api(config.api + "/entity")
    .get(config.api + "/entity")
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function getEntityById(id) {
  return await axios_api(config.api + "/entity/" + id)
    .get(config.api + "/entity/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function deleteEntity(id) {
  return await axios_api(config.api + "/entity/" + id)
    .delete(config.api + "/entity/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function GetFormularyBySE(id) {
  const result = await axios_api(config.api + "/formularySE/" + id)
    .get(config.api + "/formularySE/" + id)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
  console.log("result$$$ :", result);
  if (result.data.Formulary.length > 0) {
    return result;
  } else {
    return { data: result.data, ok: false };
  }
}

async function getDocuments(body) {
  return await axios_api(config.api + "/documents/" + body)
    .get(config.api + "/documents/" + body, body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function getDocumentsToSend(body) {
  return await axios_api(config.api + "/documents/id/" + body)
    .get(config.api + "/documents/id/" + body, body)
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

async function testApi(body) {
  return await axios_api("http://coraavega.com:9001/api/sliders")
    .get("http://coraavega.com:9001/api/sliders")
    .then((res) => {
      return { data: res.data, ok: true };
    })
    .catch((error) => {
      return { data: error, ok: false };
    });
}

export {
  getDocuments,
  getDocumentsToSend,
  GetFormularyBySE,
  GetForms,
  validateForm,
  testApi,
  deleteEntity,
  createEntity,
  getEntity,
  getEntityById,
};
